.blog {
	&-card {
		&_wrap {
			position: relative;
			width: 100%;

			&:hover {
				.blog-card_heading {
					text-decoration-line: underline;
				}
			}
		}

		&_image {
			width: 100%;
			aspect-ratio: 430/244;
			margin-bottom: 28px;
			position: relative;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
			.tag-sm-light {
				position: absolute;
				left: 17px;
				top: 20px;
			}
		}

		&_date {
			@include microfont("sm");
			z-index: 1;
			color: inherit;
			margin-bottom: 12px;

			*[class*="bg-dark"] & {
				color: $offwhite;
			}
		}

		&_heading {
			@include heading(h4);
			font-weight: 600;
			display: block;
			align-items: baseline;
			color: inherit;

			span {
				&.icon {
					left: 0;
					transition: left 175ms;
					margin-left: 12px;
					filter: brightness(0) saturate(100%) invert(5%) sepia(48%) saturate(2126%) hue-rotate(80deg) brightness(94%) contrast(94%);

					*[class*="bg-dark"] & {
						filter: none;
					}
				}
			}
		}

		&_link {
			position: absolute;
			inset: 0;
		}

		&_footerBox {
			position: relative;
			padding-bottom: 20px;

			@media (max-width: 767px) {
				position: unset;
				padding: 0;
			}

			*[class*="bg-dark"] & {
				color: $offwhite;

				* {
					color: inherit;
				}
			}
		}
		&_text {
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
}
