.section {
	margin-top: 120px;
	margin-bottom: 120px;

	@media (max-width: 767px) {
		margin-top: 56px;
		margin-bottom: 56px;
	}

	&[class*="bg-"],
	&[class^="bg-"] {
		&:not(.bg-white) {
			padding-top: 120px;
			padding-bottom: 120px;
			margin: 0;

			@media (max-width: 767px) {
				padding-top: 56px;
				padding-bottom: 56px;
			}

			&:last-child {
				margin-bottom: 0;
			}

			+.section {

				&[class*=" bg-"],
				&[class^="bg-"] {
					&:not(.bg-white) {
					}
				}
			}
		}
	}
	&.bg-dark, &.bg-blue {
		&:last-child {
			padding-bottom: 64px;
			margin-bottom: 0;

			@media (max-width: 767px) {
				padding-bottom: 40px;
			}
		}
	}

	*[data-portal-component-type="layout"]+&:not(.bg-white) {
		&[class*="bg-"] {
			margin-top: 0;
		}
	}
	&_head {
		margin-bottom: 48px;
		@media(max-width: 991px) {
			margin-bottom: 24px;
		}
		> *{
			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0!important;
			}
		}

	}
	&_footer {
		margin-top: 48px;
		@media(max-width: 991px) {
			margin-top: 24px;
		}
	}
}

// .section + .section,
.sectionbg-gray+.section.bg-gray,
.section.bg-blue+.section.bg-blue,
.section.bg-dark+.section.bg-dark,
.section.bg-blue+.section.bg-dark,
.section.bg-dark+.section.bg-blue,
.section.bg-page-light+.section.bg-page-light,
.section.bg-page+.section.bg-page {
	padding-top: 0 !important;
}
