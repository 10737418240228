/* Article */
.article,
.prpratSingle,
.course,
.advertiseSingle,
.vacantPosition {
	display: block;
}

.article .imageArticle,
.prpratSingle .imageArticle,
.course .imageArticle,
.advertiseSingle .imageArticle,
.vacantPosition .imageArticle {
	max-width: 1440px;
	margin: 0 auto;
}

.article .imageArticle .img-responsive,
.prpratSingle .imageArticle .img-responsive,
.course .imageArticle .img-responsive,
.advertiseSingle .imageArticle .img-responsive,
.vacantPosition .imageArticle .img-responsive {
	width: 100%;
}

@media (min-width: 1440px) {

	.article .container,
	.prpratSingle .container,
	.course .container,
	.advertiseSingle .container,
	.vacantPosition .container {
		width: 1260px;
	}
}

.article .container .contentArticle,
.prpratSingle .container .contentArticle,
.course .container .contentArticle,
.advertiseSingle .container .contentArticle,
.vacantPosition .container .contentArticle {
	background: #fff;
	width: 66.66667%;
	margin: 0 auto;
	position: relative;
	display: block;
	margin-top: 100px;
}

@media (max-width: 767px) {

	.article .container .contentArticle,
	.prpratSingle .container .contentArticle,
	.course .container .contentArticle,
	.advertiseSingle .container .contentArticle,
	.vacantPosition .container .contentArticle {
		width: 96%;
	}
}

@media (max-width: 480px) {

	.article .container .contentArticle,
	.prpratSingle .container .contentArticle,
	.course .container .contentArticle,
	.advertiseSingle .container .contentArticle,
	.vacantPosition .container .contentArticle {
		width: 100%;
	}
}

.article .container .contentArticle .header,
.prpratSingle .container .contentArticle .header,
.course .container .contentArticle .header,
.advertiseSingle .container .contentArticle .header{
	padding-top: 30px;
	padding-left: 13%;
	padding-right: 13%;
	margin-bottom: 60px;
}
.vacantPosition .container .header {
	padding-top: 30px;
	margin-bottom: 60px;
	@media (max-width: 480px) {
		margin-bottom: 20px;
	}
	.vacantBox{
		margin-left: 13%;
		margin-right: 13%;
		@media (max-width: 480px) {
			margin-right: 20px;
			margin-left: 20px;
		}
	}
}
@media (max-width: 480px) {

	.article .container .contentArticle .header,
	.prpratSingle .container .contentArticle .header,
	.course .container .contentArticle .header,
	.advertiseSingle .container .contentArticle .header {
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 20px;
	}
}

.article .container .contentArticle .header h1,
.prpratSingle .container .contentArticle .header h1,
.course .container .contentArticle .header h1,
.advertiseSingle .container .contentArticle .header h1,
.vacantPosition .container .header h1 {
	margin-bottom: 40px;
}

.article .container .contentArticle .header .infoTable,
.prpratSingle .container .contentArticle .header .infoTable,
.course .container .contentArticle .header .infoTable,
.advertiseSingle .container .contentArticle .header .infoTable,
.vacantPosition .container .header .infoTable {
	border-top: 1px solid #E7EAE6;
}

.article .container .contentArticle .header .infoTable .infoTableRow,
.prpratSingle .container .contentArticle .header .infoTable .infoTableRow,
.course .container .contentArticle .header .infoTable .infoTableRow,
.advertiseSingle .container .contentArticle .header .infoTable .infoTableRow,
.vacantPosition .container .header .infoTable .infoTableRow {
	padding-top: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #E7EAE6;
	font-size: 0.813rem;
	line-height: 1.25rem;
	font-family: $sans;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.article .container .contentArticle .header .infoTable .infoTableRow .infoTableCol,
.prpratSingle .container .contentArticle .header .infoTable .infoTableRow .infoTableCol,
.course .container .contentArticle .header .infoTable .infoTableRow .infoTableCol,
.advertiseSingle .container .contentArticle .header .infoTable .infoTableRow .infoTableCol,
.vacantPosition .container .header .infoTable .infoTableRow .infoTableCol {
	flex: 1 1 auto;
}

.article .container .contentArticle .header .infoTable .infoTableRow .infoTableCol:first-child,
.prpratSingle .container .contentArticle .header .infoTable .infoTableRow .infoTableCol:first-child,
.course .container .contentArticle .header .infoTable .infoTableRow .infoTableCol:first-child,
.advertiseSingle .container .contentArticle .header .infoTable .infoTableRow .infoTableCol:first-child,
.vacantPosition .container .header .infoTable .infoTableRow .infoTableCol:first-child {
	border-left: none;
}

.article .container .contentArticle .intro,
.prpratSingle .container .contentArticle .intro,
.course .container .contentArticle .intro,
.advertiseSingle .container .contentArticle .intro,
.vacantPosition .container .contentArticle .intro {
	padding-top: 30px;
	padding-left: 13%;
	padding-right: 13%;
	margin-bottom: 60px;
}

@media (max-width: 480px) {

	.article .container .contentArticle .intro,
	.prpratSingle .container .contentArticle .intro,
	.course .container .contentArticle .intro,
	.advertiseSingle .container .contentArticle .intro,
	.vacantPosition .container .contentArticle .intro {
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 20px;
	}
}

.article .container .contentArticle .intro .categoryArticle,
.prpratSingle .container .contentArticle .intro .categoryArticle,
.course .container .contentArticle .intro .categoryArticle,
.advertiseSingle .container .contentArticle .intro .categoryArticle,
.vacantPosition .container .contentArticle .intro .categoryArticle {
	color: $old7D7D7D;
	@include microfont("sm");
	// font-size: 0.75rem;
	text-align: center;
	margin-bottom: 25px;
}

@media (max-width: 480px) {

	.article .container .contentArticle .intro .categoryArticle,
	.prpratSingle .container .contentArticle .intro .categoryArticle,
	.course .container .contentArticle .intro .categoryArticle,
	.advertiseSingle .container .contentArticle .intro .categoryArticle,
	.vacantPosition .container .contentArticle .intro .categoryArticle {
		font-size: 10px;
		line-height: 10px;
		margin-bottom: 13px;
	}
}

.article .container .contentArticle .intro h1.title,
.prpratSingle .container .contentArticle .intro h1.title,
.course .container .contentArticle .intro h1.title,
.advertiseSingle .container .contentArticle .intro h1.title,
.vacantPosition .container .contentArticle .intro h1.title {
	color: $oldblue;
	@include serifHeading;
	font-size: 40px;

	line-height: 44px;
	text-align: center;
	margin-bottom: 30px;
	margin-top: 0;
}

@media (max-width: 480px) {

	.article .container .contentArticle .intro h1.title,
	.prpratSingle .container .contentArticle .intro h1.title,
	.course .container .contentArticle .intro h1.title,
	.advertiseSingle .container .contentArticle .intro h1.title,
	.vacantPosition .container .contentArticle .intro h1.title {
		font-size: 24px;
		line-height: 28px;
		margin-bottom: 15px;
	}
}

.article .container .contentArticle .intro p,
.prpratSingle .container .contentArticle .intro p,
.course .container .contentArticle .intro p,
.advertiseSingle .container .contentArticle .intro p,
.vacantPosition .container .contentArticle .intro p {
	color: $old7D7D7D;
	@include serifRegular;
	font-size: 22px;
	font-style: italic;
	line-height: 30px;
	text-align: center;
	margin-bottom: 20px;
}

@media (max-width: 480px) {

	.article .container .contentArticle .intro p,
	.prpratSingle .container .contentArticle .intro p,
	.course .container .contentArticle .intro p,
	.advertiseSingle .container .contentArticle .intro p,
	.vacantPosition .container .contentArticle .intro p {
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 20px;
	}
}

.article .container .contentArticle .intro .byLine,
.prpratSingle .container .contentArticle .intro .byLine,
.course .container .contentArticle .intro .byLine,
.advertiseSingle .container .contentArticle .intro .byLine,
.vacantPosition .container .contentArticle .intro .byLine {
	color: $old7D7D7D;
	font-family: $sans;
	font-size: 11px;

	line-height: 16px;
	text-align: center;
}

@media (max-width: 480px) {

	.article .container .contentArticle .intro .byLine,
	.prpratSingle .container .contentArticle .intro .byLine,
	.course .container .contentArticle .intro .byLine,
	.advertiseSingle .container .contentArticle .intro .byLine,
	.vacantPosition .container .contentArticle .intro .byLine {
		font-size: 9px;
		line-height: 14px;
	}
}

.article .container .contentArticle .body,
.prpratSingle .container .contentArticle .body,
.course .container .contentArticle .body,
.advertiseSingle .container .contentArticle .body,
.vacantPosition .container .contentArticle .body {
	padding-left: 13%;
	padding-right: 13%;
}

@media (max-width: 480px) {

	.article .container .contentArticle .body,
	.prpratSingle .container .contentArticle .body,
	.course .container .contentArticle .body,
	.advertiseSingle .container .contentArticle .body,
	.vacantPosition .container .contentArticle .body {
		padding: 0;
	}
}

.article .container .contentArticle .body hr,
.prpratSingle .container .contentArticle .body hr,
.course .container .contentArticle .body hr,
.advertiseSingle .container .contentArticle .body hr,
.vacantPosition .container .contentArticle .body hr {
	margin-top: 40px;
	margin-bottom: 40px;
}

.article .container .contentArticle .body p,
.prpratSingle .container .contentArticle .body p,
.course .container .contentArticle .body p,
.advertiseSingle .container .contentArticle .body p,
.vacantPosition .container .contentArticle .body p {
	color: $oldblue;
	@include serifRegular;
	font-size: 18px;
	line-height: 32px;
	margin-bottom: 15px;
}

@media (max-width: 480px) {

	.article .container .contentArticle .body p,
	.prpratSingle .container .contentArticle .body p,
	.course .container .contentArticle .body p,
	.advertiseSingle .container .contentArticle .body p,
	.vacantPosition .container .contentArticle .body p {
		font-size: 14px;
		line-height: 22px;
	}
}

.article .container .contentArticle .body p>a:not(a.button),
.prpratSingle .container .contentArticle .body p>a:not(a.button),
.course .container .contentArticle .body p>a:not(a.button),
.advertiseSingle .container .contentArticle .body p>a:not(a.button),
.vacantPosition .container .contentArticle .body p>a:not(a.button) {
	font-weight: normal;
	border-bottom: 1px solid #a2cc38;
	color: $oldblue;
}

.article .container .contentArticle .body p>a:not(a.button):hover,
.prpratSingle .container .contentArticle .body p>a:not(a.button):hover,
.course .container .contentArticle .body p>a:not(a.button):hover,
.advertiseSingle .container .contentArticle .body p>a:not(a.button):hover,
.vacantPosition .container .contentArticle .body p>a:not(a.button):hover,
.article .container .contentArticle .body p>a:not(a.button):focus,
.prpratSingle .container .contentArticle .body p>a:not(a.button):focus,
.course .container .contentArticle .body p>a:not(a.button):focus,
.advertiseSingle .container .contentArticle .body p>a:not(a.button):focus,
.vacantPosition .container .contentArticle .body p>a:not(a.button):focus,
.article .container .contentArticle .body p>a:not(a.button):active,
.prpratSingle .container .contentArticle .body p>a:not(a.button):active,
.course .container .contentArticle .body p>a:not(a.button):active,
.advertiseSingle .container .contentArticle .body p>a:not(a.button):active,
.vacantPosition .container .contentArticle .body p>a:not(a.button):active {
	color: #a2cc38;
}

.article .container .contentArticle .body h1,
.prpratSingle .container .contentArticle .body h1,
.course .container .contentArticle .body h1,
.advertiseSingle .container .contentArticle .body h1,
.vacantPosition .container .contentArticle .body h1,
.article .container .contentArticle .body h2,
.prpratSingle .container .contentArticle .body h2,
.course .container .contentArticle .body h2,
.advertiseSingle .container .contentArticle .body h2,
.vacantPosition .container .contentArticle .body h2,
.article .container .contentArticle .body h3,
.prpratSingle .container .contentArticle .body h3,
.course .container .contentArticle .body h3,
.advertiseSingle .container .contentArticle .body h3,
.vacantPosition .container .contentArticle .body h3,
.article .container .contentArticle .body h4,
.prpratSingle .container .contentArticle .body h4,
.course .container .contentArticle .body h4,
.advertiseSingle .container .contentArticle .body h4,
.vacantPosition .container .contentArticle .body h4,
.article .container .contentArticle .body h5,
.prpratSingle .container .contentArticle .body h5,
.course .container .contentArticle .body h5,
.advertiseSingle .container .contentArticle .body h5,
.vacantPosition .container .contentArticle .body h5,
.article .container .contentArticle .body h6,
.prpratSingle .container .contentArticle .body h6,
.course .container .contentArticle .body h6,
.advertiseSingle .container .contentArticle .body h6,
.vacantPosition .container .contentArticle .body h6 {
	margin-bottom: 15px;
	margin-top: 0px;
	@include serifHeading;
}

.article .container .contentArticle .body h2,
.prpratSingle .container .contentArticle .body h2,
.course .container .contentArticle .body h2,
.advertiseSingle .container .contentArticle .body h2,
.vacantPosition .container .contentArticle .body h2,
.article .container .contentArticle .body h4,
.prpratSingle .container .contentArticle .body h4,
.course .container .contentArticle .body h4,
.advertiseSingle .container .contentArticle .body h4,
.vacantPosition .container .contentArticle .body h4,
.article .container .contentArticle .body h6,
.prpratSingle .container .contentArticle .body h6,
.course .container .contentArticle .body h6,
.advertiseSingle .container .contentArticle .body h6,
.vacantPosition .container .contentArticle .body h6 {
	font-size: 20px;
	line-height: 32px;
}

.article .container .contentArticle .body h3,
.prpratSingle .container .contentArticle .body h3,
.course .container .contentArticle .body h3,
.advertiseSingle .container .contentArticle .body h3,
.vacantPosition .container .contentArticle .body h3 {
	font-size: 36px;
	line-height: 40px;
}

.article .container .contentArticle .body h5,
.prpratSingle .container .contentArticle .body h5,
.course .container .contentArticle .body h5,
.advertiseSingle .container .contentArticle .body h5,
.vacantPosition .container .contentArticle .body h5 {
	font-size: 28px;
	line-height: 34px;
}

.article .container .contentArticle .body ul,
.prpratSingle .container .contentArticle .body ul,
.course .container .contentArticle .body ul,
.advertiseSingle .container .contentArticle .body ul,
.vacantPosition .container .contentArticle .body ul,
.article .container .contentArticle .body ol,
.prpratSingle .container .contentArticle .body ol,
.course .container .contentArticle .body ol,
.advertiseSingle .container .contentArticle .body ol,
.vacantPosition .container .contentArticle .body ol {
	margin-bottom: 25px;
}

.article .container .contentArticle .body ul li,
.prpratSingle .container .contentArticle .body ul li,
.course .container .contentArticle .body ul li,
.advertiseSingle .container .contentArticle .body ul li,
.vacantPosition .container .contentArticle .body ul li,
.article .container .contentArticle .body ol li,
.prpratSingle .container .contentArticle .body ol li,
.course .container .contentArticle .body ol li,
.advertiseSingle .container .contentArticle .body ol li,
.vacantPosition .container .contentArticle .body ol li {
	font-family: $sans;
	font-size: 18px;
	color: $oldblue;
}

@media (max-width: 480px) {

	.article .container .contentArticle .body ul li,
	.prpratSingle .container .contentArticle .body ul li,
	.course .container .contentArticle .body ul li,
	.advertiseSingle .container .contentArticle .body ul li,
	.vacantPosition .container .contentArticle .body ul li,
	.article .container .contentArticle .body ol li,
	.prpratSingle .container .contentArticle .body ol li,
	.course .container .contentArticle .body ol li,
	.advertiseSingle .container .contentArticle .body ol li,
	.vacantPosition .container .contentArticle .body ol li {
		font-size: 14px;
		line-height: 22px;
	}
}

.article .container .contentArticle .body .callToAction,
.prpratSingle .container .contentArticle .body .callToAction,
.course .container .contentArticle .body .callToAction,
.advertiseSingle .container .contentArticle .body .callToAction,
.vacantPosition .container .contentArticle .body .callToAction {
	margin: 50px -18%;
	overflow: hidden;
	border-top: 1px solid #E7EAE6;
	border-bottom: 1px solid #E7EAE6;
	padding: 15px 0px;
}

@media (max-width: 480px) {

	.article .container .contentArticle .body .callToAction,
	.prpratSingle .container .contentArticle .body .callToAction,
	.course .container .contentArticle .body .callToAction,
	.advertiseSingle .container .contentArticle .body .callToAction,
	.vacantPosition .container .contentArticle .body .callToAction {
		margin: 50px 0;
	}
}

.article .container .contentArticle .body .callToAction .boxCTA,
.prpratSingle .container .contentArticle .body .callToAction .boxCTA,
.course .container .contentArticle .body .callToAction .boxCTA,
.advertiseSingle .container .contentArticle .body .callToAction .boxCTA,
.vacantPosition .container .contentArticle .body .callToAction .boxCTA {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: 13%;
	margin-right: 13%;
}

.article .container .contentArticle .body .callToAction .boxCTA .contentCTA,
.prpratSingle .container .contentArticle .body .callToAction .boxCTA .contentCTA,
.course .container .contentArticle .body .callToAction .boxCTA .contentCTA,
.advertiseSingle .container .contentArticle .body .callToAction .boxCTA .contentCTA,
.vacantPosition .container .contentArticle .body .callToAction .boxCTA .contentCTA {
	width: 60%;
	color: $oldblue;
	font-family: $sans;
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	padding-right: 10px;
}

@media (max-width: 480px) {

	.article .container .contentArticle .body .callToAction .boxCTA .contentCTA,
	.prpratSingle .container .contentArticle .body .callToAction .boxCTA .contentCTA,
	.course .container .contentArticle .body .callToAction .boxCTA .contentCTA,
	.advertiseSingle .container .contentArticle .body .callToAction .boxCTA .contentCTA,
	.vacantPosition .container .contentArticle .body .callToAction .boxCTA .contentCTA {
		width: 100%;
		padding-right: 0px;
		margin-bottom: 20px;
		font-size: 13px;
		line-height: 16px;
		text-align: center;
	}
}

.article .container .contentArticle .body .callToAction .boxCTA .buttonCTA,
.prpratSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA,
.course .container .contentArticle .body .callToAction .boxCTA .buttonCTA,
.advertiseSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA,
.vacantPosition .container .contentArticle .body .callToAction .boxCTA .buttonCTA {
	width: 40%;
	padding-left: 10px;
}

@media (max-width: 480px) {

	.article .container .contentArticle .body .callToAction .boxCTA .buttonCTA,
	.prpratSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA,
	.course .container .contentArticle .body .callToAction .boxCTA .buttonCTA,
	.advertiseSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA,
	.vacantPosition .container .contentArticle .body .callToAction .boxCTA .buttonCTA {
		width: 100%;
		padding-left: 0px;
		text-align: center;
	}
}

.article .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a,
.prpratSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a,
.course .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a,
.advertiseSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a,
.vacantPosition .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a {
	color: #FFFFFF;
	width: auto;
	@include microfont("sm");
	// font-size: 0.75rem;
	font-weight: 600;

	line-height: 22px;
	text-align: center;
	padding: 20px 30px;
	display: inline-block;

	border-radius: 3px;
	text-decoration: none !important;
	background: linear-gradient(180deg, #A2CD38 0%, #88AC2E 100%);
}

@media (max-width: 480px) {

	.article .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a,
	.prpratSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a,
	.course .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a,
	.advertiseSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a,
	.vacantPosition .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a {
		width: initial;
		font-size: 10px;
		padding: 16px 24px;
	}
}

.article .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:hover,
.prpratSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:hover,
.course .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:hover,
.advertiseSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:hover,
.vacantPosition .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:hover,
.article .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:focus,
.prpratSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:focus,
.course .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:focus,
.advertiseSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:focus,
.vacantPosition .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:focus,
.article .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:active,
.prpratSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:active,
.course .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:active,
.advertiseSingle .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:active,
.vacantPosition .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a:active {
	background: #87ac2e;
}

.article .container .contentArticle .body .embedCourse,
.prpratSingle .container .contentArticle .body .embedCourse,
.course .container .contentArticle .body .embedCourse,
.advertiseSingle .container .contentArticle .body .embedCourse,
.vacantPosition .container .contentArticle .body .embedCourse {
	position: relative;
	margin: 50px 0px;
	overflow: hidden;
	border-radius: 3px;
}

.article .container .contentArticle .body .embedCourse .imageBox,
.prpratSingle .container .contentArticle .body .embedCourse .imageBox,
.course .container .contentArticle .body .embedCourse .imageBox,
.advertiseSingle .container .contentArticle .body .embedCourse .imageBox,
.vacantPosition .container .contentArticle .body .embedCourse .imageBox {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	background: url("../../images/embed-course-image-2.jpg") center top no-repeat;
}

.article .container .contentArticle .body .embedCourse:hover .imageBox>img,
.prpratSingle .container .contentArticle .body .embedCourse:hover .imageBox>img,
.course .container .contentArticle .body .embedCourse:hover .imageBox>img,
.advertiseSingle .container .contentArticle .body .embedCourse:hover .imageBox>img,
.vacantPosition .container .contentArticle .body .embedCourse:hover .imageBox>img,
.article .container .contentArticle .body .embedCourse:focus .imageBox>img,
.prpratSingle .container .contentArticle .body .embedCourse:focus .imageBox>img,
.course .container .contentArticle .body .embedCourse:focus .imageBox>img,
.advertiseSingle .container .contentArticle .body .embedCourse:focus .imageBox>img,
.vacantPosition .container .contentArticle .body .embedCourse:focus .imageBox>img {
	-webkit-filter: brightness(0.5);
	filter: brightness(0.5);
}

.article .container .contentArticle .body .embedCourse .contentBox,
.prpratSingle .container .contentArticle .body .embedCourse .contentBox,
.course .container .contentArticle .body .embedCourse .contentBox,
.advertiseSingle .container .contentArticle .body .embedCourse .contentBox,
.vacantPosition .container .contentArticle .body .embedCourse .contentBox {
	position: relative;
	z-index: 2;
	padding: 20px;
	min-height: 220px;
}

.article .container .contentArticle .body .embedCourse .contentBox .diagonalBand,
.prpratSingle .container .contentArticle .body .embedCourse .contentBox .diagonalBand,
.course .container .contentArticle .body .embedCourse .contentBox .diagonalBand,
.advertiseSingle .container .contentArticle .body .embedCourse .contentBox .diagonalBand,
.vacantPosition .container .contentArticle .body .embedCourse .contentBox .diagonalBand {
	position: absolute;
	top: 20px;
	right: -27px;
	height: 20px;
	width: 140px;
	transform: rotate(393deg);
	color: #FFFFFF;
	@include microfont("sm");
	// font-size: 0.75rem;


	text-align: center;
	background-color: $green;

	padding: 3px 25px;
}

.article .container .contentArticle .body .embedCourse .contentBox .dateContent,
.prpratSingle .container .contentArticle .body .embedCourse .contentBox .dateContent,
.course .container .contentArticle .body .embedCourse .contentBox .dateContent,
.advertiseSingle .container .contentArticle .body .embedCourse .contentBox .dateContent,
.vacantPosition .container .contentArticle .body .embedCourse .contentBox .dateContent {
	color: #D2E288;
	@include microfont("sm");
	// font-size: 0.75rem;


	padding-bottom: 20px;
	width: 54%;
}

@media (max-width: 480px) {

	.article .container .contentArticle .body .embedCourse .contentBox .dateContent,
	.prpratSingle .container .contentArticle .body .embedCourse .contentBox .dateContent,
	.course .container .contentArticle .body .embedCourse .contentBox .dateContent,
	.advertiseSingle .container .contentArticle .body .embedCourse .contentBox .dateContent,
	.vacantPosition .container .contentArticle .body .embedCourse .contentBox .dateContent {
		width: 80%;
	}
}

.article .container .contentArticle .body .embedCourse .contentBox .titleContent,
.prpratSingle .container .contentArticle .body .embedCourse .contentBox .titleContent,
.course .container .contentArticle .body .embedCourse .contentBox .titleContent,
.advertiseSingle .container .contentArticle .body .embedCourse .contentBox .titleContent,
.vacantPosition .container .contentArticle .body .embedCourse .contentBox .titleContent {
	color: #FFFFFF;
	font-family: $sans;
	font-size: 18px;

	line-height: 24px;
	width: 54%;
	margin-bottom: 20px;
	min-height: 104px;
}

@media (max-width: 480px) {

	.article .container .contentArticle .body .embedCourse .contentBox .titleContent,
	.prpratSingle .container .contentArticle .body .embedCourse .contentBox .titleContent,
	.course .container .contentArticle .body .embedCourse .contentBox .titleContent,
	.advertiseSingle .container .contentArticle .body .embedCourse .contentBox .titleContent,
	.vacantPosition .container .contentArticle .body .embedCourse .contentBox .titleContent {
		width: 100%;
		font-size: 16px;
		line-height: 20px;
	}
}

.article .container .contentArticle .body .embedCourse .contentBox .locationContent,
.prpratSingle .container .contentArticle .body .embedCourse .contentBox .locationContent,
.course .container .contentArticle .body .embedCourse .contentBox .locationContent,
.advertiseSingle .container .contentArticle .body .embedCourse .contentBox .locationContent,
.vacantPosition .container .contentArticle .body .embedCourse .contentBox .locationContent {
	color: #FFFFFF;
	font-family: $sans;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	width: 55%;
}

@media (max-width: 480px) {

	.article .container .contentArticle .body .embedCourse .contentBox .locationContent,
	.prpratSingle .container .contentArticle .body .embedCourse .contentBox .locationContent,
	.course .container .contentArticle .body .embedCourse .contentBox .locationContent,
	.advertiseSingle .container .contentArticle .body .embedCourse .contentBox .locationContent,
	.vacantPosition .container .contentArticle .body .embedCourse .contentBox .locationContent {
		width: 100%;
	}
}

.article .container .contentArticle .body .embedCourse .contentBox .locationContent:before,
.prpratSingle .container .contentArticle .body .embedCourse .contentBox .locationContent:before,
.course .container .contentArticle .body .embedCourse .contentBox .locationContent:before,
.advertiseSingle .container .contentArticle .body .embedCourse .contentBox .locationContent:before,
.vacantPosition .container .contentArticle .body .embedCourse .contentBox .locationContent:before {
	content: " ";
	display: inline-block;
	width: 20px;
	height: 20px;
	background: url("../../images/icon_map.svg") center center no-repeat;
	background-size: contain;
	vertical-align: middle;
	margin-right: 5px;
}

.article .container .contentArticle .body .statistics,
.prpratSingle .container .contentArticle .body .statistics,
.course .container .contentArticle .body .statistics,
.advertiseSingle .container .contentArticle .body .statistics,
.vacantPosition .container .contentArticle .body .statistics {
	margin: 50px -53.5%;
	overflow: hidden;
	border-radius: 3px;
	background-color: $old003C4C;
	padding-top: 80px;
	padding-bottom: 80px;
}

@media (max-width: 767px) {

	.article .container .contentArticle .body .statistics,
	.prpratSingle .container .contentArticle .body .statistics,
	.course .container .contentArticle .body .statistics,
	.advertiseSingle .container .contentArticle .body .statistics,
	.vacantPosition .container .contentArticle .body .statistics {
		margin: 50px -22.5%;
	}
}

@media (max-width: 480px) {

	.article .container .contentArticle .body .statistics,
	.prpratSingle .container .contentArticle .body .statistics,
	.course .container .contentArticle .body .statistics,
	.advertiseSingle .container .contentArticle .body .statistics,
	.vacantPosition .container .contentArticle .body .statistics {
		margin: 50px 0;
	}
}

.article .container .contentArticle .body .statistics .statisticsWrapper,
.prpratSingle .container .contentArticle .body .statistics .statisticsWrapper,
.course .container .contentArticle .body .statistics .statisticsWrapper,
.advertiseSingle .container .contentArticle .body .statistics .statisticsWrapper,
.vacantPosition .container .contentArticle .body .statistics .statisticsWrapper {
	margin-left: 10%;
	margin-right: 10%;
}

.article .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox,
.prpratSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox,
.course .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox,
.advertiseSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox,
.vacantPosition .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: -60px;
	margin-right: -60px;
}

.article .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox,
.prpratSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox,
.course .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox,
.advertiseSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox,
.vacantPosition .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox {
	width: 33.33333%;
	border-right: 1px solid #2e5f6c;
	padding: 0px 60px;
}

.article .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox:last-child,
.prpratSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox:last-child,
.course .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox:last-child,
.advertiseSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox:last-child,
.vacantPosition .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox:last-child {
	border-right: none;
}

@media (max-width: 1199px) {

	.article .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox,
	.prpratSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox,
	.course .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox,
	.advertiseSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox,
	.vacantPosition .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox {
		padding: 0 50px;
	}
}

@media (max-width: 767px) {

	.article .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox,
	.prpratSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox,
	.course .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox,
	.advertiseSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox,
	.vacantPosition .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox {
		width: 100%;
		border-right: none;
		border-bottom: 1px solid #2e5f6c;
		margin-bottom: 30px;
		padding-bottom: 30px;
	}

	.article .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox:last-child,
	.prpratSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox:last-child,
	.course .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox:last-child,
	.advertiseSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox:last-child,
	.vacantPosition .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox:last-child {
		border-bottom: none;
		padding-bottom: 0;
		padding-bottom: 0;
	}
}

.article .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .titleContent,
.prpratSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .titleContent,
.course .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .titleContent,
.advertiseSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .titleContent,
.vacantPosition .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .titleContent {
	color: #A2CD38;
	@include microfont("sm");
	font-size: 48px;
	line-height: 48px;
	text-align: center;
	margin-bottom: 30px;
}

.article .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .introContent,
.prpratSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .introContent,
.course .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .introContent,
.advertiseSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .introContent,
.vacantPosition .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .introContent {
	color: #FFFFFF;
	font-family: $sans;
	font-size: 16px;

	line-height: 26px;
	margin-bottom: 25px;
}

@media (max-width: 767px) {

	.article .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .introContent,
	.prpratSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .introContent,
	.course .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .introContent,
	.advertiseSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .introContent,
	.vacantPosition .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .introContent {
		text-align: center;
	}
}

.article .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .bodyContent,
.prpratSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .bodyContent,
.course .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .bodyContent,
.advertiseSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .bodyContent,
.vacantPosition .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .bodyContent {
	color: #FFFFFF;
	font-family: $sans;
	font-size: 14px;
	line-height: 22px;
}

@media (max-width: 767px) {

	.article .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .bodyContent,
	.prpratSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .bodyContent,
	.course .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .bodyContent,
	.advertiseSingle .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .bodyContent,
	.vacantPosition .container .contentArticle .body .statistics .statisticsWrapper .statisticsBox .contentBox .bodyContent {
		text-align: center;
	}
}

/*.article .container .contentArticle .body .embedClosed, .prpratSingle .container .contentArticle .body .embedClosed, .course .container .contentArticle .body .embedClosed, .advertiseSingle .container .contentArticle .body .embedClosed, .vacantPosition .container .contentArticle .body .embedClosed {
        height: 210px; }
      .article .container .contentArticle .body .embedOpened, .prpratSingle .container .contentArticle .body .embedOpened, .course .container .contentArticle .body .embedOpened, .advertiseSingle .container .contentArticle .body .embedOpened, .vacantPosition .container .contentArticle .body .embedOpened {
        height: 500px !important; }
        @media (max-width: 1199px) {
          .article .container .contentArticle .body .embedOpened, .prpratSingle .container .contentArticle .body .embedOpened, .course .container .contentArticle .body .embedOpened, .advertiseSingle .container .contentArticle .body .embedOpened, .vacantPosition .container .contentArticle .body .embedOpened {
            height: 565px !important; } }
        @media (max-width: 993px) {
          .article .container .contentArticle .body .embedOpened, .prpratSingle .container .contentArticle .body .embedOpened, .course .container .contentArticle .body .embedOpened, .advertiseSingle .container .contentArticle .body .embedOpened, .vacantPosition .container .contentArticle .body .embedOpened {
            height: 545px !important; }
            .article .container .contentArticle .body .embedOpened .moreContent, .prpratSingle .container .contentArticle .body .embedOpened .moreContent, .course .container .contentArticle .body .embedOpened .moreContent, .advertiseSingle .container .contentArticle .body .embedOpened .moreContent, .vacantPosition .container .contentArticle .body .embedOpened .moreContent {
              width: calc(100% + 100px) !important;
              margin-top: 0px !important; } }
        @media (max-width: 769px) {
          .article .container .contentArticle .body .embedOpened, .prpratSingle .container .contentArticle .body .embedOpened, .course .container .contentArticle .body .embedOpened, .advertiseSingle .container .contentArticle .body .embedOpened, .vacantPosition .container .contentArticle .body .embedOpened {
            height: 500px !important; }
            .article .container .contentArticle .body .embedOpened .moreContent, .prpratSingle .container .contentArticle .body .embedOpened .moreContent, .course .container .contentArticle .body .embedOpened .moreContent, .advertiseSingle .container .contentArticle .body .embedOpened .moreContent, .vacantPosition .container .contentArticle .body .embedOpened .moreContent {
              width: calc(100% + 100px) !important;
              margin-top: 50px !important; } }
        @media (max-width: 610px) {
          .article .container .contentArticle .body .embedOpened, .prpratSingle .container .contentArticle .body .embedOpened, .course .container .contentArticle .body .embedOpened, .advertiseSingle .container .contentArticle .body .embedOpened, .vacantPosition .container .contentArticle .body .embedOpened {
            height: 575px !important; }
            .article .container .contentArticle .body .embedOpened .moreContent, .prpratSingle .container .contentArticle .body .embedOpened .moreContent, .course .container .contentArticle .body .embedOpened .moreContent, .advertiseSingle .container .contentArticle .body .embedOpened .moreContent, .vacantPosition .container .contentArticle .body .embedOpened .moreContent {
              margin-top: 0px !important; } }
        @media (max-width: 480px) {
          .article .container .contentArticle .body .embedOpened, .prpratSingle .container .contentArticle .body .embedOpened, .course .container .contentArticle .body .embedOpened, .advertiseSingle .container .contentArticle .body .embedOpened, .vacantPosition .container .contentArticle .body .embedOpened {
            height: 430px !important; }
            .article .container .contentArticle .body .embedOpened .moreContent, .prpratSingle .container .contentArticle .body .embedOpened .moreContent, .course .container .contentArticle .body .embedOpened .moreContent, .advertiseSingle .container .contentArticle .body .embedOpened .moreContent, .vacantPosition .container .contentArticle .body .embedOpened .moreContent {
              width: calc(100% + 100px) !important;
              margin-top: 50px !important; } }
        @media (max-width: 440px) {
          .article .container .contentArticle .body .embedOpened, .prpratSingle .container .contentArticle .body .embedOpened, .course .container .contentArticle .body .embedOpened, .advertiseSingle .container .contentArticle .body .embedOpened, .vacantPosition .container .contentArticle .body .embedOpened {
            height: 475px !important; } }
        @media (max-width: 360px) {
          .article .container .contentArticle .body .embedOpened, .prpratSingle .container .contentArticle .body .embedOpened, .course .container .contentArticle .body .embedOpened, .advertiseSingle .container .contentArticle .body .embedOpened, .vacantPosition .container .contentArticle .body .embedOpened {
            height: 526px !important; } }*/

.article .container .contentArticle .body .tagsRelated,
.prpratSingle .container .contentArticle .body .tagsRelated,
.course .container .contentArticle .body .tagsRelated,
.advertiseSingle .container .contentArticle .body .tagsRelated,
.vacantPosition .container .contentArticle .body .tagsRelated {
	margin: 50px 0px;
}

.article .container .contentArticle .body .tagsRelated .titleContent,
.prpratSingle .container .contentArticle .body .tagsRelated .titleContent,
.course .container .contentArticle .body .tagsRelated .titleContent,
.advertiseSingle .container .contentArticle .body .tagsRelated .titleContent,
.vacantPosition .container .contentArticle .body .tagsRelated .titleContent {
	color: $oldblue;
	@include serifRegular;
	font-size: 20px;

	margin-bottom: 10px;
}

.article .container .contentArticle .body .tagsRelated .tagsBox,
.prpratSingle .container .contentArticle .body .tagsRelated .tagsBox,
.course .container .contentArticle .body .tagsRelated .tagsBox,
.advertiseSingle .container .contentArticle .body .tagsRelated .tagsBox,
.vacantPosition .container .contentArticle .body .tagsRelated .tagsBox {
	list-style-type: none;
	padding: 0;
	margin: 0 -7.5px;
}

.article .container .contentArticle .body .tagsRelated .tagsBox li,
.prpratSingle .container .contentArticle .body .tagsRelated .tagsBox li,
.course .container .contentArticle .body .tagsRelated .tagsBox li,
.advertiseSingle .container .contentArticle .body .tagsRelated .tagsBox li,
.vacantPosition .container .contentArticle .body .tagsRelated .tagsBox li {
	display: inline;
	margin: 7.5px;
}

.article .container .contentArticle .body .tagsRelated .tagsBox li a,
.prpratSingle .container .contentArticle .body .tagsRelated .tagsBox li a,
.course .container .contentArticle .body .tagsRelated .tagsBox li a,
.advertiseSingle .container .contentArticle .body .tagsRelated .tagsBox li a,
.vacantPosition .container .contentArticle .body .tagsRelated .tagsBox li a {
	color: $oldgray;
	font-family: $sans;
	font-size: 14px;
	line-height: 22px;
}

.article .container .contentArticle .body .tagsRelated .tagsBox li a:hover,
.prpratSingle .container .contentArticle .body .tagsRelated .tagsBox li a:hover,
.course .container .contentArticle .body .tagsRelated .tagsBox li a:hover,
.advertiseSingle .container .contentArticle .body .tagsRelated .tagsBox li a:hover,
.vacantPosition .container .contentArticle .body .tagsRelated .tagsBox li a:hover,
.article .container .contentArticle .body .tagsRelated .tagsBox li a:focus,
.prpratSingle .container .contentArticle .body .tagsRelated .tagsBox li a:focus,
.course .container .contentArticle .body .tagsRelated .tagsBox li a:focus,
.advertiseSingle .container .contentArticle .body .tagsRelated .tagsBox li a:focus,
.vacantPosition .container .contentArticle .body .tagsRelated .tagsBox li a:focus {
	color: $oldblue;
	font-family: $sans;
	font-size: 14px;
	line-height: 22px;
}

.sectionBlock .container .headerBox .contentIntro .factbox,
.article .container .contentArticle .body .factbox,
.prpratSingle .container .contentArticle .body .factbox,
.course .container .contentArticle .body .factbox,
.advertiseSingle .container .contentArticle .body .factbox,
.vacantPosition .container .contentArticle .body .factbox {
	margin-left: -53.5%;
	margin-right: -53.5%;
	position: relative;
}

@media (max-width: 767px) {

	.sectionBlock .container .headerBox .contentIntro .factbox,
	.article .container .contentArticle .body .factbox,
	.prpratSingle .container .contentArticle .body .factbox,
	.course .container .contentArticle .body .factbox,
	.advertiseSingle .container .contentArticle .body .factbox,
	.vacantPosition .container .contentArticle .body .factbox {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 45px;
		margin-top: 45px;
		border-bottom: 1px solid #E7EAE6;
	}
}

@media (max-width: 480px) {

	.sectionBlock .container .headerBox .contentIntro .factbox,
	.article .container .contentArticle .body .factbox,
	.prpratSingle .container .contentArticle .body .factbox,
	.course .container .contentArticle .body .factbox,
	.advertiseSingle .container .contentArticle .body .factbox,
	.vacantPosition .container .contentArticle .body .factbox {
		margin-bottom: 35px;
		margin-top: 35px;
	}
}

.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper,
.article .container .contentArticle .body .factbox .factboxWrapper,
.prpratSingle .container .contentArticle .body .factbox .factboxWrapper,
.course .container .contentArticle .body .factbox .factboxWrapper,
.advertiseSingle .container .contentArticle .body .factbox .factboxWrapper,
.vacantPosition .container .contentArticle .body .factbox .factboxWrapper {
	display: block;
	width: 16.66667%;
	top: 0;
	right: 0;
	position: absolute;
	padding-left: 20px;
}

@media (max-width: 767px) {

	.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper,
	.article .container .contentArticle .body .factbox .factboxWrapper,
	.prpratSingle .container .contentArticle .body .factbox .factboxWrapper,
	.course .container .contentArticle .body .factbox .factboxWrapper,
	.advertiseSingle .container .contentArticle .body .factbox .factboxWrapper,
	.vacantPosition .container .contentArticle .body .factbox .factboxWrapper {
		position: relative;
		padding: 0;
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom: 30px;
	}
}

.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .categoryBox,
.article .container .contentArticle .body .factbox .factboxWrapper .categoryBox,
.prpratSingle .container .contentArticle .body .factbox .factboxWrapper .categoryBox,
.course .container .contentArticle .body .factbox .factboxWrapper .categoryBox,
.advertiseSingle .container .contentArticle .body .factbox .factboxWrapper .categoryBox,
.vacantPosition .container .contentArticle .body .factbox .factboxWrapper .categoryBox {
	color: $old7D7D7D;
	@include microfont("sm");
	// font-size: 0.75rem;



	margin-bottom: 20px;
}

.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .categoryBox:before,
.article .container .contentArticle .body .factbox .factboxWrapper .categoryBox:before,
.prpratSingle .container .contentArticle .body .factbox .factboxWrapper .categoryBox:before,
.course .container .contentArticle .body .factbox .factboxWrapper .categoryBox:before,
.advertiseSingle .container .contentArticle .body .factbox .factboxWrapper .categoryBox:before,
.vacantPosition .container .contentArticle .body .factbox .factboxWrapper .categoryBox:before {
	content: " ";
	height: 2px;
	display: inline-block;
	width: 30px;
	margin-right: 10px;
	background-color: $green;
	position: relative;
	vertical-align: middle;
}

@media (max-width: 767px) {

	.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .categoryBox,
	.article .container .contentArticle .body .factbox .factboxWrapper .categoryBox,
	.prpratSingle .container .contentArticle .body .factbox .factboxWrapper .categoryBox,
	.course .container .contentArticle .body .factbox .factboxWrapper .categoryBox,
	.advertiseSingle .container .contentArticle .body .factbox .factboxWrapper .categoryBox,
	.vacantPosition .container .contentArticle .body .factbox .factboxWrapper .categoryBox {
		width: 100%;
		order: 1;
	}
}

.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .imageBox,
.article .container .contentArticle .body .factbox .factboxWrapper .imageBox,
.prpratSingle .container .contentArticle .body .factbox .factboxWrapper .imageBox,
.course .container .contentArticle .body .factbox .factboxWrapper .imageBox,
.advertiseSingle .container .contentArticle .body .factbox .factboxWrapper .imageBox,
.vacantPosition .container .contentArticle .body .factbox .factboxWrapper .imageBox {
	display: block;
	margin-bottom: 20px;
}

@media (max-width: 767px) {

	.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .imageBox,
	.article .container .contentArticle .body .factbox .factboxWrapper .imageBox,
	.prpratSingle .container .contentArticle .body .factbox .factboxWrapper .imageBox,
	.course .container .contentArticle .body .factbox .factboxWrapper .imageBox,
	.advertiseSingle .container .contentArticle .body .factbox .factboxWrapper .imageBox,
	.vacantPosition .container .contentArticle .body .factbox .factboxWrapper .imageBox {
		width: 33.33333%;
		order: 3;
	}
}

@media (max-width: 767px) {

	.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .contentBox,
	.article .container .contentArticle .body .factbox .factboxWrapper .contentBox,
	.prpratSingle .container .contentArticle .body .factbox .factboxWrapper .contentBox,
	.course .container .contentArticle .body .factbox .factboxWrapper .contentBox,
	.advertiseSingle .container .contentArticle .body .factbox .factboxWrapper .contentBox,
	.vacantPosition .container .contentArticle .body .factbox .factboxWrapper .contentBox {
		width: 66.66667%;
		order: 2;
		padding-right: 20px;
	}
}

.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .contentBox .titleContent,
.article .container .contentArticle .body .factbox .factboxWrapper .contentBox .titleContent,
.prpratSingle .container .contentArticle .body .factbox .factboxWrapper .contentBox .titleContent,
.course .container .contentArticle .body .factbox .factboxWrapper .contentBox .titleContent,
.advertiseSingle .container .contentArticle .body .factbox .factboxWrapper .contentBox .titleContent,
.vacantPosition .container .contentArticle .body .factbox .factboxWrapper .contentBox .titleContent {
	color: $oldblue;
	font-family: $sans;
	font-size: 16px;

	line-height: 22px;
	margin-bottom: 10px;
}

.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .contentBox .bodyContent,
.article .container .contentArticle .body .factbox .factboxWrapper .contentBox .bodyContent,
.prpratSingle .container .contentArticle .body .factbox .factboxWrapper .contentBox .bodyContent,
.course .container .contentArticle .body .factbox .factboxWrapper .contentBox .bodyContent,
.advertiseSingle .container .contentArticle .body .factbox .factboxWrapper .contentBox .bodyContent,
.vacantPosition .container .contentArticle .body .factbox .factboxWrapper .contentBox .bodyContent {
	color: $oldgray;
	font-family: $sans;
	font-size: 14px;
	line-height: 22px;
	word-break: break-word;
}

.article .container .socialNetwork,
.prpratSingle .container .socialNetwork,
.course .container .socialNetwork,
.advertiseSingle .container .socialNetwork,
.vacantPosition .container .socialNetwork {
	padding: 0px;
	margin: 0px;
	margin-left: -26.5%;
	margin-right: -26.5%;
	position: relative;
}

@media (max-width: 767px) {

	.article .container .socialNetwork,
	.prpratSingle .container .socialNetwork,
	.course .container .socialNetwork,
	.advertiseSingle .container .socialNetwork,
	.vacantPosition .container .socialNetwork {
		display: block;
		margin: 0;
		padding-left: 13%;
		padding-right: 13%;
		margin-bottom: 50px;
	}
}

@media (max-width: 480px) {

	.article .container .socialNetwork,
	.prpratSingle .container .socialNetwork,
	.course .container .socialNetwork,
	.advertiseSingle .container .socialNetwork,
	.vacantPosition .container .socialNetwork {
		margin-bottom: 30px;
	}
}

.article .container .socialNetwork .socialNetworkWrapper,
.prpratSingle .container .socialNetwork .socialNetworkWrapper,
.course .container .socialNetwork .socialNetworkWrapper,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper,
.vacantPosition .container .socialNetwork .socialNetworkWrapper {
	list-style-type: none;
	padding: 0;
	margin: 0;
	position: absolute;
	display: block;
	left: 0;
	top: 10px;
}

@media (max-width: 767px) {

	.article .container .socialNetwork .socialNetworkWrapper,
	.prpratSingle .container .socialNetwork .socialNetworkWrapper,
	.course .container .socialNetwork .socialNetworkWrapper,
	.advertiseSingle .container .socialNetwork .socialNetworkWrapper,
	.vacantPosition .container .socialNetwork .socialNetworkWrapper {
		position: relative;
		text-align: center;
	}
}

.article .container .socialNetwork .socialNetworkWrapper li,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li,
.course .container .socialNetwork .socialNetworkWrapper li,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li {
	margin-bottom: 10px;
}

@media (max-width: 767px) {

	.article .container .socialNetwork .socialNetworkWrapper li,
	.prpratSingle .container .socialNetwork .socialNetworkWrapper li,
	.course .container .socialNetwork .socialNetworkWrapper li,
	.advertiseSingle .container .socialNetwork .socialNetworkWrapper li,
	.vacantPosition .container .socialNetwork .socialNetworkWrapper li {
		display: inline-block;
		margin: 5px;
	}
}

.article .container .socialNetwork .socialNetworkWrapper li a,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a,
.course .container .socialNetwork .socialNetworkWrapper li a,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a {
	text-decoration: none;
}

.article .container .socialNetwork .socialNetworkWrapper li a span.icon,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon {
	display: inline-block;
}

.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:before {
	content: " ";
	display: inline-block;
	width: 36px;
	height: 36px;
	background: url("../../images/icon_facebook.svg") center center no-repeat;
	background-size: contain;
	vertical-align: middle;
}

.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:hover:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:hover:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:hover:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:hover:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:hover:before,
.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:active:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:active:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:active:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:active:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:active:before {
	background: url("../../images/icon_facebook_active.svg") center center no-repeat;
}

.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:focus:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:focus:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:focus:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:focus:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconFacebook:focus:before {
	background: url("../../images/icon_facebook_pressed.svg") center center no-repeat;
}

.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:before {
	content: " ";
	display: inline-block;
	width: 36px;
	height: 36px;
	background: url("../../images/icon_twitter.svg") center center no-repeat;
	background-size: contain;
	vertical-align: middle;
}

.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:hover:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:hover:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:hover:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:hover:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:hover:before,
.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:active:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:active:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:active:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:active:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:active:before {
	background: url("../../images/icon_twitter_active.svg") center center no-repeat;
}

.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:focus:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:focus:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:focus:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:focus:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconTwitter:focus:before {
	background: url("../../images/icon_twitter_pressed.svg") center center no-repeat;
}

.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:before {
	content: " ";
	display: inline-block;
	width: 36px;
	height: 36px;
	background: url("../../images/icon_linkedin.svg") center center no-repeat;
	background-size: contain;
	vertical-align: middle;
}

.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:hover:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:hover:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:hover:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:hover:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:hover:before,
.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:active:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:active:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:active:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:active:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:active:before {
	background: url("../../images/icon_linkedin_active.svg") center center no-repeat;
}

.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:focus:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:focus:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:focus:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:focus:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconLinkedin:focus:before {
	background: url("../../images/icon_linkedin_pressed.svg") center center no-repeat;
}

.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:before {
	content: " ";
	display: inline-block;
	width: 36px;
	height: 36px;
	background: url("../../images/icon_mail.svg") center center no-repeat;
	background-size: contain;
	vertical-align: middle;
}

.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:hover:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:hover:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:hover:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:hover:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:hover:before,
.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:active:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:active:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:active:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:active:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:active:before {
	background: url("../../images/icon_mail_active.svg") center center no-repeat;
}

.article .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:focus:before,
.prpratSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:focus:before,
.course .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:focus:before,
.advertiseSingle .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:focus:before,
.vacantPosition .container .socialNetwork .socialNetworkWrapper li a span.icon.iconMail:focus:before {
	background: url("../../images/icon_mail_pressed.svg") center center no-repeat;
}

.article .imageArticle+.container .contentArticle,
.prpratSingle .imageArticle+.container .contentArticle,
.course .imageArticle+.container .contentArticle,
.advertiseSingle .imageArticle+.container .contentArticle,
.vacantPosition .imageArticle+.container .contentArticle {
	margin-top: -80px;
}

.article .container.statistics .row.statisticsWrapper .statisticsBox .contentBox p,
.prpratSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox p,
.course .container.statistics .row.statisticsWrapper .statisticsBox .contentBox p,
.advertiseSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox p,
.vacantPosition .container.statistics .row.statisticsWrapper .statisticsBox .contentBox p {
	color: #FFFFFF;
	font-family: $sans;
	font-size: 14px;
	line-height: 22px;
}

.article .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-12,
.prpratSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-12,
.course .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-12,
.advertiseSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-12,
.vacantPosition .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-12 {
	width: 100%;
}

@media(max-width: 767px) {

	.article .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-12.col-sm-12,
	.prpratSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-12.col-sm-12,
	.course .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-12.col-sm-12,
	.advertiseSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-12.col-sm-12,
	.vacantPosition .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-12.col-sm-12 {
		width: 100%;
	}
}

.article .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-6,
.prpratSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-6,
.course .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-6,
.advertiseSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-6,
.vacantPosition .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-6 {
	width: 50%;
}

@media(max-width: 767px) {

	.article .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-6.col-sm-12,
	.prpratSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-6.col-sm-12,
	.course .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-6.col-sm-12,
	.advertiseSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-6.col-sm-12,
	.vacantPosition .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-6.col-sm-12 {
		width: 100%;
	}
}

.article .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-4,
.prpratSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-4,
.course .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-4,
.advertiseSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-4,
.vacantPosition .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-4 {
	width: 33%;
}

@media(max-width: 767px) {

	.article .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-4.col-sm-12,
	.prpratSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-4.col-sm-12,
	.course .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-4.col-sm-12,
	.advertiseSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-4.col-sm-12,
	.vacantPosition .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-4.col-sm-12 {
		width: 100%;
	}
}

.article .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-3,
.prpratSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-3,
.course .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-3,
.advertiseSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-3,
.vacantPosition .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-3 {
	width: 25%;
	padding-left: 20px;
	padding-right: 20px;
}

@media(max-width: 767px) {

	.article .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-3.col-sm-12,
	.prpratSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-3.col-sm-12,
	.course .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-3.col-sm-12,
	.advertiseSingle .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-3.col-sm-12,
	.vacantPosition .container.statistics .row.statisticsWrapper .statisticsBox .contentBox.col-md-3.col-sm-12 {
		width: 100%;
		padding-left: 50px;
		padding-right: 50px;
	}
}
.prpratSingle .imageArticle+.container .contentArticle .intro {
	background: #FFFFFF;
	padding-bottom: 10px;
}

@media (min-width: 769px) {
	.prpratSingle .imageArticle+.container .contentArticle .intro {
		position: relative;
		margin-left: -26.5%;
		margin-right: 45%;
		margin-top: -35%;
	}
}

.article .container .book-review.contentArticle,
.prpratSingle .container .book-review.contentArticle,
.course .container .book-review.contentArticle,
.advertiseSingle .container .book-review.contentArticle,
.vacantPosition .container .book-review.contentArticle {
	display: block;
	margin: 70px auto 0 !important;
}

.article .container .book-review.contentArticle header div span,
.prpratSingle .container .book-review.contentArticle header div span,
.course .container .book-review.contentArticle header div span,
.advertiseSingle .container .book-review.contentArticle header div span,
.vacantPosition .container .book-review.contentArticle header div span {
	margin-right: 10px;
}

.article .container .book-review.contentArticle header h1,
.prpratSingle .container .book-review.contentArticle header h1,
.course .container .book-review.contentArticle header h1,
.advertiseSingle .container .book-review.contentArticle header h1,
.vacantPosition .container .book-review.contentArticle header h1 {
	font-size: 24px;
	margin-top: 11px;
	weight: bold;
	color: $oldblue;
}

.article .container .book-review.contentArticle .intro,
.prpratSingle .container .book-review.contentArticle .intro,
.course .container .book-review.contentArticle .intro,
.advertiseSingle .container .book-review.contentArticle .intro,
.vacantPosition .container .book-review.contentArticle .intro {
	margin: 0;
	padding: 0;
}

.article .container .book-review.contentArticle .intro:after,
.prpratSingle .container .book-review.contentArticle .intro:after,
.course .container .book-review.contentArticle .intro:after,
.advertiseSingle .container .book-review.contentArticle .intro:after,
.vacantPosition .container .book-review.contentArticle .intro:after {
	display: none;
}

.article .container .book-review.contentArticle .first-label,
.prpratSingle .container .book-review.contentArticle .first-label,
.course .container .book-review.contentArticle .first-label,
.advertiseSingle .container .book-review.contentArticle .first-label,
.vacantPosition .container .book-review.contentArticle .first-label {
	color: $old7D7D7D;
	@include microfont("sm");
}

.article .container .book-review.contentArticle .info-label,
.prpratSingle .container .book-review.contentArticle .info-label,
.course .container .book-review.contentArticle .info-label,
.advertiseSingle .container .book-review.contentArticle .info-label,
.vacantPosition .container .book-review.contentArticle .info-label {
	color: $oldgray;
	margin: 10px 0 20px;
	font-size: 14px;
}

.article .container .book-review.contentArticle .info-rev,
.prpratSingle .container .book-review.contentArticle .info-rev,
.course .container .book-review.contentArticle .info-rev,
.advertiseSingle .container .book-review.contentArticle .info-rev,
.vacantPosition .container .book-review.contentArticle .info-rev {
	font-size: 16px;
	color: $oldblue;
	padding: 19px 0 15px;
	margin-bottom: 20px;
	border-top: 1px solid #E7EAE6;
	border-bottom: 1px solid #E7EAE6;
}

.article .container .book-review.contentArticle .description-info,
.prpratSingle .container .book-review.contentArticle .description-info,
.course .container .book-review.contentArticle .description-info,
.advertiseSingle .container .book-review.contentArticle .description-info,
.vacantPosition .container .book-review.contentArticle .description-info {
	color: $oldblue;
	padding: 0 0 60px;
	border-bottom: 1px solid #E7EAE6;
	margin-bottom: 40px;
}

.article .container .book-review.contentArticle .description-info img,
.prpratSingle .container .book-review.contentArticle .description-info img,
.course .container .book-review.contentArticle .description-info img,
.advertiseSingle .container .book-review.contentArticle .description-info img,
.vacantPosition .container .book-review.contentArticle .description-info img {
	max-width: 100%;
	box-shadow: 0 2px 4px 0 rgba(49, 48, 58, 0.2);
}

@media screen and (max-width: 500px) {

	.article .container .book-review.contentArticle .info-label,
	.prpratSingle .container .book-review.contentArticle .info-label,
	.course .container .book-review.contentArticle .info-label,
	.advertiseSingle .container .book-review.contentArticle .info-label,
	.vacantPosition .container .book-review.contentArticle .info-label {
		margin: 10px 0 0px;
	}

	.article .container .book-review.contentArticle .socialNetwork,
	.prpratSingle .container .book-review.contentArticle .socialNetwork,
	.course .container .book-review.contentArticle .socialNetwork,
	.advertiseSingle .container .book-review.contentArticle .socialNetwork,
	.vacantPosition .container .book-review.contentArticle .socialNetwork {
		margin-bottom: 30px;
		padding: 0;
	}

	.article .container .book-review.contentArticle .socialNetwork .socialNetworkWrapper,
	.prpratSingle .container .book-review.contentArticle .socialNetwork .socialNetworkWrapper,
	.course .container .book-review.contentArticle .socialNetwork .socialNetworkWrapper,
	.advertiseSingle .container .book-review.contentArticle .socialNetwork .socialNetworkWrapper,
	.vacantPosition .container .book-review.contentArticle .socialNetwork .socialNetworkWrapper {
		text-align: left;
	}

	.article .container .book-review.contentArticle .socialNetwork .socialNetworkWrapper li,
	.prpratSingle .container .book-review.contentArticle .socialNetwork .socialNetworkWrapper li,
	.course .container .book-review.contentArticle .socialNetwork .socialNetworkWrapper li,
	.advertiseSingle .container .book-review.contentArticle .socialNetwork .socialNetworkWrapper li,
	.vacantPosition .container .book-review.contentArticle .socialNetwork .socialNetworkWrapper li {
		margin-left: 0;
	}
}

.course .container .contentArticle {
	background: #fff;
	display: block;
	margin-top: 0;
}

.course .container .contentArticle .body {
	margin-bottom: 40px;
}

.course .container .contentArticle .body h1 {
	color: $oldblue;
	@include serifHeading;
	font-size: 28px;

	line-height: 34px;
}

.course .container .contentArticle .body h2,
.course .container .contentArticle .body h3,
.course .container .contentArticle .body h4,
.course .container .contentArticle .body h5,
.course .container .contentArticle .body h6 {
	color: $oldblue;
	font-family: $sans;

	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
}

.course .container .contentArticle .infoBox {
	padding-left: 13%;
	padding-right: 13%;
	margin-bottom: 40px;
}

@media (max-width: 480px) {
	.course .container .contentArticle .infoBox {
		padding: 0;
	}
}

.course .container .contentArticle .infoBox .infoBoxDate {
	color: $oldblue;
	font-family: $sans;
	font-size: 16px;

	line-height: 26px;
}

.course .container .contentArticle .infoBox .infoBoxSubscribers {
	color: $oldblue;
	font-family: $sans;
	font-size: 16px;

	line-height: 26px;
}

.course .container .contentArticle .infoBox .infoBoxLink {
	margin-top: 30px;
}

.course .container .contentArticle .infoBox .infoBoxLink>a {
	border-bottom: 3px solid $green;
	display: inline-block;
	color: $oldblue;
	font-family: $sans;
	font-size: 1rem;
	font-weight: 500;
	line-height: 26px;
}

.course .container .contentArticle .infoBox .infoBoxLink>a:hover,
.course .container .contentArticle .infoBox .infoBoxLink>a:focus,
.course .container .contentArticle .infoBox .infoBoxLink>a:active {
	border-bottom: 3px solid $green;
}

.course .container .contentArticle .infoBox .infoBoxLink>a.active {
	border-bottom: 3px solid $green;
}

.course .container .contentArticle .priceWrapper {
	padding-left: 13%;
	padding-right: 13%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: 50px;
	margin-left: -10px;
	margin-right: -10px;
}

@media (max-width: 480px) {
	.course .container .contentArticle .priceWrapper {
		padding: 0;
	}
}

.course .container .contentArticle .priceWrapper .priceItem {
	width: 50%;
	padding-left: 10px;
	padding-right: 10px;
}

@media (max-width: 767px) {
	.course .container .contentArticle .priceWrapper .priceItem {
		width: 100%;
		margin-bottom: 20px;
	}
}

.course .container .contentArticle .priceWrapper .priceItem .priceBox {
	border: 1px solid #E7EAE6;
	border-radius: 3px;
	padding: 30px;
	width: 100%;
	height: 100%;
}

.course .container .contentArticle .priceWrapper .priceItem .priceBox .priceTitle {
	color: $old7D7D7D;
	@include microfont("sm");
	// font-size: 0.75rem;


	text-align: center;

	margin-bottom: 50px;
}

.course .container .contentArticle .priceWrapper .priceItem .priceBox .priceValue {
	color: $old003C4C;
	@include microfont("sm");
	font-size: 48px;
	line-height: 48px;
	text-align: center;
	margin-bottom: 50px;
}

@media (max-width: 767px) {
	.course .container .contentArticle .priceWrapper .priceItem .priceBox .priceButton {
		text-align: center;
	}
}

.course .container .contentArticle .priceWrapper .priceItem .priceBox .priceButton>a {
	width: 100%;
	min-width: unset;
	padding: 19px 30px;
}

@media (max-width: 767px) {
	.course .container .contentArticle .priceWrapper .priceItem .priceBox .priceButton>a {
		width: unset;
	}
}

.course .container .contentArticle .priceWrapper .priceBoxLink {
	width: 100%;
	padding: 25px 0px;
	text-align: center;
}

.course .container .contentArticle .priceWrapper .priceBoxLink>a {
	border-bottom: 3px solid $green;
	display: inline-block;
	color: $oldblue;
	font-family: $sans;
	font-size: 1rem;
	font-weight: 500;
	line-height: 26px;
}

.course .container .contentArticle .priceWrapper .priceBoxLink>a:hover,
.course .container .contentArticle .priceWrapper .priceBoxLink>a:focus,
.course .container .contentArticle .priceWrapper .priceBoxLink>a:active {
	border-bottom: 3px solid $green;
}

.course .container .contentArticle .priceWrapper .priceBoxLink>a.active {
	border-bottom: 3px solid $green;
}
.advertiseSingle .container .contentArticle {
	background: transparent;
	margin-top: 50px;
	margin-bottom: 80px;
}

.advertiseSingle .container .contentArticle .intro {
	background: #FFFFFF;
	padding-bottom: 10px;
}
.vacantPosition .container .contentArticle {
	background: transparent;
	margin-top: 0px;
	margin-bottom: 80px;
}

.vacantPosition .container .header {
	word-break: break-word;
}

.vacantPosition .container .contentArticle .body h1 {
	color: $oldblue;
	font-size: 28px;

	line-height: 34px;
}

.vacantPosition .container .contentArticle .body h2,
.vacantPosition .container .contentArticle .body h3,
.vacantPosition .container .contentArticle .body h4,
.vacantPosition .container .contentArticle .body h5,
.vacantPosition .container .contentArticle .body h6 {
	color: $oldblue;
	font-family: $sans;
	font-size: 24px;

	line-height: 30px;
}

/**************** Fakta center *************************************************************/

.article .container .contentArticle .body .factbox-center,
.prpratSingle .container .contentArticle .body .factbox-center,
.course .container .contentArticle .body .factbox-center,
.advertiseSingle .container .contentArticle .body .factbox-center,
.vacantPosition .container .contentArticle .body .factbox-center {
    margin-left: -27.5%;
    margin-right: -36.5%;
    margin-bottom: 30px;
    position: relative;
}

.article .container .contentArticle .body .factbox-center .factboxWrapper,
.prpratSingle .container .contentArticle .body .factbox-center .factboxWrapper,
.course .container .contentArticle .body .factbox-center .factboxWrapper,
.advertiseSingle .container .contentArticle .body .factbox-center .factboxWrapper,
.vacantPosition .container .contentArticle .body .factbox-center .factboxWrapper {
    display: block;
    width: 100%;
    top: 0;
    right: 0;
    padding-left: 20px;
}

.article .container .contentArticle .body .factbox-center .factboxWrapper .categoryBox,
.prpratSingle .container .contentArticle .body .factbox-center .factboxWrapper .categoryBox,
.course .container .contentArticle .body .factbox-center .factboxWrapper .categoryBox,
.advertiseSingle .container .contentArticle .body .factbox-center .factboxWrapper .categoryBox,
.vacantPosition .container .contentArticle .body .factbox-center .factboxWrapper .categoryBox {
    color: $old7D7D7D;
    @include microfont("sm");
    // font-size: 0.75rem;
    padding-bottom: 10px;
    padding-left: 10px;
}

.article .container .contentArticle .body .factbox-center .factboxWrapper .contentBox .titleContent,
.prpratSingle .container .contentArticle .body .factbox-center .factboxWrapper .contentBox .titleContent,
.course .container .contentArticle .body .factbox-center .factboxWrapper .contentBox .titleContent,
.advertiseSingle .container .contentArticle .body .factbox-center .factboxWrapper .contentBox .titleContent,
.vacantPosition .container .contentArticle .body .factbox-center .factboxWrapper .contentBox .titleContent {
    color: $oldblue;
    font-family: $sans;
    font-size: 24px;

    line-height: 30px;
}

.article .container .contentArticle .body .factbox-center .factboxWrapper .contentBox .bodyContent,
.prpratSingle .container .contentArticle .body .factbox-center .factboxWrapper .contentBox .bodyContent,
.course .container .contentArticle .body .factbox-center .factboxWrapper .contentBox .bodyContent,
.advertiseSingle .container .contentArticle .body .factbox-center .factboxWrapper .contentBox .bodyContent,
.vacantPosition .container .contentArticle .body .factbox-center .factboxWrapper .contentBox .bodyContent {
    color: $oldblue;
    font-family: $sans;
    font-size: 16px;
    line-height: 26px;
    padding-right: 50px;
}

/**************** Fakta center *************************************************************/

.article .container .contentArticle .body h1,
.prpratSingle .container .contentArticle .body h1,
.advertiseSingle .container .contentArticle .body h1,
.vacantPosition .container .contentArticle .body h1,
.article .container .contentArticle .body h2,
.prpratSingle .container .contentArticle .body h2,
.advertiseSingle .container .contentArticle .body h2,
.article .container .contentArticle .body h3,
.prpratSingle .container .contentArticle .body h3,
.advertiseSingle .container .contentArticle .body h3,
.article .container .contentArticle .body h4,
.prpratSingle .container .contentArticle .body h4,
.advertiseSingle .container .contentArticle .body h4,
.article .container .contentArticle .body h5,
.prpratSingle .container .contentArticle .body h5,
.advertiseSingle .container .contentArticle .body h5,
.article .container .contentArticle .body h6,
.prpratSingle .container .contentArticle .body h6,
.advertiseSingle .container .contentArticle .body h6 {
    font-family: "PT Serif", Serif;
    color: $oldblue;
}

.article .container .book-review.contentArticle .body h1 {
    font-family: $sans;
}

article .container .contentArticle .body figure {
    margin-top: 0;
    margin-bottom: 0;
}

article .container .contentArticle .body figure.justify {
    margin-left: -40%;
    margin-right: -40%;
    overflow: hidden;
}

article .container .contentArticle .body figure.center {
    margin-left: -18%;
    margin-right: -18%;
    width: 100% !important;
    overflow: hidden;
}

article .container .contentArticle .body a:not(.button),
.headerBox>.contentIntro>p>a:not(.button),
.contentBox>.contentIntro>p>a:not(.button),
.contactBox>p>a:not(.button),
.book-list_cards .moreContent p a:not(.button),
.agency-list_cards .moreContent p a:not(.button),
.bg-blue p a:not(.button),
.modalAccessOpen p a:not(.button) {
    text-decoration: underline !important;
    -webkit-text-decoration-color: $green !important;
    text-decoration-color: $green !important;
    -webkit-text-underline-position: under;
    -ms-text-underline-position: below;
    text-underline-position: under;
    color: $oldblue;
    border-width: 1px !important;
}


article .container .contentArticle .body figure figcaption {
    color: $old7D7D7D;
    font-family: $sans;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #e7eae6;
    padding-top: 20px;
    padding-bottom: 10px;
}

article .container .contentArticle .body figure.justify figcaption {
    margin-left: 22%;
    margin-right: 22%;
}

@media (max-width: 767px) {
    article .container .contentArticle .body figure.justify figcaption {
        margin-left: 0;
        margin-right: 0;
    }
    .contentArticle figure.justify {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

/*************************************************************************/

.article .container .contentArticle .video-block {
    padding-left: 13%;
    padding-right: 13%;
}

.article .container .contentArticle .body h2 {
    color: $oldblue;
    font-size: 20px;

    line-height: 32px;
    font-family: "PT Serif", Serif;
}

.article .container .book-review.contentArticle .body h2 {
    font-family: $sans;
}

.article .container .contentArticle .body .callToAction .boxCTA .buttonCTA>a {
    text-decoration: none !important;
}

.article .container .contentArticle .body ul li,
.prpratSingle .container .contentArticle .body ul li,
.course .container .contentArticle .body ul li,
.advertiseSingle .container .contentArticle .body ul li,
.vacantPosition .container .contentArticle .body ul li,
.article .container .contentArticle .body ol li,
.prpratSingle .container .contentArticle .body ol li,
.course .container .contentArticle .body ol li,
.advertiseSingle .container .contentArticle .body ol li,
.vacantPosition .container .contentArticle .body ol li {
    color: $oldblue;
    @include serifRegular;
    font-size: 18px;
    line-height: 32px;
    list-style-position: outside;
}
#hostseminaret .contentArticle .infoTableRow .icon {
    margin-bottom: 0;
}
#hostseminaret .article .container .contentArticle .body p,
#hostseminaret .container .contentArticle .body p,
#hostseminaret.article .container .contentArticle .body ul li,
#hostseminaret.container .contentArticle .body ul li {
    font-family: $sans;
    font-size: 16px;
    line-height: 26px;
}


@media (max-width: 576px) {
    .article .container .contentArticle .body ul li,
    .prpratSingle .container .contentArticle .body ul li,
    .course .container .contentArticle .body ul li,
    .advertiseSingle .container .contentArticle .body ul li,
    .vacantPosition .container .contentArticle .body ul li,
    .article .container .contentArticle .body ol li,
    .prpratSingle .container .contentArticle .body ol li,
    .course .container .contentArticle .body ol li,
    .advertiseSingle .container .contentArticle .body ol li,
    .vacantPosition .container .contentArticle .body ol li {
        font-size: 14px;
        line-height: 22px;
    }
}

@media (max-width: 576px) {
    .vacantPosition .container .header {
        padding-left: 0;
        padding-right: 0;
    }
    .vacantBox .icon.iconCalendar:before {
        top: 0;
    }
    .vacantBox .icon.iconCalendar {
        font-size: 0.75rem;
    }
    .vacantBox .icon {
        margin-bottom: 0;
    }
}
