.skip-header {
	position: fixed;
	left: 0;
	top: 0;
	transform: translateY(-101%);
	background-color: #fff;
	padding: 5px;
	transition: .01s;

	&:focus-visible {
		transform: none;
	}
}

.header-modal,
.main-header {
	min-height: 150px;
	z-index: 10000;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: var(--page-color);

	@media (max-width: 991px) {
		font-size: 1rem;
		min-height: 64px;
	}

	+* {
		margin-top: 150px;

		@media (max-width: 991px) {
			margin-top: 64px;
		}
	}

	.button {
		padding: 6px 22px;
	}

	.button-link {
		text-decoration-line: none;

		.icon {
			width: 18px;
			height: 18px;

			@media (max-width: 991px) {
				width: 14px;
				height: 14px;
			}
		}
	}

	.login-button__name {
		max-width: 250px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	&__logo {
		height: 70px;
		object-fit: contain;
		margin-right: 64px;

		@media (max-width: 991px) {
			height: 32px;
			margin-right: 24px;
		}

		img {
			width: auto;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
		}
	}

	&__bar {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 40px 0;

		@media (max-width: 991px) {
			padding: 16px 0;

		}

		.button-link {
			@media (max-width: 991px) {
				line-height: 1;
			}
		}
	}

	&__links {
		display: flex;
		flex-wrap: wrap;
		column-gap: 48px;
		row-gap: 8px;
		align-items: center;
		flex-shrink: 0;

		@include fontBody(sm);

		* {
			font-size: inherit;
		}

		form {
			display: contents;
		}

		.login-button,
		.search-link {
			@media (max-width: 767px) {
				display: none;
			}
		}

		.button-secondary {
			@media (max-width: 1199px) {
				display: none;
			}
		}
	}

	&.mega-nav {
		background-color: var(--page-color);
		color: $offblack;
		position: fixed;
		overflow-y: scroll;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		&:-webkit-scrollbar {
			display: none;
		}

		.menu-button-toggle {
			&::after {
				content: '';
				display: block;
				width: 18px;
				height: 18px;
				background-image: url('../../images/icon/close-18.svg');
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				filter: brightness(0) saturate(100%) invert(8%) sepia(22%) saturate(1364%) hue-rotate(65deg) brightness(97%) contrast(99%);

				@media (max-width: 991px) {
					width: 14px;
					height: 14px;
					background-size: 12px;
				}
			}

			img {
				display: none;
			}
		}
	}

	&.header-inverted {
		background-color: $offblack;
		color: $lightgreen;

		.button-link img,
		.main-header__logo img {
			filter: brightness(0) saturate(100%) invert(94%) sepia(22%) saturate(165%) hue-rotate(29deg) brightness(97%) contrast(96%);
		}
	}

	&.header-lightgreen {
		background-color: $lightgreen;

		&.headerFixed {
			background: $lightgreen;
		}
	}
}

.headerFixed.is-sticky {
	.main-header {
		background-color: white;

		&.header-inverted {
			background-color: $offblack;
		}

		&.mega-nav {
			background-color: var(--page-color);
		}
	}
}

.nav-items {
	display: none;

	&.menu-active {
		display: block;
	}

	@media (max-width: 991px) {
		font-size: -to-rem(20);
	}

	.button-link {
		text-decoration-line: none;

		.icon {
			width: 18px;
			height: 18px;

			@media (max-width: 991px) {
				width: 14px;
				height: 14px;
			}
		}
	}

	.container {
		margin-top: 140px;

		@media (max-width: 767px) {
			margin: 24px;
			margin-bottom: 60px;
		}
	}

	.search-form,
	.login-button {
		@media (min-width: 768px) {
			display: none !important;
		}
	}

	.search-form {
		display: flex;
		gap: 8px;
		flex-wrap: wrap;
		justify-content: center;

		.form-control {
			min-height: 46px;
			border: none;
			flex: 1;
			min-width: 10rem;
		}
	}

	.login-button {
		text-align: right;
		margin-bottom: 16px;
		margin-left: auto;
		width: fit-content;

		img {
			filter: brightness(0) saturate(100%) invert(94%) sepia(22%) saturate(165%) hue-rotate(29deg) brightness(97%) contrast(96%);
		}
	}

	// &-wrap {
	// 	display: flex;
	// 	flex-wrap: wrap;
	// 	gap: 48px;

	// 	@media (max-width: 767px) {
	// 		gap: 32px;
	// 		margin-bottom: 56px;
	// 	}

	// 	>* {
	// 		flex: 0 0 calc(20% - 38.5px);

	// 		@media (max-width: 1199px) {
	// 			flex: 0 0 calc(33.3% - 32px);
	// 		}

	// 		@media (max-width: 767px) {
	// 			flex: 0 0 calc(50% - 16px);
	// 		}

	// 		@media (max-width: 600px) {
	// 			flex: 0 0 100%;
	// 		}

	// 	}
	// }

	a {
		display: inline-block;
		// padding: 8px 0;
		color: inherit;
		text-decoration-line: none;

		// @media (max-width: 767px) {
		// 	gap: 32px;
		// 	padding: 8px 0;
		// }

		// &:hover,
		// &:focus-visible {
		// 	color: $green;
		// }

		@media (min-width: 992px) {
			display: block;
		}

		@media (hover: hover) {
			&:hover {
				background: $offblack;
				color: $lightgreen;
			}
		}
	}

	ul:not(.content) {
		list-style: none;
		padding: 0;

		li {
			border-bottom: 1px solid $lightgreen;
		}
	}

	.item-top {
		text-align: left;
		background-color: transparent;
		border: none;
		@include heading(h3);
		@include serifHeading;
		color: $green;
		padding: 8px 0;
		border-bottom: 1px solid $lightgreen;
		display: flex;
		width: 100%;

		@media (max-width: 767px) {
			padding: 8px 0;
		}
	}

	a.item-top {
		@media (max-width: 767px) {
			display: none;
		}
	}

	button.item-top {
		@media (min-width: 768px) {
			display: none;
		}

		&[aria-expanded=false] {
			&:after {
				content: '+';
				color: $green;
				margin-left: auto;
				min-width: -to-rem(14);
				text-align: center;
			}
		}

		&[aria-expanded=true] {
			&:after {
				content: '-';
				color: $green;
				margin-left: auto;
				min-width: -to-rem(14);
				text-align: center;
			}
		}
	}

	.item-mobile {
		@media (min-width: 768px) {
			display: none;
		}
	}

	.item-wrap {
		overflow: hidden;
		transition: .5s;

		@media (min-width: 768px) {
			height: unset !important;
		}
	}

	.container {
		width: auto;
	}
}

.mega-nav {
	&__column {
		display: flex;
		flex-direction: column;
		gap: 24px;

		@media (min-width: 993px) {
			&.space-fix-right {
				padding-right: 28px;
			}

			&.space-fix-left {
				padding-left: 28px;
			}
		}

		.title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 18px;
			line-height: 1.5;
			font-weight: 500;
			padding-bottom: 12px;
			border-bottom: 2px solid $offblack;


			a {
				display: inline-flex;
				gap: 8px;

				&::after {
					content: "";
					display: block;
					width: -to-rem(24);
					height: -to-rem(24);
					background-image: url("../../images/icon/arrow-right-24.svg");
					background-size: 100%;
					background-repeat: no-repeat;
					background-position: center;
					margin-right: 4px;
				}

				&:hover:after {
					filter: brightness(0) saturate(100%) invert(91%) sepia(32%) saturate(130%) hue-rotate(39deg) brightness(103%) contrast(90%);
				}
			}
		}


		.intro {
			font-size: 16px;
			line-height: 1.5;

			@media (max-width: 767px) {
				display: none;
			}
		}

		.content-wrapper {
			width: 100%;
		}

		.content {
			display: flex;
			flex-direction: column;
			gap: 12px;
			font-size: 18px;
			font-weight: 500;
			list-style: none;
			padding: 0;
			margin: 0;
		}

		&:not(.mega-nav__column--double) {
			.content {
				li {
					a {
						@media (hover: hover) {
							&:hover {
								background: $offblack;
								color: $lightgreen;
							}
						}
					}
				}
			}
		}

		&--double {
			[data-trigger] {
				position: relative;
				border: none;
				background-color: transparent;
				font-weight: bold;

				span {
					pointer-events: none;
					display: block;
					rotate: initial
				}

				&::before {
					content: "";
					position: absolute;
					inset: -12px;
				}

				display: none;

				@media (max-width: 767px) {
					display: block;
				}
			}

			&[aria-expanded="false"] {
				[data-trigger] {
					span {
						rotate: 45deg;
					}
				}
			}

			.content-wrapper {
				@media (max-width: 767px) {
					display: flex;
					flex-direction: column;
					gap: 8px;
					overflow-y: hidden;
					transition: max-height 175ms ease-out;
				}
			}

			.content {
				height: 100%;
				gap: 8px;

				li {
					border: 2px solid $offblack;
					border-radius: 4px;

					@media (max-width: 767px) {
						font-size: 16px;
					}

					@media (hover: hover) {
						&:hover {
							background: $offblack;
							color: $lightgreen;
						}
					}

					a {
						display: block;
						padding: 14px 16px;
						outline-offset: 4px;
						border-radius: 1px;
					}
				}
			}

			@media (min-width: 993px) {
				padding-right: 56px;
			}

			@media (min-width: 767px) {
				.content-wrapper {
					display: flex;
					gap: 12px;

					.content {
						width: calc((100% - 12px) / 2);
					}
				}
			}
		}
	}

	.row {
		@media (max-width: 1199px) {
			margin-bottom: 48px;
		}

		*[class*="col"] {
			@media (max-width: 991px) {
				&:not(:first-child) {
					.mega-nav__column {
						margin-top: 48px;
					}
				}
			}
		}
	}

	.mega-nav__cta {
		.button-group {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 8px;
			margin-bottom: 48px;
			@include fontBody(sm);

			* {
				font-size: inherit;
				line-height: inherit;
			}

			.login-button {
				display: flex;
				align-items: center;
				gap: 10px;
				padding: 8px 24px;
				border: 2px solid $offblack;
				border-radius: 30px;
				margin: 0;

				img {
					filter: none;
				}
			}

			.button-cta {
				display: flex;
				align-items: center;
				padding: 8px 24px;

				@media (min-width: 1200px) {
					display: none;
				}
			}
		}

		.search-form {
			.form-control {
				border: 2px solid $offblack;
				@include fontBody(sm)
			}

			.button {
				display: flex;
				align-items: center;
				gap: 10px;
				background-color: transparent;
				border: 2px solid $offblack;
				padding: 8px 24px;
				@include fontBody(sm);

				&:hover {
					color: currentColor;
					background-color: $green;
				}
			}
		}

	}
}

.operationalNotice {
	display: flex;
	align-items: center;
	position: sticky;
	background-color: $offblack;
	color: $offwhite;
	gap: 16px;
	padding: 12px;
	@include fontBody(xs);
	top: 0;
	z-index: 99999999999999;

	div {
		p {
			margin: 0;

			a {
				color: var(--page-color-light);
				text-decoration: underline;
				text-decoration-thickness: 2px;
			}
		}
	}

	button {
		display: inline-flex;
		border: 2px solid var(--page-color-light);
		border-radius: 999px;
		padding: 10px 12px;
		font-weight: 700;
		line-height: 1;
		background-color: $offblack;
		color: var(--page-color-light);

		&:hover {
			background-color: var(--page-color-light);
			color: $offblack;
		}
	}

	&.hidden {
		display: none;
	}
}
