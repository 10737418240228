*:focus-visible {
	outline: solid 2px;
	outline-offset: 2px;
	outline-color: inherit;
}
*::before,
*::after,
* {
	outline-offset: 2px;
	outline-width: 2px;
	outline-color: inherit;
}
body {
	outline-color: $offblack;
}
a:focus,
a:hover {
	color: unset;
}
@media (min-width: 1720px) {
	.container {
		width: 1792px;
		max-width: calc(100vw - 128px);
	}
}
.body-block{
	overflow: hidden;
}
.bg-blue,
.bg-dark {
	background-color: $offblack;
	color: $lightgreen;
	outline-color: $green;
}
