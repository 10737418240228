.embedArticle {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	border-radius: 12px;
	padding: 32px;
	gap: 36px;
	background-color: $lightgreen;
	flex-wrap: wrap;
	@media (max-width: 767px) {
		gap: 24px;
		padding: 16px;
	}
	@media (max-width: 480px) {
		flex-direction: column;
	}

	&__img {
		background-repeat: no-repeat;
		background-position: center center;
		width: calc(30.2% - 36px);
		aspect-ratio: 1/1;
		max-width: 240px;
		min-width: 100px;

		@media (max-width: 767px) {
			width: calc(30.2% - 24px);
		}
		@media (max-width: 480px) {
			width: auto;
		}
	}

	&__content{
		flex: 1;

		width: 69.8%;
		@media (max-width: 480px) {
			width: 100%;
		}

	}
	&__category {
		@include microfont("xs");
		width: fit-content;
		margin-bottom: 24px;
		padding: 2px 10px;
		border: 2px solid $offblack;
		border-radius: 14px;

		@media (max-width: 480px) {
			margin-bottom: 16px;

		}
	}

	&__title{
		color: $offblack;
		@include heading(h4);
		margin-bottom: 16px;
		.icon {
			vertical-align: middle;
			height: 26px;
			margin: 0;
			margin-left: 8px;
		}
	}

	&__intro {
		line-clamp: 4;

	}
	&__link {
		color: $old7D7D7D;
		@include microfont("sm");
		font-weight: normal;
		text-decoration: none !important;

		&:before {
			content: " ";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			border-radius: 12px;

		}

		&:focus {
			&:before{
				outline: auto;
			}

		}
	}
}
