.accordion {
	margin-block: 120px;

	@media (max-width: 991px) {
		margin-block: 60px;
	}

	$colors: (
		blue: #DEF6FA,
		green: $theme-green,
		purple: $theme-purple,
		yellow: $theme-yellow,
	);

	@each $color-name, $color-value in $colors {
		&.bg-theme-#{$color-name} {
			background-color: $color-value;
			padding-block: 120px;

			@media (max-width: 991px) {
				padding-block: 60px;
			}
		}
	}

	&--macro {
		margin-block: 32px;

		@media (max-width: 991px) {
			margin-block: 16px;
		}
	}

	&__wrapper {
		// Resetting margins
		margin: 0;

		* {
			margin: 0;
		}
	}

	&__title {
		margin-bottom: 80px;

		@media (max-width: 991px) {
			margin-bottom: 36px;
		}
	}

	&__group {
		&+& {
			margin-top: 80px;

			@media (max-width: 991px) {
				margin-top: 36px;
			}
		}

		h4 {
			margin-bottom: 36px;

			@media (max-width: 991px) {
				margin-bottom: 16px;
			}
		}
	}

	&__item {
		border-top: 2px solid $offblack;
		border-bottom: 2px solid $offblack;

		&+& {
			margin-top: -2px;
		}

		&[aria-expanded="false"] {
			.accordion-button {
				&::before {
					content: " ";
					display: block;
					flex-shrink: 0;
					width: 24px;
					height: 24px;
					background-image: url("../../images/icon/plus-24.svg");
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
				}
			}
		}

		&[aria-expanded="true"] {
			.accordion-button {
				font-weight: 600;

				&::before {
					content: " ";
					display: block;
					flex-shrink: 0;
					width: 24px;
					height: 24px;
					background-image: url("../../images/icon/minus-24.svg");
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
				}
			}
		}

		.accordion-button {
			display: flex;
			align-items: center;
			gap: 16px;
			padding-block: 22px;
			padding-right: 16px;
			cursor: pointer;
			user-select: none;
			@include fontBody(md);

			@media (max-width: 991px) {
				padding-block: 20px;
			}
		}

		.content-wrapper {
			height: 0px;
			overflow: hidden;
			transition: height 175ms ease-in-out;
		}

		.accordion-content {
			@extend .text-block-sm;
			padding-bottom: 40px;

			@media (max-width: 991px) {
				padding-bottom: 32px;
			}
		}
	}
}

body[class*="page-"] .accordion:not([class*="bg-theme-"]) {
	background-color: var(--page-color);
}

body[class="page-blue"] .accordion:not([class*="bg-theme-"]) {
	background-color: #DEF6FA;
}
