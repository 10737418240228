
.nav-search,
.nav-search_wrap {
	&.modal-full {
		background-color: $lightgreen;
	}

	&_wrap {
		margin-top: 140px;
		margin-bottom: 72px;

		@media (max-width: 767px) {
			margin-top: 24px;
		}
	}

	label,
	.searches-title {
		@include heading(h3);
		@include serifHeading;
		color: $offblack;
	}

	.search-form {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		column-gap: 12px;
		row-gap: 32px;

		label {
			width: 100%;
		}

		input.form-control {
			flex: 1;
			background-color: transparent;

			border: none;
			margin: 4px;
			box-shadow:  inset 0 0 0 2px #{$offblack};
			&:hover, &:focus-visible {
				outline: solid #0B1C08 2px;
				outline-offset: 4px;
			}
		}

		button {
			display: flex;
			align-items: center;
			gap: 12px;
			padding: 12px 32px;
			color: $lightgreen;
			@include fontBody(md);
			margin: 4px;
			&:focus-visible {
				outline: auto #0B1C08 2px;
				outline-offset: 4px;
			}


			span {
				display: inline-block;
			}

			img {
				display: inline-block;
				filter: brightness(0) saturate(100%) invert(92%) sepia(28%) saturate(128%) hue-rotate(33deg) brightness(97%) contrast(98%); //lightgreen
			}
			&:hover{
				color: $offblack;
				background-color: $green;
				img {
					filter: none;
				}
			}
		}
	}

	.searches {
		display: flex;
		flex-direction: column;
		gap: 32px;

		ul {
			display: flex;
			flex-wrap: wrap;
			row-gap: 16px;
			column-gap: 36px;
			padding: 0;
			margin: 0;
			list-style-type: none;

			@media (max-width: 767px) {
				row-gap: 12px;
				column-gap: 24px;
			}

			li {
				display: inline-block;

				a {
					@include fontBody(sm);
					color: $offblack;
					text-decoration-line: underline;
					text-decoration-thickness: 2px;
					@include link-hover-box;
				}
			}
		}
	}
}
