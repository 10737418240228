.vacancies {
	* {
		box-sizing: border-box !important;
	}

	.contentArea:not(.owl-carousel-job) {
		display: grid;
		gap: 20px;
		grid-template-columns: repeat(4, 1fr);
		padding-inline: 0;

		@media (max-width: 680px) {
			justify-content: center;
		}

		&.single {
			@media (min-width: 1200px) {
				grid-template-columns: repeat(1, 1fr);
				padding-inline: calc(75% / 2);
			}

			@media (max-width: 1199px) {
				grid-template-columns: repeat(1, 1fr);
				padding-inline: calc((75% - 64px) / 2);
			}
		}

		&.double {
			@media (min-width: 1200px) {
				padding-inline: 25%;
				grid-template-columns: repeat(2, 1fr);
			}
		}

		&.triple {
			@media (min-width: 1200px) {
				padding-inline: calc(25% / 2);
				grid-template-columns: repeat(3, 1fr);
			}
		}

		@media (max-width: 1199px) {
			grid-template-columns: repeat(2, 1fr);
			padding-inline: calc((50% - 64px) / 2);
		}

		@media (max-width: 991px) {
			padding-inline: calc((25% - 64px) / 2);
		}

		@media (max-width: 767px) {
			grid-template-columns: repeat(1, 1fr);
			padding-inline: calc((75% - 96px) / 2);
		}
	}

	&-wrap {
		text-align: center;

		h2 {
			color: $oldblue;
			margin-top: 0;
			margin-bottom: 80px;

			@media (max-width: 991px) {
				margin-bottom: 48px;
			}
		}
	}

	&-carousel {
		margin-bottom: 80px;


		@media (max-width: 991px) {
			margin-bottom: 48px;
		}
	}

	&-item {
		min-height: 300px;
		margin-bottom: 20px;
		border-radius: 12px;
		padding-right: 0px;
		padding-left: 0px;
		background-color: $lightgreen;
		word-wrap: break-word;
		position: relative;
		margin-block: auto;
		max-width: 358px;

		.vacancies:not(.vacancies-home) & {
			@media (max-width: 767px) {
				max-width: 50%;
			}
		}

		.contentArea:not(.owl-carousel-job) & {
			position: relative;
			margin: 0;
			width: 100%;
		}

		@media (hover: hover) {
			&:hover {
				.title {
					text-decoration-line: underline;
				}
			}
		}

		@media (max-width: 576px) {
			max-width: inherit;
		}

		@media (max-width: 767px) {
			max-width: 100%;
			float: left;
			width: 100%;
			min-width: 280px;
		}

		@media (min-width: 768px) and (max-width: 991.98px) {
			&:nth-child(2n+1) {
				clear: both;
			}
		}

		@media (min-width: 992px) and (max-width: 1199.98px) {
			&:nth-child(3n+1) {
				clear: both;
			}
		}

		@media (min-width: 1200px) {
			&:nth-child(4n+1) {
				clear: both;
			}
		}
	}

	&-card {
		color: $offblack;
		padding: 24px 14px 24px 14px;
		text-align: left;
		height: 100%;
		min-height: 290px;
		margin: 0 10px;

		.contentArea:not(.owl-carousel-job) & {
			padding-inline: 24px;
			border-radius: 12px;
			box-shadow: 2px 2px 4px 0px #0b1c0817;
			margin: 0;
			width: 100%;
		}

		&_wrap {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			justify-content: space-between;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			height: 100%;
			min-height: 300px;

			.contentArea:not(.owl-carousel-job) & {
				flex-wrap: nowrap;
			}

			.arrow-icon {
				display: flex;
				align-self: stretch;

				svg {
					display: block;
					margin-top: auto;
				}
			}
		}

		&_content {
			display: flex;
			flex-direction: column;
			gap: 32px;
			width: 100%;
			word-break: break-word;

			.content-image {
				width: 100%;
				max-height: 334px;
				aspect-ratio: 310/175;
				border-radius: 8px;
				background-color: white;
				overflow: hidden;
				padding: 48px 16px;
				flex-shrink: 0;

				@media (max-width: 991px) {
					padding: 32px 12px;
				}

				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: scale-down;
					object-position: center;
				}
			}

			.content-header {
				display: flex;
				flex-direction: column;
				gap: 12px;

				.employer {
					@include microfont(xs);
					margin: 0;
				}

				.title {
					font-family: $sans;
					color: $oldblue;
					font-size: 24px;
					line-height: 32px;
					font-weight: 600;

					-webkit-box-flex: 1;
					-ms-flex-positive: 1;
					flex-grow: 1;
					word-wrap: break-word;

					@media (max-width: 991px) {
						font-size: 20px;
						line-height: 28px;
					}

					@supports (-ms-ime-align: auto) {
						word-break: break-all;
						-webkit-hyphens: auto;
						-ms-hyphens: auto;
						hyphens: auto;
					}

					.internal-vacancies & {
						height: 100%;
						-webkit-hyphens: auto;
						-ms-hyphens: auto;
						hyphens: auto;

						@supports (-ms-ime-align: auto) {
							word-break: break-all;
						}
					}
				}
			}

			.content-info {
				display: flex;
				flex-direction: column;
				gap: 16px;

				.workplace,
				.deadline {
					display: flex;
					align-items: center;
					gap: 12px;
					margin: 0;
					@include fontBody(sm);

					img,
					svg {
						width: 24px;
						height: 24px;
					}
				}
			}
		}

		.linkWrap,
		&__link {
			border-radius: 12px;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;

			@media (hover: hover) {
				&:hover {
					border: 2px solid $offblack;
				}
			}
		}
	}

	.owl {
		&-carousel {
			position: relative;

			&-job {
				position: relative;

				@media (min-width: 1280px) {
					padding-inline: calc(35px + 52px);
				}
			}

			@media (max-width: 767px) {
				display: none;
			}
		}

		&-nav {
			background: transparent;
			cursor: pointer;

			*[class*=owl-] {
				background: transparent;
				cursor: pointer;
			}

			@media (min-width: 1280px) {
				position: absolute;
				top: calc(50% - (52px / 2));
				left: 0px;
				right: 0px;
			}

			.owl-prev {
				position: absolute;
				left: 0;
				margin: 0;
			}

			.owl-next {
				position: absolute;
				right: 0;
				margin: 0;
			}

			&.disabled {
				display: none;
			}
		}

		&-dots {
			display: none;

			// margin-top: 20px;

			// .owl-dot {
			// 	background-color: #d8ddd8;

			// 	span {
			// 		margin: 0;
			// 		-webkit-border-radius: 100px;
			// 		-moz-border-radius: 100px;
			// 		border-radius: 100px;
			// 		width: 36px;
			// 		height: 4px;
			// 		background-color: #d8ddd8;
			// 	}

			// 	&.active {
			// 		span {
			// 			background: $green;
			// 		}
			// 	}
			// }
		}

		&-stage {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			column-gap: 32px;

			@media (max-width: 1279px) {
				column-gap: 16px;
			}

			&-outer {
				overflow: hidden;
				padding: 4px 0;
			}
		}

		&-item {
			// background-color: white;
			border-radius: 12px;
			-webkit-border-radius: 12px;
			-moz-border-radius: 12px;
			// overflow: hidden;

			.item {
				height: 100%;
			}

			.contentArea:not(.owl-carousel-job) & {
				box-shadow: none;
			}
		}
	}
}

@media all and (-ms-high-contrast:none) {

	*::-ms-backdrop,
	.vacancies .vacancies-wrap .vacancies-carousel .vacancies-card .vacancies-card_wrap .vacancies-card_title {
		word-break: break-all;
	}

	/* IE11 */
}
