.newsletter {
	color: $lightgreen;
	padding: 120px 0;
	text-align: center;

	@media (max-width: 767px) {
		padding-top: 40px;
		padding-bottom: 48px;
	}
	.form-group {
		display: flex;
		column-gap: 20px;
		row-gap: 12px;
		margin-bottom: 0;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		@media (max-width: 767px) {
			justify-content: flex-start;
		}

		button {
			margin: 0;
		}

		input {
			flex: 1;
			min-height: 60px;

			@media (max-width: 767px) {
				padding: 12px 24px;
				min-width: 15rem;
				max-width: 100%;
			}
		}

		.button {
			font-size: 22px;

			@media (max-width: 767px) {
				font-size: 18px;
				min-height: 54px;
				border-radius: 30px;
			}
		}
	}

	&-wrap {
		display: flex;
		flex-direction: column;
		gap: 60px;

		@media (max-width: 767px) {
			gap: 36px;
		}

		h2 {
			margin-bottom: 34px;

			@media (max-width: 767px) {
				margin-bottom: 24px;
			}
		}
	}
}
