.program {
	--theme-color: #{$lightgreen};

	&--purple {
		--theme-color: #{$theme-purple};
	}

	&--blue {
		--theme-color: #{$theme-blue};
	}

	&--green {
		--theme-color: #{$lightgreen};
	}

	h2 {
		margin-bottom: 48px;
		margin-top: 0;
	}

	&__nav {
		display: flex;
		flex-wrap: wrap;
		gap: 12px 24px;

		label {
			display: inline-flex;
			align-items: center;
			column-gap: 10px;
			border-radius: 999px;
			border: 2px solid $offblack;
			padding: 13px 30px;
			@include fontBody(sm);

			input {
				position: absolute;
				width: 1px;
				height: 1px;
				z-index: 0;
				background-color: transparent;

				&:focus-visible {
					outline: none;
					border: none;
				}
			}

			&::before {
				content: "";
				position: relative;
				display: none;
				width: 23px;
				height: 17px;
				background-image: url("../../images/icon/check-mark.svg");
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
			}

			&:has(input[type="radio"]:checked) {
				background-color: $green;

				&::before {
					display: block;
				}
			}

			&:has(input[type="radio"]:focus-visible) {
				outline: solid currentColor 2px;
				outline-offset: 2px;
			}
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		gap: 16px;
		margin-top: 60px;
	}

	.program-item {
		@include fontBody(sm);
		font-weight: 600;
		background-color: transparent;
		padding: 32px 40px;
		border: 2px solid $offblack;
		border-color: $offblack;
		border-radius: 12px;

		@media (max-width: 991px) {
			padding: 24px;
		}

		&:has(.program-item__content) {
			padding: 0;
			background-color: var(--theme-color);
			border-color: var(--theme-color);

			.program-item__header {
				padding: 32px 40px;
				cursor: pointer;

				@media (max-width: 991px) {
					padding: 24px;
					gap: 16px;
					flex-direction: column;
				}

				.program-item__column:nth-child(2) {
					display: flex;
					flex-direction: column;
					gap: 8px;
				}

				.program-item__column:nth-child(3) {
					font-weight: 400;
				}

				.program-item__column:nth-child(4) {
					svg {
						transition: rotate 175ms ease-out;
					}

					@media (max-width: 991px) {
						position: absolute;
						top: 24px;
						right: 24px;
					}
				}

				.detail {
					font-weight: 400;
					line-height: 1.5;
				}

				.title {
					@include heading(h4);
				}
			}

		}

		&--guide {
			background-color: $offblack;
			color: $green;
			font-weight: 700;

			@media (max-width: 991px) {
				display: none;
			}
		}

		&:not(:has(.program-item__content)) {
			.program-item__header {
				@media (max-width: 991px) {
					gap: 12px;
				}
			}

			.program-item__column {
				&:nth-child(1) {
					@media (max-width: 991px) {
						width: 58px;
					}
				}

				&:nth-child(2) {
					@media (max-width: 991px) {
						flex-shrink: 0;
					}
				}
			}
		}

		&[aria-expanded="true"] {
			.program-item__column {
				svg {
					rotate: 180deg;
				}
			}
		}

		&[aria-expanded] {
			&:focus-visible {
				outline: 2px solid $offblack;
				outline-offset: 2px;
			}
		}

		&__header,
		&__content {
			position: relative;
			display: flex;
			align-items: flex-start;
			flex-wrap: nowrap;
			gap: 48px;
		}

		&__content {
			max-height: 0;
			overflow: hidden;
			transition: max-height 175ms ease-in-out;
		}

		&__column {
			flex-shrink: 0;

			@media (max-width: 991px) {
				width: 100%;
			}

			@media (max-width: 991px) {
				width: 100%;
			}

			&:first-child {
				width: 100px;
			}

			&:nth-child(2) {
				flex-grow: 1;
				flex-shrink: 1;
			}

			&:nth-child(3) {
				width: 230px;
			}

			&:nth-child(4) {
				width: 32px;
			}
		}

		&__content {
			.content {
				padding: 48px 40px;
				width: 100%;
				padding-top: calc(48px - 32px);

				@media (max-width: 1199px) {
					display: flex;
					flex-direction: column;

					p {
						&:first-of-type {
							order: 1;
						}
					}

					img {
						order: 2;
						margin-bottom: 20px;

						&:not(:first-child) {
							margin-top: 32px;
						}
					}
				}

				@media (max-width: 991px) {
					padding: 24px;
					padding-top: 0;
				}

				p,
				ul,
				ol {
					@include fontBody(md);
					margin-left: 148px;
					max-width: 498px;

					@media (max-width: 1439px) {
						max-width: 369px;
					}

					@media (max-width: 1199px) {
						order: 3;
					}

					@media (max-width: 991px) {
						max-width: 100%;
						width: 70%;
						margin-left: 0;
					}

					@media (max-width: 679px) {
						margin-inline: 0;
						width: 100%;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}

				img {
					margin-left: 48px;
					max-width: 313px;
					aspect-ratio: 1;
					float: right;

					@media (max-width: 1199px) {
						margin-left: 148px;
						float: none;
					}

					@media (max-width: 991px) {
						margin-left: 0;
					}
				}
			}
		}
	}
}

.presenters {
	--theme-color: #{$lightgreen};

	&--purple {
		--theme-color: #{$theme-purple};
	}

	&--blue {
		--theme-color: #{$theme-blue};
	}

	&--green {
		--theme-color: #{$lightgreen};
	}

	h2 {
		margin-block: 0 60px;

		@media (max-width: 767px) {
			margin-bottom: 48px;
		}
	}

	.presenter {
		position: relative;
		padding: 32px 40px;
		border-radius: 12px;
		background-color: var(--theme-color);

		@media (max-width: 767px) {
			padding: 24px;
		}

		&__header {
			position: relative;
			display: flex;
			align-items: center;
			gap: 60px;
			cursor: pointer;

			@media (max-width: 767px) {
				flex-direction: column;
				align-items: flex-start;
				gap: 24px;
			}

			figure {
				width: 187px;
				height: 187px;
				aspect-ratio: 1;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}

			.content {
				display: flex;
				flex-direction: column;
				gap: 32px;
				max-width: 550px;
				margin-right: calc(36px + 16px);

				.title-wrapper {
					display: flex;
					flex-direction: column;
					gap: 4px;

					.tag {
						@include fontBody(xs);
						font-weight: 500;
					}

					.title {
						@include heading(h4);
					}
				}

				.intro {
					@include fontBody(sm);
				}
			}

			&::after {
				content: "";
				position: absolute;
				inset: -32px -40px;
				cursor: pointer;

				@media (max-width: 767px) {
					inset: -24px;
				}
			}
		}

		&__content {
			margin-left: 187px;
			margin-right: calc(36px + 16px);
			padding-left: 60px;
			max-width: 550px;
			@include fontBody(sm);
			overflow: hidden;
			max-height: 0;
			transition: max-height 175ms ease-in-out;

			@media (max-width: 767px) {
				margin-inline: 0;
				padding-left: 0;
			}

			.content {
				padding-top: 24px;
			}
		}

		&__icon {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			top: calc(50% - 18px);
			right: 32px;
			width: 48px;
			height: 48px;
			cursor: pointer;
			transition: rotate 175ms ease-out;

			@media (max-width: 767px) {
				top: 24px;
				right: 24px;
			}
		}

		&[aria-expanded="true"] {
			.presenter__icon {
				rotate: 180deg;
			}
		}

		&+.presenter {
			margin-top: 24px;

			@media (max-width: 767px) {
				margin-top: 16px;
			}
		}
	}
}























.table__presenters {
	max-width: 806px;
	margin: 0 auto;
}

.table__presenters .nav.nav-tabs>.nav-item {
	width: 33.33%;
	border-left: 1px solid #DDD;
	border-right: 1px solid #DDD;
	border-top: 1px solid #DDD;
}

.table__presenters .nav.nav-tabs>.nav-item button {
	background: #f7f9f6;
	border-radius: 0;
	margin: 0;
	text-align: center;
	font-weight: 500;
}

.table__presenters .nav.nav-tabs>.nav-item button.active {
	background: #FFFFFF;
	border-bottom: 2px solid #88ac2e;
}

.table__presenters .nav.nav-tabs>.nav-item:nth-child(2) {
	border-left: 0;
	border-right: 0;
}

.table__presenters .nav.nav-tabs>.nav-item button {
	color: $offblack;
}

.table__presenters .table__container table thead tr th:nth-child(1),
.table__presenters .table__container table thead tr th:nth-child(2) {
	width: 10%;
}

.table__presenters .table__container table thead tr th:nth-child(3) {
	width: 45%;
}

/* .table__presenters .table__container table tbody tr td:nth-child(3){

} */
.table__presenters .table__container table tbody tr td:nth-child(4) {
	font-style: italic;
}

.table__presenters .table__container table thead tr th:nth-child(4) {
	width: 30%;
}

.table__presenters .table__container table thead tr th:nth-child(5) {
	width: 5%;
}

.table__presenters .table__container table tbody tr.line:first-child td {
	border-top: 10px solid #FFFFFF;
}

.table__presenters .table__container table tbody tr.line--content td {
	/* border-bottom: 1px solid #ccc; */
	background: #f7f9f6;
}

.table__presenters .table__container table tbody tr.line--content td .readmore .iconPlus:before {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.table__presenters .table__container table tbody tr.line--content td .readmore {
	/* float: right; */
	width: auto;
	position: absolute;
	right: 0;
	top: 0;
}

.table__presenters .table__container table tbody tr.line--content .content {
	padding: 20px;
	position: relative;
}

.table__presenters .table__container table tbody tr.line--content .content h3 {
	font-family: $sans;
}

.table__presenters .table__container table tbody tr.line--content .content p {
	margin-bottom: 30px;
	@include microfont("sm");
	// font-size: 14px;
	// line-height: 20px;
	color: $old7D7D7D;
}

.table__presenters .table__container table tbody tr.line--content .content .image {
	float: right;
}

.table__presenters .table__container table tbody tr.show-line {
	display: block !important;
}

.table__presenters .table__container table tbody tr.hide-line {
	display: none !important;
}

.table__presenters .table__container table tbody tr td {
	background: #f7f9f6;
	border-top: 5px solid #FFFFFF;
	border-bottom: 5px solid #FFFFFF;
	padding: 10px;
}

.table__presenters .table__container table tbody tr.line--content div {
	font-size: 18px;
	font-weight: 500;
	width: 100%;
}

.readmore .iconPlus {
	margin-bottom: 0;
}

.readmore .iconPlus:before {
	content: " ";
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url(../images/icon_plus.svg) center center no-repeat;
	background-size: contain;
	vertical-align: middle;
	margin-right: 5px;
}

/*  adjust  */
.table__presenters .nav.nav-tabs {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.table__presenters .nav.nav-tabs>.nav-item {
	width: auto;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.table__presenters .table__container table tbody tr:hover td {
	background: #d2e288;
}

.table__presenters .table__container table tbody tr.line--content:hover td {
	background: #f7f9f6;
}

.table__presenters .table__container table tbody tr.line--content .content .image {
	margin-left: 40px;
	max-width: 100%;
}

.table__presenters .table__container table tbody tr.line--content {
	display: none;
}

.table__presenters .table__container table tbody tr.line--content div p {
	text-align: left;
	font-size: 16px;
	font-weight: 400;
	color: $old323335;
}

.content-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.mobile-title {
	display: none;
}

.table__presenters .table__container table {
	display: none;
}

/*.table__presenters .table__container table[data-tab=0]{
    display: block !important;
} */

@media (max-width: 767px) {
	.table__presenters .table__container table tbody tr:hover {
		background: #d2e288;
	}

	.table__presenters .table__container table tbody tr,
	.table__presenters .table__container table tbody tr td,
	.table__presenters .table__container table tbody tr.line:first-child td {
		border-top: none;
		border-bottom: none;
		display: block;
	}

	.table__presenters .table__container table tbody tr {
		border-top: none;
		border-bottom: 10px solid #FFFFFF;
	}

	.table__presenters .table__container table tbody tr:nth-child(1) {
		border-top: 10px solid #FFFFFF;
	}

	.table__presenters .table__container table tbody tr.line:first-child td:nth-child(1),
	.table__presenters .table__container table tbody tr.line:first-child td:nth-child(2),
	.table__presenters .table__container table tbody tr td:nth-child(1),
	.table__presenters .table__container table tbody tr td:nth-child(2) {
		display: inline-block;
	}

	.table__presenters .table__container table tbody tr.line--content .content .image {
		margin: 0;
	}

	table tbody:first-child tr:first-child {
		width: auto;
	}

	.table__presenters .table__container table tbody tr.line--content td .readmore .iconPlus:before {
		margin-right: 5px;
	}

	.table__presenters .table__container table tbody tr.line--content td .readmore {
		position: absolute;
		/* right: 25px; */
		right: -30px;
	}

	.content-body {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.mobile-title {
		color: $offblack;
		font-size: 18px;
		background-color: #fff;
		padding: 10px;
		border-bottom: 1px solid #ccc;
		display: block;
		width: 100%;

	}

	.table__presenters .nav.nav-tabs>.nav-item button {
		text-align: left;
	}

	.table__presenters .table__container table tbody tr.line {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 10px 0;
	}

	.table__presenters .table__container table tbody tr.line td {
		padding: 0 10px;
	}

	.table__presenters .table__container table tbody tr.line td:nth-child(2) {
		-ms-flex-preferred-size: 70%;
		flex-basis: 70%;
	}

	.table__presenters .table__container table tbody tr.line td:nth-child(3) {
		-ms-flex-preferred-size: 90%;
		flex-basis: 90%;
	}

	.table__presenters .table__container table tbody tr.line td:nth-child(5) {
		position: absolute;
		right: 25px;
		padding: 0;
	}

	.table__presenters .table__container table tbody tr.line--content .content {
		padding: 0;
		width: calc(100% - 30px);
	}

	.table__presenters .table__container table tbody tr.line--content {
		/* display: block; */
	}

	.table__presenters .table__container table tbody tr.line--content .content .image {
		float: none;
		margin: 20px 0;
	}
}
