.bannerTop {
	background-color: var(--page-color);
	padding-bottom: 140px;
	padding-top: 0;
	position: relative;
	@media (min-width: 992px) {
		hyphens: initial;
  		word-break: break-word;
	}

	@media (max-width: 767px) {
		padding-bottom: 40px;
	}

	.row {
		display: flex;
		flex-wrap: wrap-reverse;
		align-items: center;
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 36px;
		padding: 20px 0;

		@media (max-width: 991px) {
			gap: 24px;
			padding: 0;
		}

		h1 {
			font-size: -to-rem(64);
			line-height: calc(78 / 64);
			margin: 0;

			@media (max-width: 767px) {
				font-size: -to-rem(40);
				line-height: calc(48 / 40);
			}

		}

		p {
			margin: 0;
			@include fontBody("intro");

			@media (max-width: 767px) {
				font-size: 22px;
			}
		}

		.button {
			font-size: 22px;

			@media (max-width: 767px) {
				font-size: 18px;
			}
		}
	}

	&__img {
		width: 100%;
		aspect-ratio: 644/475;
		overflow: hidden;

		@media (max-width: 991px) {
			aspect-ratio: 328/187;
		}

		@media (max-width: 991px) {
			margin-bottom: 32px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
}
