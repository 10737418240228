.anchor-menu {
	padding-block: 120px 48px;

	@media (max-width: 991px) {
		padding-block: 60px 24px;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.h4 {
		margin: 0;
	}

	&__list {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 8px;

		li {
			display: block;
			color: $offblack;
			@include fontBody(sm);

			a {
				display: block;
				padding: 8px 20px;
				border: 2px solid $offblack;
				border-radius: 8px;

				&:hover {
					background-color: $offblack;
					color: $lightgreen;
				}
			}

			* {
				color: inherit;
			}
		}
	}

	.icons-front:not(.bg-page)+& {
		margin-top: -120px;

		@media (max-width: 767px) {
			margin-top: -56px;
		}
	}

	& + .section[class*="bg-"] {
		margin-top: 0px;
	}
}
