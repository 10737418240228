.interview {
	&__top {
		margin-bottom: 80px;
		@media (max-width: 1199px) {
			margin-bottom: 56px;
		}
		@media (max-width: 992px) {
			margin-bottom: 40px;
		}
		.row {
			display: flex;
			flex-wrap: wrap;

			&:before,
			&:after {
				display: none;
			}
		}
		.col-flex {
			display: flex;
			flex-direction: column;
			@media (min-width: 992px) {
				margin-left: -8.34%;
				&:before, &:after {
					content: '';
					display: block;
					flex: 1;
				}
				&::before {
					min-height: 80px;
				}
			}
		}
	}

	&__image {
		margin: 0 auto;

		@media (max-width: 1199px) {
			margin-bottom: -8.4%;
		}

		@media (max-width: 991px) {
			margin-left: calc(50% - 50vw);
			margin-right:  calc(50% - 50vw);
			margin-bottom: -13%;
		}

		img {
			width: 100%;
			object-fit: cover;
		}
	}

	&__content {
		position: relative;
		margin-bottom: 120px;

		@media (max-width: 991px) {
			margin-bottom: 60px;
			margin-top: 0;
		}
	}

	&__infotext {
		@include microfont(xs);
		line-height: 1.4;
		text-transform: uppercase;
		margin-top: 60px;

		@media (max-width: 991px) {
			margin-top: 36px;
		}
	}
	&__category {
		@include fontBody(sm);
		line-height: 1.4;
		text-transform: uppercase;
		margin-bottom: 24px;

		@media (max-width: 991px) {
			margin-bottom: 16px;
		}
	}

	&__intro {
		background-color: #fff;
		text-align: center;
		padding: 48px 0 48px 64px;
		@media (max-width: 1199px) {
			padding: 48px;
			padding-bottom: 0;
			margin-bottom: 0px;
		}
		@media (max-width: 768px) {
			padding: 24px;
			padding-bottom: 0;
			margin-bottom: 0px;
		}

		>* {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		h1,
		.h1 {
			margin-top: 0;
		}

		p {
			@include fontBody(intro2);
			margin-top: 24px;
			margin-bottom: 0;

			@media (max-width: 991px) {
				margin-top: 16px;
			}
		}
	}

	&__section {
		+.interview__section {
			margin-top: 40px;

			@media (max-width: 991px) {
				margin-top: 24px;
			}
		}
	}

	// FIX for full container
	// .col-xs-12 {
	// 	.TextBlock {
	// 		margin: 40px calc(50% - 50vw);

	// 		.container .row>div .w-100 {
	// 			padding: 10px;
	// 		}
	// 	}
	// }

	// .col-md-8 .TextBlock {
	// 	@media (min-width: 992px) {
	// 		margin: 56px calc(-25% - 15px);

	// 		.container .row>div .w-100 {
	// 			padding: 0 16.66667%;
	// 		}
	// 	}
	// }

	// .col-lg-6 .TextBlock {
	// 	@media (min-width: 1200px) {
	// 		margin: 56px calc(-50% - 20px);

	// 		.container .row>div .w-100 {
	// 			padding: 0 25%;
	// 		}
	// 	}
	// }

	.col-negative,
	.embedArticle,
	.col-negative,
	table,
	.course-instructor,
	.embedArticle,
	.embedBook {
		width: auto;
		@media (min-width: 1200px) {
			margin-left: -15%;
			margin-right: -15%;
		}
		@media (min-width: 992px) {
			margin-left: -12.5%;
			margin-right: -12.5%;
		}
		@media (min-width: 768px) {
			margin-left: -10%;
			margin-right: -10%;
		}
	}
}

.article-single {
	&__image {
		margin: 0 auto;
		margin-bottom: -7.4%;

		@media (max-width: 991px) {
			margin-bottom: -13%;
		}

		img {
			width: 100%;
			@media (max-width: 776px) {
				aspect-ratio: 360/195;
				object-fit: cover;
			}
		}
	}

	&__content {
		position: relative;
		display: block;
		margin-bottom: 120px;
		@media (max-width: 991px) {
			margin-bottom: 56px;
		}
	}

	&__infotext {
		@include microfont(xs);
		line-height: 1.4;
		text-transform: uppercase;
		margin-top: 60px;

		@media (max-width: 991px) {
			margin-top: 36px;
		}
	}
	&__category {
		@include fontBody(sm);
		line-height: 1.4;
		text-transform: uppercase;
		margin-bottom: 24px;

		@media (max-width: 991px) {
			margin-bottom: 16px;
		}
	}

	&__intro {
		background: #fff;
		text-align: center;
		padding: 48px 64px;
		margin-bottom: 24px;
		@media (max-width: 768px) {
			padding: 24px;
			margin-bottom: 16px;
		}

		>* {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		h1,
		.h1 {
			margin-top: 0;
		}

		p {
			@include fontBody(intro2);
			margin-top: 24px;
			margin-bottom: 0;

			@media (max-width: 991px) {
				margin-top: 16px;
			}
		}
	}

	&__section {
		+.article-single__section {
			margin-top: 40px;

			@media (max-width: 991px) {
				margin-top: 24px;
			}
		}
		> .video {
			margin-bottom: 40px;
			@media (max-width: 991px){
				margin-bottom: 8px;
			}
		}
	}

	.col-negative,
	table,
	.course-instructor,
	.embedArticle,
	.embedBook  {
		width: auto;
		@media (min-width: 1200px) {
			margin-left: -15%;
			margin-right: -15%;
		}
		@media (min-width: 992px) {
			margin-left: -12.5%;
			margin-right: -12.5%;
		}
		@media (min-width: 768px) {
			margin-left: -10%;
			margin-right: -10%;
		}
	}
}

.articlePr {
	&__top {
		margin-bottom: 80px;
		@media (max-width: 1199px) {
			margin-bottom: 36px;
		}
		.row {
			display: flex;
			flex-wrap: wrap;

			&:before,
			&:after {
				display: none;
			}
		}
		.col-flex {
			display: flex;
			flex-direction: column;
			@media (min-width: 1200px) {
				margin-left: -8.33333%;
				&:before, &:after {
					content: '';
					display: block;
					flex: 1;
				}
				&::before {
					min-height: 80px;
				}
			}
		}
	}
	&__content {
		position: relative;
		margin-bottom: 120px;

		@media (max-width: 991px) {
			margin-bottom: 60px;
			margin-top: 0;
		}
	}
	&__infotext {
		@include microfont(xs);
		line-height: 1.4;
		text-transform: uppercase;
		margin-top: 60px;

		@media (max-width: 991px) {
			margin-top: 36px;
		}
	}
	&__category {
		@include fontBody(sm);
		line-height: 1.4;
		text-transform: uppercase;
		margin-bottom: 24px;

		@media (max-width: 991px) {
			margin-bottom: 16px;
		}
	}
	&__writer {
		@include microfont(sm);
		text-align: left;
		padding: 24px;
		border-top: 1px solid $offblack;
		border-bottom: 1px solid $offblack;
		display: flex;
		gap: 48px;
		align-items: center;
		margin-top: 80px;
		hyphens:initial;
		justify-content: space-between;
		@media (max-width: 1440px) {
			gap: 24px;
			padding: 24px 16px;
		}
		@media (max-width: 991px) {
			margin-top: 36px;
			hyphens: auto;
		}
		@media (max-width: 767px) {
			flex-direction: column-reverse;
			gap: 24px;
		}
		&__label {
			font-weight: 600;
			+ p {
				display: inline;
			}
		}
		&__text {
			max-width: 500px;
		}
		&__foto {
			margin-top: 16px;
		}
		&__img {
			aspect-ratio: 1/1;
			border-radius: 50%;
			height: 150px;
			object-fit: cover;
		}
	}

	&__intro {
		text-align: center;
		margin-top: 48px;
		@media (max-width: 991px) {
			margin-top: 0px;
		}
		>* {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		h1,
		.h1 {
			margin-top: 0;
		}

		p {
			@include fontBody(intro2);
			margin-top: 24px;
			margin-bottom: 0;
		}
	}

	&__section {
		+.articlePr__section {
			margin-top: 40px;

			@media (max-width: 991px) {
				margin-top: 24px;
			}
		}
	}

	// FIX for full container

	.col-negative,
	table,
	.course-instructor,
	.embedArticle,
	.embedBook {
		width: auto;
		@media (min-width: 1200px) {
			margin-left: -15%;
			margin-right: -15%;
		}
		@media (min-width: 992px) {
			margin-left: -12.5%;
			margin-right: -12.5%;
		}
		@media (min-width: 768px) {
			margin-left: -10%;
			margin-right: -10%;
		}
	}

}
