.card-event {
	min-height: 220px;
	@include fontBody(sm);
	line-height: -to-rem(24);
	display: flex;
	flex-direction: column;
	gap: 12px;
	position: relative;
	padding: 40px;
	border-radius: 12px;
	background-color: $lightgreen;
	overflow: hidden;
	hyphens: initial;
  	word-break: break-word;
	&:focus-within {
		outline-style: solid;
		outline-offset: -2px;
	}

	@media (hover: hover) {
		&:hover {
			box-shadow: inset 0 0 0 2px $offblack;

			.card-event__title {
				text-decoration-line: underline;
			}
		}

		&:focus-within {
			outline-offset: 2px;
		}
	}

	@media (max-width: 991px) {
		gap: 8px;
	}

	@media (max-width: 767px) {
		padding: 24px;
		min-height: 156px;
	}

	&__tag {
		position: absolute;
		font-weight: 600;
		line-height: 1.3;
		background-color: $offblack;
		color: $green;
		transform: rotate(45deg);
		width: 167px;
		height: 1.3em;
		margin-top: -1.3em;
		text-align: center;
		right: 0px;
		top: 116px;
		transform-origin: bottom right;

		@media (max-width: 991px) {
			font-weight: 500;
			right: 0px;
			top: 83px;
			line-height: 1.4;
			height: 1.5em;
			margin-top: -1.5em;
			width: 117px;

		}
	}
	&__date {
	}

	&__title {
		@include heading(h4);
		color: inherit;
	}

	&__place {
		margin-top: auto;
		&::before {
			content: '';
			display: inline-block;
			height: 16px;
			width: 12px;
			margin-right: 8px;
			background-image: url('../../images/icon/pin-16.svg');
			background-size: contain;
			background-repeat: no-repeat;

			@media (max-width: 991px) {
				width: 9px;
				height: 12px;
				margin-right: 4px;
			}
		}
	}

	&__link {
		color: inherit;
		margin-bottom: 36px;

		@media (max-width: 991px) {
			margin-bottom: 28px;
		}

		&:hover {
			color: inherit;
		}

		&::before {
			border-radius: 12px;
			content: '';
			position: absolute;
			left: -2px;
			top: -2px;
			bottom: -2px;
			right: -2px;
			z-index: 2;
		}

		&::after {
			content: " -->";
			white-space: nowrap;
		}

		&:focus-visible {
			outline-style: none;

			&:before {
				outline-style: solid;
			}
		}

		&:has(+.card-event__tag-group--mobile) {
			@media (max-width: 767px) {
				margin-bottom: 0;
			}
		}
	}

	&__tag-group {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 8px;
		margin-bottom: 12px;

		&:not(.card-event__tag-group--mobile) {
			&:not(:has(>.subject-tag)) {
				@media (max-width: 767px) {
					display: none;
				}
			}
		}

		.subject-tag {
			display: inline-flex;
			align-items: center;
			padding: 4px 12px;
			background-color: $offblack;
			color: $lightgreen;
			border-radius: 30px;
			@include microfont(xs);
		}

		.member-required-tag {
			display: inline-flex;
			@include microfont(xs);

			.tag-text {
				display: inline-flex;
				align-items: center;
				padding: 4px 8px;
				background-color: $green;
			}

			&::before {
				content: "";
				display: inline-flex;
				align-items: center;
				width: 27px;
				height: 27px;
				padding: 4px 8px;
				background-color: $offblack;
				color: $green;
				background-image: url("../../images/icon/plus-12-green.svg");
				background-size: 12px;
				background-position: center;
				background-repeat: no-repeat;
			}
		}

		&:not(.card-event__tag-group--mobile) {
			.member-required-tag {
				@media (max-width: 767px) {
					display: none;
				}
			}
		}

		&--mobile {
			@media (min-width: 768px) {
				display: none;
			}

			margin-block: calc(24px - 8px);
		}
	}

	@include add-theme-variations(background-color);
}
