.breadcrumbs {
	@include microfont(xs);
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
    flex-wrap: wrap;
    gap: 4px;
	align-items: center;

	li {
		display: flex;
		align-items: center;
		gap: 4px;
		@media (max-width: 991px) {
			&:not(:nth-last-child(2)) {
				display: none
			}

			&:nth-last-child(2) {
				&::after {
					order: -1;
					background-image: url("../../images/icon/arrow-right-16.svg");

				}
			}
		}
		&::after {
			content: "";
			width: -to-rem(16);
			height:  -to-rem(16);
			display: inline-block;
			background-image: url("../../images/icon/chevron-right-16.svg");
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
		}


		a {
			line-height: -to-rem(20);
			@include link-styled;
			text-decoration-thickness: 1px;
		}
	}
}
.breadcrumbs-section {
	margin-top: 24px;
	margin-bottom: 26px;
	@media (max-width: 991px) {
		margin-top: 16px;
		margin-bottom: 16px;
	}
}
