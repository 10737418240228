.agency-card {
	text-align: left;
	height: auto;
	.arrow-icon {
		img {
			width: 25px;
			height: 25px;
		}
		&.link-ext {
			img {
				transform: rotate(-45deg);
			}
		}
	}
	.readmore {
		margin-top: 16px;
		.button {
			@include button-secondary;
			display: flex;
			width: -to-rem(32);
			height: -to-rem(32);
			align-items: center;
			justify-content: center;
			padding: 0!important;
			&.closeContent {
				transform: rotate(-45deg);
			}
		}
		svg {
			width: -to-rem(16);
			height: -to-rem(16);
		}
	}
	&__wrap {
		background-color: $page-color;
		color: $offblack;
		position: relative;
		padding: 40px;
		border-radius: 12px;
		background-color: $lightgreen;
		overflow: hidden;
		hyphens: initial;
		word-break: break-word;
		height: 100%;
		display: flex;
		flex-direction: column;

		&:focus-within {
			outline-style: solid;
			outline-offset: -2px;
		}

		@media (hover: hover) {
			&:focus-within {
				outline-offset: 2px;
			}
		}

		@media (max-width: 991px) {
			gap: 8px;
		}

		@media (max-width: 767px) {
			padding: 24px;
			min-height: 156px;
		}
	}
	&__heading {
		display: flex;
		// flex-wrap: wrap;
		gap: 12px;
		justify-content: space-between;
		@media (max-width: 476px) {
			flex-direction: column-reverse;
		}
		h3,.h3 {
			@include heading(h4);
			color: inherit;
			margin: auto 0;
			a {
				text-decoration-line: none;
				@media (hover:hover) {
					&:hover {
						text-decoration-line: underline;
					}
				}
			}
		}
	}
	&__title {
		width: 50%;
		flex: 1;
		display: flex;
		flex-direction: column;
		height: 100%;
		@media (max-width: 476px) {
			width: 100%;
			min-height: unset;
		}
	}
	&__tag {
		@include microfont(xs);
		margin-bottom: 8px;
	}
	&__info {
		margin-top: 8px;
		@include microfont("xs");
		@media (max-width: 790px) {
			display: none;
		}
	}
	&__link {
		@include microfont(sm);
		margin-top: 8px;
		&::before {
			content: '';
			position: absolute;
			inset: 0;
			border-radius: 12px;
		}
		@media (hover:hover) {
			&:hover {
				text-decoration-line: underline;
				&::before {
					border: 2px solid $offblack;
				}
			}
		}
	}
	&__linelink {
		@include microfont('xs');
		margin-top: 8px;
		width: fit-content;
		img {
			transform: rotate(-45deg);
		}
		@media (hover:hover) {
			&:hover {
				text-decoration-line: underline;
			}
			&::before {
				border: 2px solid $offblack;
			}
		}

	}
	&__img {
		width: 50%;
		height: 100px;
		border-radius: 12px;
		padding: 8px 16px;
		background-color: white;
		overflow: hidden;
		padding: 8px;
		@media (max-width: 476px) {
			width: 100%;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
	&__moreContent {
		@include microfont('sm');
		display: none;
		margin-top: 16px;
		&.expanded{
			+ .readmore {
			   display: none!important;
		   	}

		}
	}

	&-carousel {
		margin-block: 80px;
		padding: 0 6.6%;
		position: relative;
		@media (max-width: 991px) {
			margin-block: 48px;
			padding: 0;
		}
		@media (max-width: 767px) {
			overflow: auto;
		}
		.swiper-wrapper,
		.swiper {
			position: static;
		}
	}
}
.agency-card-group {
	display: flex;
	flex-wrap: wrap;
	margin-block: 80px;
	gap: 32px;
	@media (max-width: 991px) {
		gap: 16px;
			margin-block: 48px;
	}

	.agency-card {
		height: auto;
		width: calc(50% - 16px);
		@media (max-width: 767px){
			width: 100%;
		}
	}

}
.agency-default {
	text-align: center;
}
.byralistenFrontPageMobile {
	display: flex;
	flex-direction: column;
	gap: 16px;
	@media (min-width: 791px) {
		display: none !important;
	}
}
