.footer {
	.main-footer {
		@include fontBody(sm);
		background-color: $lightgreen;
		padding-top: 100px;
		padding-bottom: 110px;

		@media(max-width: 767px) {
			padding: 40px 0;
			font-size: -to-rem(16);
		}
	}

	a {
		@include link-styled();
	}

	ul,
	ol {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.row {
		display: flex;
		flex-wrap: wrap;
	}

	.col-sm-toend {
		@media(max-width: 767px) {
			order: 5;
		}
	}

	&_title {
		font-weight: 600;
		margin-bottom: 16px;

		@media(max-width: 767px) {
			display: none;
		}
	}

	&_logo {
		margin-bottom: 54px;
		width: 100%;

		@media(max-width: 767px) {
			margin-bottom: 32px;
		}

		img {
			width: 353px;
			height: 70px;

			@media (max-width: 991px) {
				width: 250px;
				height: 50px;
			}
		}

		a:hover {
			background-color: transparent;
		}
	}

	&_contacts {
		line-height: calc(150% + 8px);

		p {
			line-height: inherit;
		}

		@media(max-width: 1199px) {
			margin-bottom: 40px;
		}
	}

	&_social {
		a {
			line-height: calc(140% + 8px);
			// text-decoration: underline;
			// text-decoration-line: underline;
			// text-decoration-thickness: 2px;
			// text-underline-offset: 4px;
		}

		@media(max-width: 1199px) {
			margin-bottom: 40px;
		}

		ul+ul {
			margin-top: 36px;

			@media(max-width: 767px) {
				margin-top: 24px;
			}
		}

	}

	&_links {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		max-height: 100%;
		text-decoration: underline;
		text-decoration-line: underline;
		text-decoration-thickness: 2px;
		text-underline-offset: 4px;

		@media(max-width: 1199px) {
			margin-bottom: 40px;
		}

		li {
			a {
				line-height: calc(140% + 8px);
				text-underline-offset: 4px;
			}
		}
	}

	&_credits {
		p {
			line-height: calc(140% + 8px);
		}
	}
}
