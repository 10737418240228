//Base application
@import "./base/colors";
@import "./base/mixings";
@import "./base/typography";

@import "./base/theme";

// legacy
@import "./legacy/app.scss";
@import "./legacy/main.scss";
@import "./legacy/glyphicon.scss";
@import "./legacy/article.scss";


//Base
@import "./base/global";
@import "./base/grid";

// Components

@import "./components/button.scss";
@import "./components/vacancies.scss";
@import "./components/text-block.scss";
@import "./components/banner-block.scss";
@import "./components/banner-cta.scss";
@import "./components/cards.scss";
@import "./components/blog.scss";
@import "./components/card-event.scss";
@import "./components/section.scss";
@import "./components/breadcrumbs.scss";
@import "./components/forms";
@import "./components/factbox";
@import "./components/forms";
@import "./components/circle-charts";
@import "./components/embebArticle";
@import "./components/embedBook";
@import "./components/video";
@import "./components/table";
@import "./components/agency-card";
@import "./components/swiper";
@import "./components/card-green";
@import "./components/card-clean";
@import "./components/program";

//parts

@import "./parts/bannerTop.scss";
@import "./parts/icons-front.scss";
@import "./parts/courses-and-arrangements.scss";
@import "./parts/hero-corporate.scss";
@import "./parts/article.scss";
@import "./parts/accordion.scss";
@import "./parts/fagstoff.scss";
@import "./parts/footer.scss";
@import "./parts/header.scss";
@import "./parts/nav-search.scss";
@import "./parts/newsletter.scss";
@import "./parts/footer.scss";
@import "./parts/modal.scss";
@import "./parts/course-instructor.scss";
@import "./parts/prices-and-registration.scss";
@import "./parts/subject-list.scss";
@import "./parts/membership-info.scss";
@import "./parts/list-menu-links.scss"; // Legacy
@import "./parts/anchor-menu.scss";
@import "./parts/textpart.scss";
@import "./parts/sallary-calc.scss";
@import "./parts/list-anything.scss";
@import "./parts/person-cards.scss";
@import "./parts/related-books.scss";
@import "./parts/podcast-list";
@import "./parts/video-cards";

// Layouts
@import "./layouts/login.scss";
@import "./layouts/search.scss";
@import "./layouts/event.scss";

//utilities
@import "./base/utilities";
@import  "../cookieBot/force-dialog";
