.subject-list {
	padding-block: 120px;
	background-color: var(--page-color);

	@media (max-width: 991px) {
		padding-block: 60px;
	}

	h2 {
		margin: 0;
	}

	&__content {
		display: flex;
		gap: 80px;
		padding-top: 48px;

		@media (max-width: 991px) {
			gap: 48px;
			padding-top: 36px;
		}

		@media (max-width: 767px) {
			flex-direction: column;
			;
			gap: 0;
			padding-top: 48px;

			ul+ul {
				margin-top: -2px;
			}
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			width: calc((100% - 80px) / 2);
			color: $offblack;

			@media (max-width: 991px) {
				width: calc((100% - 48px) / 2);
			}

			@media (max-width: 767px) {
				width: 100%;
			}

			* {
				color: inherit;
			}

			li {
				display: flex;
				position: relative;
				align-items: center;
				gap: 16px;
				padding: 12px 16px;
				border-block: 2px solid $offblack;
				@include fontBody(sm);

				@media (hover: hover) {
					&:hover {
						background-color: $offblack;
						color: $lightgreen;

						&:before {
							filter: brightness(0) saturate(100%) invert(92%) sepia(28%) saturate(128%) hue-rotate(33deg) brightness(97%) contrast(98%); //lightgreen
						}
					}
				}

				a {
					&::before {
						content: "";
						position: absolute;
						inset: 0;
					}
				}

				&::before {
					display: block;
					content: "";
					width: 24px;
					height: 24px;
					flex-shrink: 0;
					background-image: url("../../images/icon/arrow-right-24.svg");
					background-size: 100%;
					background-position: center;
					background-repeat: no-repeat;

					.subject-list--categories & {
						rotate: 90deg;
					}
				}
			}

			.subject-list:not(.subject-list--categories) & {
				li+li {
					border-top: none;
				}
			}
		}

		.subject-list--categories & {
			ul {
				display: flex;
				flex-wrap: wrap;
				column-gap: 80px;
				width: 100%;

				@media (max-width: 767px) {
					column-gap: 40px;
				}

				li {
					width: calc((100% - 80px)/2);
					margin-block: -1px;

					@media (max-width: 767px) {
						width: calc((100% - 40px)/2);
					}

					@media (max-width: 574px) {
						width: 100%;
					}

					&:first-child {
						margin-top: 0;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.hero-corporate {
	.subject-list {
		padding-block: 0;

		&__content {
			padding-top: 80px;

			@media (max-width: 991px) {
				padding-top: 48px;
			}
		}
	}
}
