.banner-cta {
	&__wrapper {
		display: flex;
		flex-direction: column;
		border-radius: 12px;
		overflow: hidden;

		@media (min-width: 769px) {
			flex-direction: row-reverse;
		}

		$colors: (
			purple: $theme-purple,
			green: $theme-green,
			blue: $theme-blue,
		);

		@each $color-name, $color-value in $colors {
			&.theme-#{$color-name} {
				background-color: #{$color-value};
			}
		}

		.banner-cta--reverse & {
			@media (min-width: 769px) {
				flex-direction: row;
			}
		}
	}

	&__image {
		position: relative;
		width: 50%;
		aspect-ratio: 757/560;

		@media (max-width: 767px) {
			width: 100%;
			aspect-ratio: 328/242;
		}

		div {
			position: absolute;
			inset: 0;
		}
	}

	&__content {
		width: 50%;
		padding: 64px 56px;
		position: relative;
		display: flex;
		flex-direction: column;

		* {
			margin: 0;
		}

		@media (max-width: 991px) {
			padding: 24px;
			padding-bottom: 32px;
		}

		@media (max-width: 767px) {
			width: 100%;
		}

		h2 {
			margin-bottom: 32px;
			flex-grow: 0;
			flex-shrink: 0;

			@media (max-width: 767px) {
				margin-bottom: 24px;
			}
		}

		p {
			flex-grow: 0;
			flex-shrink: 0;
			@include fontBody(md);

			& + p {
				margin-top: 36px;

				@media (max-width: 767px) {
					margin-top: 28px;
				}
			}

			&:last-child {
				flex-grow: 1;
				margin-top: 40px;
				display: inline-flex;
				align-items: flex-end;
			}
		}

	}
}
