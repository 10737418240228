.prices-and-registration {
	margin-block: 120px;

	@media (max-width: 991px) {
		margin-block: 60px;
	}

	h2 {
		margin-block: 0 60px;
		text-align: center;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	&__item {
		display: flex;
		align-items: flex-start;
		gap: 60px;
		background-color: $lightgreen;
		border-radius: 12px;
		padding: 48px;
		justify-content: space-between;

		@media (max-width: 1119px) {
			gap: 60px;
		}

		@media (max-width: 767px) {
			flex-direction: column;
			gap: 24px;
			padding: 32px 24px;
		}

		.item-info {
			display: flex;
			flex-direction: column;
			gap: 16px;
			min-width: 173px;
			flex-shrink: 0;

			@media (max-width: 767px) {
				gap: 4px
			}

			.item-label {
				@include heading(h4);
			}

			.item-price {
				@include heading(h2);
				@include serifHeading;
			}
		}

		.item-body {
			display: flex;
			flex-direction: column;
			gap: 36px;
			max-width: 397px;
			flex: 1;

			@media (max-width: 991px) {
				gap: 24px;
			}

			.text-block-sm {
				ul {
					margin-block: calc(20px * 1.5);
				}
			}
		}
	}

	&__cta {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
}
