.card-clean {
	@include fontBody('sm');
	position: relative;
	width: 100%;
	z-index: 0;
	max-width: 700px;
	margin-inline: auto;

	&:hover {
		.card-clean_link {

			~.card-clean_content .card-clean_title,
			~.card-clean_title {
				text-decoration-line: underline;
			}
		}
	}

	&_content {
		>*:last-child {
			margin-bottom: 0;
		}
	}

	&_img {
		width: 100%;
		aspect-ratio: 422/244;
		margin-bottom: 28px;
		position: relative;
		background-color: $offwhite;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}

		.related-books & {
			padding: 2.2% 0 1.1%;

			img {
				object-fit: contain
			}
		}

		.tag-sm-light {
			position: absolute;
			left: 17px;
			top: 20px;
			max-width: calc(100% - 17px);

		}
	}

	&_pretitle {
		// @include microfont("sm");
		z-index: 1;
		color: inherit;
		margin-bottom: 12px;
	}

	&_title {
		@include heading(h4);
		font-weight: 600;
		display: block;
		align-items: baseline;
		color: inherit;
		margin-bottom: 16px;

		.icon {
			left: 0;
			transition: left 175ms;
			margin-left: 12px;
			margin-bottom: 0;
			margin-left: 8px;
		}
	}

	&_info {
		@include microfont("sm");
		margin-bottom: 12px;
	}

	&_text {
		@include fontBody('sm');
		margin-bottom: 12px;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;

		>*:last-child {
			margin-bottom: 0;
		}
	}

	&_link {
		&::before {
			content: '';
			position: absolute;
			inset: 0;
			z-index: 1;
		}
	}

	*[class*="bg-dark"] & {
		color: $offwhite;
	}

	&--video {
		display: flex;
		flex-direction: column;
		row-gap: 20px;

		.card-clean_img {
			aspect-ratio: 16/9;
			overflow: hidden;
			margin-bottom: 0;

			img {
				display: block;
				width: 100%;
				height: 100%;
			}

			&:after {
				content: " ";
				position: absolute;
				left: 50%;
				top: 50%;
				z-index: 3;
				transform: translate(-50%, -50%);
				-webkit-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				width: 60px;
				height: 60px;
				background: url(../../images/icon/video-play.svg) center center no-repeat;
				background-size: -to-rem(60);
				vertical-align: middle;
				cursor: pointer;
			}
		}

		iframe {
			border: none;
		}

		.card-clean_link {
			position: absolute;
			inset: 0;
			z-index: 9999;
		}
	}

	&-group {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		row-gap: 32px;

		@media (max-width: 991px) {
			row-gap: 24px;
		}

		&>* {
			float: none;
		}
	}

	.tag-sm-light {
		display: inline-flex;
		position: absolute;
		top: 20px;
		left: 17px;
		z-index: 999;
	}
}

.tag-for-medlem {
	@include microfont(xs);
	font-weight: 500;
	display: block;
	background-color: $green;
	color: $offblack;
	padding: 4px 8px 4px 35px;
	width: fit-content;
	position: absolute;
	bottom: 0;
	right: 0;

	&::before {
		font-size: -to-rem(25);
		line-height: -to-rem(24);
		color: $green;
		background-color: $offblack;
		content: '+';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: -to-rem(27);
		text-align: center;
	}
}

.tag-sm-light {
	@include microfont('sm');
	padding: 4px 12px;
	background-color: $lightgreen;
	color: $offblack;
	border-radius: 20px;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	overflow: hidden;
}
