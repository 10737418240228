// grey text block
.textpart {

	&__single {
		&:not(:last-child) {
			margin-bottom: 60px;
			@media (max-width: 991px) {
				margin-bottom: 30px;
			}
		}
		.col-negative,
		table,
		.embedArticle,
		.embedBook {
			@media (min-width: 1200px) {
				margin-left: -15%;
				margin-right: -15%;
				width: calc(100% + 30%);
			}
			@media (min-width: 992px) {
				margin-left: -12.5%;
				margin-right: -12.5%;
				width: calc(100% + 25%);

			}
			@media (min-width: 768px) {
				margin-left: -10%;
				margin-right: -10%;
				width: calc(100% + 20%);
			}
		}
	}
	&__group {
		row-gap: 24px;
		&:not(:last-child) {
			margin-bottom: 60px;
			@media (max-width: 991px) {
				margin-bottom: 30px;
			}
		}
		// display: flex;
	}
	&__col {
	}
	&__box {
		color: $offblack;
		padding: 40px;
		border-radius: 12px;
		background-color: $green;
		min-height: 100%;
		@media (max-width: 991px) {
			padding: 32px -24px;
		}
	}
}
