@mixin button {
	font-family: $sans;
	font-weight: 400;
	display: inline-block;
	@include fontBody(sm);
	line-height: 1.5;
	padding: 8px 30px;
	gap: 10px;
	border-radius: 30px;
	border: 2px solid transparent;
	background-color: transparent;
	width: fit-content;
	text-align: center;
	color: inherit;

	@media (max-width: 767px) {
		font-size: 18px;
		padding: 8px 20px;
		gap: 10px;
		// border-radius: 23px;
		min-width: 46px;
		min-height: 46px;
	}
	&:focus-visible {
		outline: solid currentColor 2px;
		outline-offset: 2px;
	}
	.icon {
		margin: 0;
	}
}
@mixin button-link {
	color: inherit;
	padding: 0;
	bottom: 0;
	border-radius: 0;
	border-color: transparent;
	background-color: transparent;
	display: flex;
	align-items: center;
	gap: 10px;
	height: fit-content;

	@media (max-width: 991px) {
		gap: 8px;
	}
	&:hover, &:focus {
		color: inherit;
	}
	.icon {
		margin: 0;
	}
}

@mixin button-primary {
	@include button();
	color: $green;
	background-color: $offblack;
	border-color: $offblack;
	&:focus {
		color: $green;
	}
	@media (hover: hover) {
		&:hover {
			color: $offblack;
			background-color: transparent;
		}
	}
	&:focus-visible {
		outline-color: $offblack;
	}
}

@mixin button-primary-dark  {
	@include button();
	color: $offblack;
	background-color: $green;
	border-color: $green;

	@media (hover: hover) {
		&:hover {
			color: $lightgreen;
			background-color: transparent;
			.icon{
				filter: brightness(0) saturate(100%) invert(92%) sepia(28%) saturate(128%) hue-rotate(33deg) brightness(97%) contrast(98%); //lightgreen
			}
		}
	}
	&:focus-visible {
		outline-color: $green;
	}
}
@mixin button-secondary {
	@include button();
	color: $offblack;
	background-color: transparent;
	border-color: $offblack;

	@media (hover: hover) {
		&:hover {
			color: $lightgreen;
			background-color: $offblack;
			.icon{
				filter: brightness(0) saturate(100%) invert(92%) sepia(28%) saturate(128%) hue-rotate(33deg) brightness(97%) contrast(98%); //lightgreen
			}
		}
	}
	&:focus-visible {
		outline-color: $offblack;
	}
}
@mixin button-secondary-dark {
	@include button();
	color: $lightgreen;
	background-color: transparent;
	border-color: $lightgreen;

	@media (hover: hover) {
		&:hover {
			color: $offblack;
			background-color: $lightgreen;
		}
	}
	&:focus-visible {
		outline-color: $offblack;
	}
}

@mixin link-styled {
	color: inherit;
	font-weight: 400;
	text-decoration-line: underline;
	text-decoration-thickness: 2px;
	text-decoration-skip-ink: none;
	text-underline-position: under;
	text-underline-offset: 1px;
	outline-offset: 1px;
	@media (hover: hover) {
		&:hover {
			background-color: $offblack;
			color: $lightgreen;
			.icon{
				filter: brightness(0) saturate(100%) invert(92%) sepia(28%) saturate(128%) hue-rotate(33deg) brightness(97%) contrast(98%); //lightgreen
			}
		}
	}
}

@mixin link-hover-box {
	// text-underline-offset: 4px;
	text-underline-position: under;
	&:hover {
		background-color: $offblack;
		color: $lightgreen;
	}
	&:focus-visible {
		outline: solid currentColor 2px;
		outline-offset: 1px;
	}
}

.button {
	@include button();

	&-primary {
		@include button-primary();
	}
	&-secondary {
		@include button-secondary();
	}

	&-icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: 52px;
		min-height: 52px;
		padding: 0;
		height: fit-content;
		img {
			max-height: 100%;
			object-fit: contain

		}
	}
	&-link {
		@include button-link();
	}

	&-green {
		@include button-primary();
	}

	&-outline-white,
	&-outline-gray,
	&-outline-green,
	&-outline-blue {
		@include button-secondary();
	}


	&.btnFull {
		width: 100%;
		min-width: 90px;
	}
}


// .bg-blue {
// 	.button.button-outline-white,
// 	.button.button-secondary,
// 	.button.button-outline-green,
// 	.button.button-outline-blue,
// 	.button-secondary {
// 		color: inherit;
// 	}
// }

// .owl-theme .owl-nav .owl-next,
// .owl-theme .owl-nav .owl-prev {
// 	@include button-secondary();
// }
.bg-blue,
.bg-dark {
	background-color: $offblack;
	color: $lightgreen;
	outline-color: $lightgreen;
	.button-primary {
		@include button-primary-dark();
	}
	.button-secondary {
		@include button-secondary-dark();
	}
}
