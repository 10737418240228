.factbox {
	position: relative;
	margin: 80px auto;
	// max-width: 750px;
	padding: 40px;
	background-color: $lightgreen;
	border-radius: 12px;

	box-sizing: border-box;

	@media (max-width: 767px) {
		padding: 16px;
		padding-bottom: 32px;
		margin: 40px auto;
	}

	&[aria-expanded=true] {
		.factbox__button--open {
			display: none;
		}
	}

	&[aria-expanded=false] {
		.factbox__button--close {
			display: none;
		}

		.factbox__content {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}

	.h4 {
		margin-top: 0;
		margin-bottom: 24px;
	}

	&--yellow {
        background-color: $lightyellow;
    }

	&__button {
		@include button-secondary();
		@include fontBody('sm');
		padding: 6px 22px;
		background-color: inherit;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%);
		margin: 0 !important;

		.factbox--yellow & {
			&:hover {
				background-color: $theme-yellow;
				filter: saturate(2);
			}
		}
	}

	&__content {
		overflow: hidden;
		height: 2.8em;
		transition: .2s;
		@include htmlarea;
		@include fontBody(sm);
		--ha-spacing-xl: 36px;
		--ha-spacing-lg: 40px --ha-spacing-md: 28px;
		--ha-spacing-sm: 24px;
		--ha-spacing-xs: 16px;

		@media (max-width: 991px) {
			--ha-spacing-xl: 40px;
			--ha-spacing-lg: 32px --ha-spacing-md: 30px;
			--ha-spacing-sm: 12px;
			--ha-spacing-xs: 8px;
		}

		p {
			@media (min-width: 992px) {
				margin-bottom: 24px;
			}
		}
	}
}
