.listAnyThing {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-left: -12px;
	margin-right: -12px;
	margin-bottom: 30px;
}

.listAnyThing .itemBox {
	padding: 12px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 25%;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	/* Blog Card item */
	/* Subject Material Card */
}

@media (max-width: 767px) {
	.listAnyThing .itemBox {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

.listAnyThing .itemBox .subject-material_card__wrap {
	position: relative;
	overflow: hidden;
	border-radius: 3px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 20px;
}

.listAnyThing .itemBox .subject-material_card__wrap:hover {
	background: black !important;
}

.listAnyThing .itemBox .subject-material_card__wrap .subject-material_card__content {
	text-align: left;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	position: relative;
	justify-content: flex-end;
}

.listAnyThing .itemBox .subject-material_card__wrap .subject-material_card__content:hover h3 a {
	color: #dce59c;
}

.listAnyThing .itemBox .subject-material_card__wrap .subject-material_card__content .theme-tag {
	@include microfont("sm");
	// font-size: 0.75rem;



	color: #D2E288;
	margin-bottom: 11px;
}

.listAnyThing .itemBox .subject-material_card__wrap .subject-material_card__content h3 {
	margin-top: 0;
	margin-bottom: 20px;
}

.listAnyThing .itemBox .subject-material_card__wrap .subject-material_card__content h3 a {
	font-size: 1.25rem;
	font-weight: 700;
	line-height: 24px;
	color: white;
}

.listAnyThing .itemBox .subject-material_card__wrap .subject-material_card__content .read-more {
	@include microfont("sm");
	// font-size: 0.75rem;
	font-weight: 400;



	color: white;
}

.listAnyThing .itemBox .subject-material_card__wrap .subject-material_card__content .read-more:before {
	content: " ";
	background: url("../../images/icon_line.svg") center center no-repeat;
	background-size: contain;
	display: inline-block;
	width: 30px;
	height: 20px;
	margin-right: 10px;
	position: relative;
	vertical-align: middle;
}

.listAnyThing .itemBox .subject-material_card__wrap .subject-material_card__content .read-more:hover:before {
	background: url("../../images/icon_arrow_right_active.svg") center center no-repeat;
	width: 35px;
}

.listAnyThing .itemBox .subject-material_card__wrap.big-card {
	min-height: 620px;
}

@media (min-width: 992px) and (max-width: 1199px) {
	.listAnyThing .itemBox .subject-material_card__wrap.big-card {
		min-height: 460px;
	}
}

@media (max-width: 991px) {
	.listAnyThing .itemBox .subject-material_card__wrap.big-card {
		min-height: 350px;
	}
}

@media (max-width: 767px) {
	.listAnyThing .itemBox .subject-material_card__wrap.big-card {
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 0;
		background: none !important;
		margin-bottom: 30px;
	}

	.listAnyThing .itemBox .subject-material_card__wrap.big-card:hover {
		background: transparent !important;
	}
}

.listAnyThing .itemBox .subject-material_card__wrap.big-card.cardVideo:after {
	content: " ";
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 3;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	width: 64px;
	height: 64px;
	background: url("../../images/icon/video-play.svg") center center no-repeat;
	vertical-align: middle;
	cursor: pointer;
}

@media (max-width: 767px) {
	.listAnyThing .itemBox .subject-material_card__wrap.big-card .subject-material_card__content .logged-in {
		top: 20px;
		left: 20px;
	}

	.listAnyThing .itemBox .subject-material_card__wrap.big-card .subject-material_card__content .theme-tag {
		color: $oldgray;
	}

	.listAnyThing .itemBox .subject-material_card__wrap.big-card .subject-material_card__content h3 a {
		color: $oldblue;
	}

	.listAnyThing .itemBox .subject-material_card__wrap.big-card .subject-material_card__content .read-more {
		color: $oldgray;
	}
}

.listAnyThing .itemBox .subject-material_card__wrap.big-card .subject-material_card__content .subject-material_card__image {
	margin-bottom: 20px;
	position: relative;
}

.listAnyThing .itemBox .subject-material_card__wrap.big-card .subject-material_card__content .subject-material_card__image:hover:before {
	background: rgba(66, 65, 75, 0.35) !important;
	mix-blend-mode: multiply;
	content: "" !important;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
}

.listAnyThing .itemBox .subject-material_card__wrap.big-card .subject-material_card__content .subject-material_card__image img {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	width: 100%;
}

.listAnyThing .itemBox .subject-material_card__wrap.small-card {
	background-color: white;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	padding: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	border-bottom: 3px solid transparent;
}

.listAnyThing .itemBox .subject-material_card__wrap.small-card:hover {
	border-bottom: 3px solid $green;
	background: white !important;
	-webkit-border-radius: 3px 3px 0 0;
	-moz-border-radius: 3px 3px 0 0;
	border-radius: 3px 3px 0 0;
}

.listAnyThing .itemBox .subject-material_card__wrap.small-card .card-image,
.listAnyThing .itemBox .subject-material_card__wrap.small-card .card-video {
	position: relative;
}

.listAnyThing .itemBox .subject-material_card__wrap.small-card .card-image a:hover:before,
.listAnyThing .itemBox .subject-material_card__wrap.small-card .card-video a:hover:before {
	background: rgba(66, 65, 75, 0.2) !important;
	mix-blend-mode: multiply;
	content: "" !important;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
}

.listAnyThing .itemBox .subject-material_card__wrap.small-card .card-image img,
.listAnyThing .itemBox .subject-material_card__wrap.small-card .card-video img {
	max-width: 100%;
}

.listAnyThing .itemBox .subject-material_card__wrap.small-card .card-image .logged-in,
.listAnyThing .itemBox .subject-material_card__wrap.small-card .card-video .logged-in {
	top: inherit;
	left: inherit;
	bottom: 0;
	right: 0;
	z-index: 2;
}

.listAnyThing .itemBox .subject-material_card__wrap.small-card .card-video:after {
	content: " ";
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 3;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	width: 64px;
	height: 64px;
	background: url("../../images/icon/video-play.svg") center center no-repeat;
	vertical-align: middle;
	cursor: pointer;
}

.listAnyThing .itemBox .subject-material_card__wrap.small-card .subject-material_card__content {
	padding: 20px;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}

.listAnyThing .itemBox .subject-material_card__wrap.small-card .subject-material_card__content .theme-tag {
	color: $old7D7D7D;
}

.listAnyThing .itemBox .subject-material_card__wrap.small-card .subject-material_card__content:hover h3 a {
	color: $old42414b;
}

.listAnyThing .itemBox .subject-material_card__wrap.small-card .subject-material_card__content h3 {
	margin-bottom: 78px;
}

.listAnyThing .itemBox .subject-material_card__wrap.small-card .subject-material_card__content h3 a {
	color: $oldblack;
}

.listAnyThing .itemBox .subject-material_card__wrap.small-card .subject-material_card__content .read-more {
	color: $old7D7D7D;
}

.listAnyThing.colThree .itemBox {
	-webkit-flex: 0 0 33.333%;
	-ms-flex: 0 0 33.333%;
	flex: 0 0 33.333%;
	max-width: 33.333%;
}

@media (max-width: 767px) {
	.listAnyThing.colThree .itemBox {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}
.footerBox {
	margin-top: 48px;
	@media(max-width: 991px) {
		margin-top: 24px;
	}
}
