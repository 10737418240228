@mixin htmlarea {
	@include fontBody(md);
	--ha-spacing-xl: 56px;
	--ha-spacing-lg: 40px;
	--ha-spacing-md: 36px;
	--ha-spacing-sm: 24px;
	--ha-spacing-xs: 16px;
	@media (max-width: 991px) {
		--ha-spacing-xl: 40px;
		--ha-spacing-lg: 32px;
		--ha-spacing-md: 30px;
		--ha-spacing-sm: 12px;
		--ha-spacing-xs: 8px;
	}

	>* {
		margin-bottom: var(--ha-spacing-md);

		&:first-child {
			margin-top: 0 !important;
			&:empty {
				display: none;
				& + *{
					margin-top: 0 !important;
				}
			}
			>*:first-child {
				margin-top: 0 !important;
			}
		}
		&:last-child {
			margin-bottom: 0 !important;
			>*:last-child {
				margin-bottom: 0 !important;
			}
			&:empty {
				display: none;
				+ *{
					margin-bottom: 0 !important;
				}
			}
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4 {
		margin-top: var(--ha-spacing-lg);
		margin-bottom: var(--ha-spacing-sm);

		+h1,
		+h2,
		+.h1,
		+.h2,
		+h3,
		+h4,
		+h5,
		+h6,
		+.h3,
		+.h4 {
			margin-top: var(--ha-spacing-md);
		}
	}
	iframe {
		display: block;
	}

	li {
		margin-bottom: var(--ha-spacing-sm);

		&:last-child {
			margin-bottom: 0 !important;
		}

		>* {
			margin-bottom: var(--ha-spacing-xs);

			&:first-child {
				margin-top: 0 !important;
			}

			&:last-child {
				margin-bottom: 0 !important;
			}
		}
	}
	ul {
		list-style: none;
		li {
			position: relative;
			&::before {
				position: absolute;
				content: "\2022";
				left: -31px;
			}
		}
	}
	p .button {
		display: block;
	}

	figure {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		padding: 0;
		blockquote {
			font-size: -to-rem(36);
			font-family: $serifTitle-italic;
			padding: 0;
			border: none;
			margin: 0;
			@media (max-width: 991px){
				font-size: -to-rem(24);
			}
		}
	}

	figcaption {
		width: 100%;
		@include microfont(sm);
		margin-top: var(--ha-spacing-xs);

		>* {
			margin-bottom: 4px;

			&:first-child {
				margin-top: 0 !important;
			}

			&:last-child {
				margin-bottom: 0 !important;
			}
		}
	}

	img {
		max-width: 100%;
		object-fit: scale-down;
	}

	a:not(.button):not(.card-event__link) {
		color: inherit;
		text-decoration-line: underline;
		text-decoration-skip-ink: none;
		text-decoration-thickness: 1.5px;
		text-underline-position: under;
		@media (hover: hover) {
			&:hover {
				color: $lightgreen;
				background-color: $offblack;
			}
		}
	}

	.button,
	table,
	figure,
	.circle-charts,
	iframe,
	ul,
	ol,
	.video,
	.callToAction,
	.embedArticle,
	.embedBook {
		margin-top: var(--ha-spacing-xl);
		margin-bottom: var(--ha-spacing-xl);
	}

	.button--center {
		margin-left: auto;
		margin-right: auto;
	}

}
.text-block-lg {
	text-align: center;
	@include fontBody(lg);

	>* {
		margin-bottom: 32px;

		@media (max-width: 991px) {
			margin-bottom: 24px;
		}

		&:first-child {
			margin-top: 0 !important;
		}

		&:last-child {
			margin-bottom: 0 !important;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4   {
		margin-bottom: 36px;
	}
}

.text-block-md {
	@include htmlarea;
	@include fontBody(md);
	--ha-spacing-sm: 34px;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4 {
		+h1,
		+h2,
		+.h1,
		+.h2,
		+h3,
		+h4,
		+h5,
		+h6,
		+.h3,
		+.h4 {
			margin-top: 32px;
			@media (max-width: 991px) {
				margin-top: 24px;
			}
		}
	}
}
.text-block-sm {
	// FACTBOX SIZES if needed
	@include htmlarea;
	@include fontBody(sm);
	--ha-spacing-xl: 80px;
	--ha-spacing-lg: 80px;

	--ha-spacing-md: 24px;
	--ha-spacing-sm: 24px;
	// --ha-spacing-xs: 16px;
	@media (max-width: 991px) {
		--ha-spacing-xl: 36px;
		--ha-spacing-lg: 36px;
		--ha-spacing-md: 24px;
		--ha-spacing-sm: 16px;
		--ha-spacing-xs: 8px;
	}
}

.callToAction {
	overflow: hidden;
	border: 1px solid $offblack;
	border-radius: 12px;
	padding: 40px;
	@media (max-width: 480px) {
		padding: 24px;
	}
	.button {
		margin: 0;
		margin-left: auto;
		@media (max-width: 480px) {
			margin-left: 0;
		}
	}
	.boxCTA {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 48px;

		@media (max-width: 911px) {
			gap: 16px;
		}

		.contentCTA {
			width: calc(60% - 48px);
			@media (max-width: 991px) {
				width: calc(60% - 16px);
			}

			@media (max-width: 480px) {
				width: 100%;
				text-align: center;
			}
		}

		.buttonCTA {
			width: 40%;
			text-align: right;

			@media (max-width: 480px) {
				width: 100%;
				text-align: center;
			}
		}
	}
}
.figure-two-img{
	gap: var(--ha-spacing-sm);
	display: flex;
	flex-wrap: wrap;
	img {
		max-width: 100%;
	}
	div {
		flex: 1;
	}
	figcapition {
		margin: 0;
	}
}

.TextBlock,
.TextBlock-box {
	padding: 120px 0;
	position: relative;
	&::before {
		content: '';
		width: 100vw;
		position: absolute;
		left: calc(50% - 50vw);
		right: calc(50% - 50vw);
		top: 0;
		bottom: 0;
		background-color: inherit;
		z-index: -1;
	}
	>* {
		&:first-child {
			margin-top: 0 !important;
			>*:first-child {
				margin-top: 0 !important;
			}
		}

		&:last-child {
			margin-bottom: 0 !important;
			>*:last-child {
				margin-bottom: 0 !important;
			}
		}
	}
	.button,
	table,
	figure,
	ul,
	ol {
		margin-top: 36px;
		margin-bottom: 36px;
	}

}
