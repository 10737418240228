.card-green {
	@include microfont(xs);
	color: $offblack;
	padding: 24px;
	border-radius: 12px;
	text-align: left;
	background-color: $lightgreen;
	height: 100%;
	min-height: 290px;
	max-width: 350px;
	margin: auto;
	&_pre-title {
		@include microfont(xs);
		margin-bottom: 12px;
	}

	&_title {
		@include heading(h4);
		margin-bottom: 24px;

		@media (max-width: 991px) {}
	}

	&_content {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&_image {
		width: 100%;
		margin-bottom: 32px;
		overflow: hidden;
		flex-shrink: 0;

		img {
			border-radius: 8px;
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&_info {
		display: flex;
		flex-direction: column;
		margin-bottom: 16px;
	}

	&_socials {
		display: flex;
		flex-wrap: wrap;
		gap:16px;
		.icon {
			width: -to-rem(24);
			height: -to-rem(24);
			margin: 0;
		}
		.iconTwitterGreen,
		.iconMailGreen,
		.iconLinkedinGreen {
			filter: brightness(0) saturate(100%) invert(5%) sepia(40%) saturate(2609%) hue-rotate(80deg) brightness(95%) contrast(94%);
		}
	}
	&-group {
		row-gap: 32px;
		@media (max-width: 991px) {
			row-gap: 16px;
		}
	}
}
