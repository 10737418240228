.fagstoff {
	&.theme-color {
		background-color: #E8F2D9;
		padding-block: 120px;

		@media (max-width: 767px) {
			padding-block: 56px;
		}
	}

	h2 {
		margin-bottom: 100px;
		text-align: center;

		@media (max-width: 992px) {
			margin-bottom: 48px;
		}
	}

	&__wrapper {
		// Resetting margins
		margin: 0;

		* {
			margin: 0;
		}
	}

	&__main {
		--gap: 80px;

		display: flex;
		flex-wrap: wrap;
		gap: var(--gap);

		@media (max-width: 1280px) {
			--gap: 32px;
		}

		@media (max-width: 992px) {
			--gap: 56px;
		}
	}

	&__item {
		width: calc((100% / 2) - (var(--gap)) / 2);

		@media (max-width: 992px) {
			width: 100%;
		}

		.header {
			h3 {
				margin-bottom: 24px;

				@media (max-width: 992px) {
					margin-bottom: 16px;
				}
			}

			p {
				@include fontBody(lg);
				margin-bottom: 36px;

				@media (max-width: 992px) {
					margin-bottom: 24px;
				}
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			gap: 12px;
			color: inherit;
			@media (hover: hover) {
				&:hover {
					color: inherit;
					.content-title {
						text-decoration-line: underline;
					}
				}
			}

			@media (max-width: 992px) {
				gap: 4px;
			}

			&--latest {
				border-block: 1px solid currentcolor;
				padding-block: 36px;

				@media (max-width: 992px) {
					padding-block: 24px;
					gap: 12px;
				}

				figure {
					aspect-ratio: 720/408;
					margin-bottom: calc(36px - 12px); // Accounting for the gap of the items
					overflow: hidden;

					@media (max-width: 992px) {
						margin-bottom: calc(24px - 12px); // Accounting for the gap of the items
					}

					img {
						display: block;
						width: 100%;
						height: 100%;
					}
				}
			}

			&:last-child {
				padding-bottom: 36px;
				border-bottom: 1px solid $offblack;

				@media (max-width: 992px) {
					padding-bottom: 24px;
				}
			}

			&.member-required {
				.content-type {
					&::after {
						display: inline-block;
						content: "medlem";
						border: 1px solid $offblack;
						border-radius: 26px;
						padding: 4px 16px;
						font-size: 16px;
						line-height: 18px;
						margin-left: 16px;

						@media (max-width: 992px) {
							font-size: 14px;
							line-height: 16px;
							margin-left: 12px;
						}
					}
				}
			}

			&:not(.member-required) {
				gap: calc(12px - 4px);

				@media (max-width: 992px) {
					gap: calc(8px - 4px);
				}

				.content-type {
					margin-bottom: 4px;
				}
			}

			&-type {
				display: flex;
				align-items: center;
				@include microfont(sm);
				line-height: 1;
				text-transform: uppercase;
				min-height: 24px;
			}

			&-title {
				@include heading(h4);

				&::after {
					content: " –>";
					white-space: nowrap;
				}
			}
		}

		.content:not(.content--latest)+.content {
			margin-top: 40px;

			@media (max-width: 992px) {
				margin-top: 24px;
			}
		}

		.content--latest+.content {
			margin-top: 36px;

			@media (max-width: 992px) {
				margin-top: 24px;
			}
		}
	}
}
