.video {
		position: relative;
		background-color: $lightgreen;
		align-self: center;
		width: 100%;
	iframe, figure, picture {
		margin: 0!important;
	}
	&--no-image {
		.video__play {
			display: none;
		}

		.video__poster {
			display: none;
		}
	}
	iframe,
	videos {
		aspect-ratio: 16/9;
		width: 100%;
		// border-radius: 16px;
		overflow: hidden;
		display: none;
	}

	picture {
		aspect-ratio: 16/9;
		overflow: hidden;
		width: 100%;
		display: block;
	}

	img {
		height: 100%;

	}

	&__poster {
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__play-click {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		aspect-ratio: 16 / 9;
		width: 100%;
		top: 0;
		left: 0;
		background-color: transparent;
		border: none;
		cursor: pointer;
		z-index: 0;
	}

	&__play {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: transparent;
		max-width: -to-rem(60);
		max-height: -to-rem(60);
		border-radius: 50%;
		border: none;
		padding: 0;
		&:hover {
			rect {
				fill: $green;
			}
			path {
				fill: $offblack;
			}
		}
	}

	iframe {
		width: 100%;
	}

	&--active,
	&--no-image {

		.video__poster,
		.video__play-click {
			display: none;
			opacity: 0;
			transition: opacity 0.3s ease 0.3s;
		}

		iframe {
			display: block;
			width: 100%;
		}
	}
}
.video-blocked-preview {
	position: relative;
	aspect-ratio: 16/9;
	overflow: hidden;
	width: 100%;
	display: block;
	img {
		height: 100%;
		object-position: center;
	}
	&::after {
		display: none;
	}
	&__play {
		position: absolute;
		top: 50%;
		left: 50%;
		background-color: transparent;
		max-width: -to-rem(60);
		max-height: -to-rem(60);
		margin: -to-rem(-30);
		border-radius: 50%;
		&:hover {
			rect {
				fill: $green;
			}
			path {
				fill: $offblack;
			}

		}
	}
}
