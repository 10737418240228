.courses-and-arrangements {
	.card-event + .card-event {
		margin-top: 16px;
	}
	h3, .h3 {
		margin-top: 0;
		margin-bottom: 24px;
	}
	.button {
		margin-top: 24px;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.col-12 + .col-12 {
		margin-top: 80px;
		@media (max-width: 767px) {
			margin-top: 40px;
		}
	}
}
