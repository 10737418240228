.swiper {
	@media (max-width: 991px) {
		padding-bottom: 52px;
	}
	&-button-next,
	&-button-prev {
		@media (max-width: 991px) {
			bottom: 0;
			top: unset;
		}
		&::after {
			content: '';
			background-size: contain;
			aspect-ratio: 1 / 1;
			background-color: transparent;
			background-repeat: no-repeat ;
			background-size: 16px ;
			background-position: 46% center;
			background-image: url(../../images/carousel_arrow_left.svg);
			min-width: 52px;
			min-height: 52px;
			padding: 0;
			border: 2px solid #0B1C08;
			border-radius: 30px;
			@media (max-width: 991px) {
				min-width: 36px;
				min-height: 36px;
				background-size: 11px ;
			}
		}
	}
	&-button-next:after {
		background-image: url(../../images/carousel_arrow_right.svg);
		background-position: right 46% center;
	}
}

.bg-dark, .bg-blue {
	.swiper-button-next:after, .swiper-button-prev:after {
		filter: brightness(0) saturate(100%) invert(94%) sepia(22%) saturate(165%) hue-rotate(29deg) brightness(97%) contrast(96%);
	}
}
