.membership-info {
	&__wrapper {
		&>.text-block-md {
			max-width: 600px;
		}
	}

	&__title {
		@include heading(d1);
	}

	&__intro {
		display: flex;
		max-width: 700px;
		flex-direction: column;
		gap: 24px;
		margin-top: 48px;
		@media (min-width: 992px) {
			margin-top: 32px;
		}


		.intro-text {
			@include fontBody(intro);
		}
		em {
			font-style: normal;
		}
	}

	&__price {
		display: flex;
		max-width: 600px;
		flex-direction: column;
		gap: 24px;
		margin-top: 60px;
		@media (max-width: 991px) {
			margin-top: 40px;
		}
		&:not(:last-child) {
			margin-bottom: 24px;
		}
		.h4 {
			margin: 0;
		}


		.price-card {
			display: flex;
			flex-direction: column;
			padding: 24px;
			width: 323px;
			border-radius: 12px;
			background: $lightgreen;

			.price {
				@include heading(h2);
			}
		}
	}
}
