.cards {
	$cards-gap: 24px;
	box-sizing: border-box;

	& * {
		box-sizing: inherit !important;
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: $cards-gap;

		justify-content: center;

		// Resetting margins
		* {
			margin: 0;
		}
	}

	.card {
		display: flex;
		flex-direction: column;
		border-radius: 12px;
		background-color: $theme-blue;
		width: calc((100% - (#{$cards-gap} * 2)) / 3);

		padding: 40px 25px;

		@media (max-width: 1280px) {
			width: 100%;
		}

		&__content {
			flex-shrink: 0;
			flex-grow: 0;

			h3 {
				@include serifHeading;
				font-size: 44px;
				line-height: 1.3;
				margin-bottom: 16px;

				@media (max-width: 991px) {
					font-size: 30px;
					line-height: 1.3;
					margin-bottom: 12px;
				}
			}

			p {
				@include fontBody(md);
			}
		}

		&__link {
			@include fontBody(md);
			display: flex;
			align-items: flex-end;

			flex-shrink: 0;
			flex-grow: 1;
			margin-top: 80px;

			@media (max-width: 1280px) {
				margin-top: 64px;
			}

			a {
				color: $offblack;
				line-height: 36px;
				text-decoration: none;

				span {


					&.icon {
						position: relative;
						margin-left: 8px;
						width: 28px;
						height: 36px;
						filter: brightness(0) saturate(100%) invert(5%) sepia(48%) saturate(2126%) hue-rotate(80deg) brightness(94%) contrast(94%);

						img {
							width: 100%;
							height: 100%;
							object-fit: contain;
							object-position: center;
							vertical-align: bottom;
						}
					}

					&:not(.icon) {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
