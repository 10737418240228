.form {
	&Wrapper {
		display: block;
		margin: 0 auto;
		position: relative;
		width: 50%;

		@media (max-width: 1199px) {
			width: 70%;
		}

		@media (max-width: 991px) {
			width: 100%;
		}

		*[class*='col'] & {
			width: 100%;
		}
	}

	&Box {
		.label-not-upper {
			text-transform: none;
		}

		.ja-og-nei {
			@include microfont("sm");
		}

		fieldset {
			&:not(:last-child) {
				margin-bottom: 80px;

				@media (max-width: 991px) {
					margin-bottom: 48px;
				}
			}

			h4,
			.h4 {
				margin-top: 0;
				margin-bottom: 48px;

				&:not(:first-child) {
					margin-top: 80px;

					@media (max-width: 991px) {
						margin-top: 32px;
					}
				}

				@media (max-width: 991px) {
					margin-bottom: 24px;
				}

				& + .form-group:has(>.label--sublabel:first-child) {
					margin-top: calc(24px - 48px);
				}
			}
		}
	}

	&Title {
		margin: 0px;
		margin-bottom: 80px;
		@media (max-width: 991px) {
			margin-bottom: 48px;
		}
		h2 {
			margin: 0;
			margin-bottom: 36px;
			// &:has(+*[class*="text-block"])  {
			// 	margin-bottom: 36px;
			// }
		}

		h3 {
			margin-block: 80px 48px;
			@media (max-width: 991px) {
				margin-block: 48px 32px;
			}
		}
		>:last-child {
			margin-bottom: 0!important;
		}
		>:first-child {
			margin-top: 0!important;
		}

		*[class*="text-block"] {
			margin-bottom: 80px;
			max-width: 600px;

			@media (max-width: 991px) {
				margin-bottom: 48px;
			}
		}
	}

	&-group {
		position: relative;
		margin-bottom: 48px;

		@media (max-width: 991px) {
			margin-bottom: 32px;
		}

		&:last-child {
			margin-bottom: 0;
		}
		.button {
			margin: 4px;
			height: 60px;
		}

		&--md {
			width: 50%;
		}

		&--sm {
			width: 33%;
		}

		&--row {
			display: flex;
			align-items: flex-end;
			gap: 24px;
			flex-wrap: wrap;

			.row-item {
				width: 100%;

				@media (min-width: 992px) {
					width: calc(50% - (24px / 2));
				}

				label,
				.label,
				.label--help-text,
				.label--sublabel {
					&:last-child {
						margin-bottom: 0 !important;
					}
				}
			}
		}

		&--asymmetrical {
			.row-item {
				flex-grow: 1;
				flex-shrink: 0;
				width: 100%;

				@media (min-width: 992px) {
					width: calc(((100% / 3) * 2) - (24px / 2));
				}

				&--sm {
					flex-grow: 0;
					width: 100%;

					@media (min-width: 992px) {
						width: calc((100% / 3) - (24px / 2));
					}
				}
			}
		}

		// input {
		// 	&[type="text"],
		// 	&[type="url"],
		// 	&[type="password"] {
		// 		padding-left: 15px;
		// 		padding-right: 15px;
		// 	}

		// 	&[type="file"] {
		// 		padding: 10px;
		// 	}
		// }

		.label,
		label {
			&:not(.form-check-label):not(.form-radio-label) {
				@include fontBody(sm);
				font-weight: 600;
				display: block;
				text-align: left;
				margin-bottom: 12px;
				color: inherit;
				text-wrap: wrap;

				&.label--help-text {
					font-weight: 400;
				}

				&.label--sublabel {
					@include microfont(xs);
					font-weight: 400;
				}

				&.label--counter {
					margin-top: 8px;
					text-align: right;
				}
			}
		}

		.label+.label--help-text,
		label+.label--help-text,
		.label+.label--sublabel,
		label+.label--sublabel {
			margin-top: -4px;
		}

		&:last-child {
			margin-top: 40px;
			margin-top: 0px;
		}

		.inputFileWrapper {
			display: flex;
			gap: 8px;

			.inputFileLabel {
				margin-bottom: 0 !important;

				// &:focus-within {
				// 	border: unset;
				// 	outline: unset;
				// }

				.button {
					@include button-secondary;
					padding-block: 12px;
					display: inline-block;
					// padding: 20px 30px;
					// line-height: -to-rem(20);
					border-radius: 50px;


					@media (max-width: 991px) {
						padding: 12px 20px;
					}

					&.btnFile {
						&:focus-within {
							outline-style: solid;

						}
					}
				}

				input {
					cursor: pointer;
					left: 0;
					bottom: 0;
					opacity: 0;
					position: absolute;
				}
			}

			input[type="text"] {
				color: $offblack;
				background-color: white;
				padding: 18px 32px;
				border: 2px solid $offblack;
				@include fontBody(xs);
				line-height: 20px;
				border-radius: 50px;
				flex: 1;

				@media (max-width: 991px) {
					padding: 12px 20px;
				}
			}

			&:hover {
				.btn {
					background-color: $green;
				}
			}

			.inputFileText {

				&::placeholder {
					color: $offblack;
				}
			}
		}

		&.button-area {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 16px;
		}
	}

	&-check,
	&-radio {
		display: block;
		position: relative;
		line-height: 1;

		&-input {
			position: absolute;
			top: 0;
			left: 0;
			width: 1px;
			height: 1px;
			opacity: 0;
		}

		&-label {
			@include fontBody(md);
			line-height: 1.4;
			font-weight: 400;
			margin: 0;
			display: inline-flex;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 16px;
			cursor: pointer;

			p {
				margin: 0;

				a {
					text-decoration: underline;
					text-decoration-thickness: 2px;
					text-underline-offset: 4px;
				}
			}
			@media (hover: hover) {
				&:hover {
					&::before {
						background-color: $offblack;
					}
				}
			}

			&::before {
				content: '';
				flex-shrink: 0;
				position: relative;
				top: 2px;
				display: block;
				width: 24px;
				height: 24px;
				border-radius: 4px;
				border: 2px solid currentColor;
				background-color: transparent;

				.form-check-input:focus-visible+&,
				.form-radio-input:focus-visible+& {
					outline-style: solid;
				}
			}

			&::after {
				content: '';
				flex-shrink: 0;
				position: absolute;
				top: 7px;
				left: 5px;
				display: block;
				width: 14px;
				height: 14px;
				background-color: transparent;

				.form-check-input:checked+&,
				.form-radio-input:checked+& {
					background-color: currentColor;
				}
			}

			&:has(>p) {
				&::after {
					top: 6px;
				}
			}
		}

		&+& {
			margin-top: 16px;
		}

		label+&,
		.label+& {
			margin-top: 24px;
		}
	}

	&-radio {
		&-label {

			&::before,
			&::after {
				border-radius: 50%;
			}
		}
	}

	&__card {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		gap: 24px;
		padding: 32px;
		margin-bottom: 80px;
		border: 2px solid $offblack;
		border-radius: 12px;
		background-color: transparent;

		@media (max-width: 991px) {
			margin-bottom: 48px;
		}

		.card-tag {
			display: inline-flex;
			align-items: center;
			padding: 4px 12px;
			border: 2px solid $offblack;
			border-radius: 30px;
			@include microfont(xs);
			font-weight: 500;
		}

		.card-content {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.date {
				@include microfont(xs);
				font-weight: 600;
			}

			.main-text {
				@include fontBody(intro)
			}
		}

		.card-price {
			@include microfont(xs);
			font-weight: 600;
		}
	}
}
.select2-custom {
	display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    background-color: #fff !important;
    border: 2px solid #0B1C08 !important;
    border-radius: 50px !important;
    padding: 12px 32px !important;
	max-width: 100%;
	font-size: -to-rem(18) !important;
	height: 64px!important;
}
.select2 {
	all: unset !important;

	& * {
		all: unset !important;
	}

	display: block !important;
	position: relative !important;
	width: 100% !important;

	&-container {
		&--open,
		&--focus {
			border-radius: 30px !important;
			-webkit-box-shadow: 0 0 transparent !important;
			box-shadow:  0 0 transparent !important;
			.select2-selection {
				z-index: 9999 !important;
				border: 2px solid #0B1C08 !important;
			}
		}
		&--focus {
			// outline: solid currentColor 2px !important;
			// outline-offset: 2px !important;
		}
	}

	&-selection {
		display: flex !important;
		position: relative !important;
		align-items: center !important;
		justify-content: space-between !important;
		background-color: #fff !important;
		border: 2px solid $offblack !important;
		border-radius: 50px !important;
		padding: 12px 32px !important;

		@media (max-width: 991px) {
			padding: 12px 20px !important;
		}
		&:focus-visible {
			outline: solid currentColor 2px !important;
			outline-offset: 2px !important;
		}

		&__rendered {
			display: block !important;
			white-space: nowrap !important;
			max-width: 90% !important;
			overflow: hidden !important;
			text-overflow: ellipsis !important;
			cursor: pointer !important;
			font-size: -to-rem(18) !important;
			line-height: 20px !important;

			@media (max-width: 991px) {
				font-size: -to-rem(16) !important;
				line-height: 20px !important;
			}
		}

		&__arrow {
			display: block !important;
			flex-shrink: 0 !important;
			width: 36px !important;
			height: 36px !important;
			background-image: url("../../images/icon/chevron-down-36.svg") !important;
			background-size: cover !important;
			background-repeat: no-repeat !important;
			background-position: center !important;
			transition: rotate 175ms ease-in-out !important;

			@media (max-width: 991px) {
				width: 22px !important;
				height: 22px !important;
			}

			.select2-container--open & {
				rotate: 180deg !important;
			}

			b {
				display: none !important;
			}
		}
	}

	&-results {
		&__option {
			font-size: -to-rem(18) !important;
			line-height: 20px !important;

			@media (max-width: 991px) {
				font-size: -to-rem(16) !important;
				line-height: 20px !important;
			}
		}
	}

	&-dropdown {
		padding-top: 28px;
		position: absolute;
		z-index: 9998;
		top: -28px;

		.select2-container--open & {
			border: 2px solid #0B1C08 !important;
		}

		&--above {
			padding-top: 0px;
			padding-bottom: 28px;
			top: 28px;
		}
	}
}

.form-control {
	display: block;
	width: 100%;
	padding: 18px 32px;
	@include fontBody(xs);
	line-height: 20px;
	color: $offblack;
	background-color: #fff;
	border: 2px solid $offblack;
	border-radius: 50px;


	@media (max-width: 991px) {
		padding: 12px 20px;
	}
	@media (hover: hover) {
		&:hover {
			outline-style: solid;
		}
	}

	&--md {
		width: 50%;

		@media (max-width: 574px) {
			width: 80%;
		}
	}

	&--sm {
		width: 33%;

		@media (max-width: 574px) {
			width: 50%;
		}
	}

	&:focus-visible,
	&:focus-within {
	}

	&:placeholder {
		color: #999;
		opacity: 1;
	}

	&[disabled],
	&[readonly] {
		background-color: #f2f2f3;
		opacity: 1;
	}

	&--textarea {
		display: flex;
		align-items: center;
		padding-block: 16px;
		padding-right: 12px;

		textarea {
			border: none;
			width: 100%;
			max-width: 100%;
			min-width: 100%;
			height: 100%;
			min-height: 56px;

			&:focus,
			&:focus-visible,
			&:focus-within {
				outline: none;
			}
		}
	}
}

// .form-control[disabled],
// fieldset[disabled] .form-control {

// 	.inputFileWrapper {
// 		display: -webkit-box;
// 		display: -webkit-flex;
// 		display: -ms-flexbox;
// 		display: flex;

// 		.inputFileLabel {
// 			margin: 0px 20px 0px 0px;
// 			padding: 0;

// 			.btn {
// 				@include microfont("sm");
// 				// font-size: 0.75rem;
// 				display: inline-block;
// 				text-align: center;
// 				white-space: nowrap;


// 				&.btnFile {
// 					font-weight: 500;
// 					min-width: 100px;
// 					padding: 8px 24px;
// 					background-color: transparent;
// 					border: 2px solid $offblack;
// 					border-radius: 30px;

// 					&:hover,
// 					&:focus {
// 						color: $offblack;
// 						text-decoration: none;
// 						background-color: $green;
// 					}
// 				}
// 			}

// 			&:focus-visible,
// 			&:focus-within {
// 				border: none;
// 				outline: none;

// 				.btn.btnFile {
// 					outline: 2px solid $green;
// 				}
// 			}
// 		}

// 		.inputFileText {
// 			background: #FFFFFF;
// 		}
// 	}
// }

// .min-profil {

// 	.formWrapper {
// 		width: 100%;
// 	}

// 	.formWrapper .form-group .select2-container {
// 		width: 100% !important;
// 	}

// 	.formWrapper .form-check {
// 		display: inline-block;
// 	}

// 	.formWrapper .form-check .custom-control-inline {
// 		float: left;
// 		margin-right: 25px;
// 	}

// 	.formWrapper .form-check .custom-control-inline .custom-control-label {
// 		padding-top: 8px;
// 	}

// 	.formWrapper .form-group.row {
// 		padding: inherit;
// 	}

// 	.formWrapper .formBox .row-content {
// 		overflow: hidden;
// 	}

// 	.formWrapper .formBox .button.button-green.btnFull {
// 		margin-top: 15px;
// 	}

// 	.formWrapper .formBox .row-content .form-check {
// 		float: left;
// 		width: 135px;
// 	}

// 	.formWrapper .formBox .row-content .form-group {
// 		float: left;
// 		width: calc(100% - 135px);
// 	}
// }
.form-info {
	@include microfont(sm);
	width: 100%;

}

.form-error {
	margin-top: 8px;
	@include microfont(sm);
	color: $danger;
	width: 100%;
}
.bg-blue,
.bg-dark {
	.form-control {
		outline-color: $green;
		@media (hover: hover) {
			&:hover {
				outline-style: solid;
			}
		}
	}
}
