.circle-charts {
	text-align: center;
	&__title {
		@include heading(h4);
		margin-bottom: 16px;
	}
	&__caption {
		@include microfont(sm);
		margin-top: 16px;
	}
	.circles-valueStroke {
		stroke-width: 13px;
	}
	.circles-text {
		font-family: $serifTitle;
		font-weight: 400;
		font-size: -to-rem(48)!important;
		margin-bottom: 16px;

		@media (max-width: 991px) {
			font-size: -to-rem(32)!important;
		}
	}
	.circle {
		text-align: center;
	}
}

