.banner {
	&Block {}

	&Wrapper {
		position: relative;
		display: block;
		max-width: 1440px;
		min-height: 560px;
		margin: 0 auto;
		overflow: hidden;

		@media (max-width: 576px) {
			min-height: 520px;
		}

		// @media (max-width: 767px) {
		// 	min-height: 375px;
		// }

		@media (max-width: 767px) {
			min-height: 500px;
		}


		&.bannerHeader {
			min-height: auto;

			@media (max-width: 991px) {
				height: 100%;
			}
		}
	}


	&Image {
		position: absolute;
		inset: 0;
		z-index: 1;

		.bannerHeader & {
			position: relative;
		}
	}

	&Box {
		position: absolute;
		inset: 0;
		z-index: 2;

		.container {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			height: 100%;
			align-items: center;
		}

		&::before {
			// opacity: 0.5;
			opacity: 0.3;
			content: "";
			background: $offblack;
			position: absolute;
			inset: 0;
			z-index: -1;
		}

		.contentIntro {
			color: #FFFFFF;
			text-align: center;
			width: 100%;
			margin-top: 19%;

			@media (max-width: 567px) {
				margin-top: 5%;
			}

			h2 {
				color: #FFFFFF;
				font-size: 2.5rem;
				@include serifHeading;

				@media (max-width: 567px) {
					font-size: 28px;
					margin-bottom: 10px;
				}
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p {
				margin-bottom: 25px;
				@include serifHeading;
			}

			>* {
				color: #FFFFFF;
				font-family: $sans;
				line-height: normal;
			}
		}
	}

	&BoxShort {
		.contentIntro {
			margin-left: 25%;
			margin-right: 25%;

			@media (max-width: 767px) {
				margin-left: 0;
				margin-right: 0;
			}

			>* {
				line-height: 1.7rem;
			}
		}
	}
}

.course .bannerBlock .bannerWrapper.bannerHeader,
#hostseminaret .bannerBlock .bannerWrapper.bannerHeader,
.localTeam .bannerBlock .bannerWrapper,
.vacantPosition .bannerBlock .bannerWrapper {
	max-height: 480px;
	min-height: 480px;
	height: 480px;
}

.bannerBlock .bannerWrapper .bannerBox:before {
}

@media(max-width: 767px) {
	.course .bannerBlock {
		display: none;
	}
}
