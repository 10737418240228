body {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

tbody .word-break {
    word-break: break-word;
}

.minSide-courseHistory-date {
    @include microfont("sm");
}

/************************************ agency carousel ********************/

.agency-list-carousel_card {
    max-width: 100% !important;
}

/* KOM-203 BY RODRIGO --- I'VE ADDED THIS CODE TO SET THE SIZE OF DISTENCE OF ARROWS AND THE CONTENT BOX SCREEN BY SCREEN */

@media (min-width: 791px) {
    .agency-list_cards.owl-carousel .owl-nav {
        position: absolute;
        top: 36%;
        left: -44px;
        right: -44px;
    }
    .agency-list_cards.owl-carousel .owl-nav .owl-prev {
        position: absolute;
        left: 0;
    }
    .agency-list_cards.owl-carousel .owl-nav .owl-prev:hover {

    }
    .agency-list_cards.owl-carousel .owl-nav .owl-next {
        position: absolute;
        right: 0;
    }
    .agency-list_cards.owl-carousel .owl-nav .owl-next:hover {
    }
}

@media (min-width: 992px) {
    .agency-list_cards.owl-carousel .owl-nav {
        position: absolute;
        top: 36%;
        left: -37px;
        right: -37px;
    }
}

/* ----------------------------------------------------------------------------------------------------------------------- */

@media (min-width: 1366px) {
    .agency-list_cards.owl-carousel .owl-nav {
        position: absolute;
        top: 36%;
        left: -62px;
        right: -62px;
    }

}

.agency-list_cards.owl-carousel .owl-dots {
    margin-top: 20px;
}

.agency-list_cards.owl-carousel .owl-dots .owl-dot {
    background-color: #d8ddd8;
}

.agency-list_cards.owl-carousel .owl-dots .owl-dot span {
    margin: 0;
    border-radius: 100px;
    width: 36px;
    height: 4px;
    background-color: #d8ddd8;
}

.agency-list_cards.owl-carousel .owl-dots .owl-dot.active span {
    background: $green;
}

/************************************ article images classes in html area ********************/

.kom-nav.mega-nav .menu-links .login-button-logged,
.searchOpen .menu-links .login-button-logged {
    visibility: hidden;
}

.nav-newsletter_emoji {
    text-align: center;
    margin-bottom: 40px;
}

.nav-newsletter_text {
    margin-bottom: 80px;
    width: 100%;
}


/************************************ article images classes in html area ********************/

.modalAccessOpen p a:not(.button) {
    @include serifRegular;
    font-size: 22px;
}

/*caption issues*/

.video-block {
    padding-left: 0;
    padding-right: 0;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video-block .video-embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.header-AnyThing {
    text-align: center;
	margin-bottom: 80px;
	@media (max-width: 767px) {
		margin-top: 40px;
		margin-bottom: 40px;
	}
}

.header-AnyThing h2 {
    // font-size: 1.5rem;
    // font-weight: 700;
    // line-height: 28px;
    // @include serifHeading;
}

.header-AnyThing p {
    // line-height: 26px;
    margin-bottom: 0px;
}

.button.btnFull {
    width: 100%;
    min-width: 90px;
}

.invalidDateText {
    color: red;
    padding: 12px;
}

@media (min-width: 769px) {
    .title-video-section {
        max-width: 40%;
    }
}

/*table {
    border-collapse: collapse;
}*/

.logout-button {
    cursor: pointer;
    color: $green;
}

.logout-button:hover {
    color: white;
    background: -webkit-gradient(linear, left top, left bottom, from(#a2cd38), to(#88ac2e));
    background: linear-gradient(180deg, #a2cd38 0%, #88ac2e 100%);
}

.embed-videos {
    margin-top: 0px;
}

.embed-videos h2 {
    @include serifHeading;
}

.embed-videos.bg-blue .video-item .logged-in span:nth-child(2) {
    background: #FFFF;
    color: $old003C4C !important;
}

.embed-videos.bg-blue .video-item .item-wrap .img-video .medlemIssue {
    border: 1px solid $old003C4C;
}

.sectionBlockMacro {
    margin-left: -60%;
    margin-right: -60%;
    overflow: hidden;
    padding-right: 30px;
    padding-left: 30px;
}

.sectionBlockMacro p {
    font-family: $sans;
    color: white;
    font-size: 0.825rem;
    line-height: 1.5rem;
    font-weight: normal;
}

.bg-blue .percentMacroP {
    color: #ffffff;
    font-family: $sans;
    font-size: 0.875rem;
    line-height: 22px;
}

@media (max-width: 767px) {
    .sectionBlockMacro {
        margin-left: -25%;
        margin-right: -25%;
    }
    .sectionBlockMacro .container {
        width: auto;
    }
    .sectionBlock>.container>.contentBox.colSeparete>.itemBox {
        border-right: 0;
    }
}

@media (max-width: 767px) {
    .logout-button {
        display: none;
    }
}

@media (max-width: 991px) {
}

@media (max-width: 374px) {
    .article {
        float: none;
        width: 100%;
    }
}

.banner-area .banner-bg {
    background-size: cover;
}

.article .imageArticle,
.prpratSingle .imageArticle,
.course .imageArticle,
.advertiseSingle .imageArticle,
.vacantPosition .imageArticle {
    overflow: hidden;
}

.relatedCategories {
    margin-left: -30%;
    margin-right: -40%;
    overflow: hidden;
    padding-right: 30px;
    padding-left: 30px;
}



.related-categories-title {
    color: $oldblue;
    @include serifRegular;
    font-size: 20px;

    line-height: 32px;
}

.related-categories-tags {
    color: $oldgray;
    @include microfont(sm);
    line-height: 22px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.related-categories-tags a {
    color: $oldgray;
    text-decoration: underline;
    padding-right: 15px;
}

.clearBoth {
    clear: both;
}

/*************************************************************************/

.text-block-4 {
    padding: 1% 1%;
}

.itemColumn:nth-child(3) {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

/************************    SEMINAR PART AND LAYOUT    ********************/

.content-block {
    border-bottom: 1px solid #dadeda;
    padding-top: 60px;
    padding-bottom: 30px;
}

.seminar-logoList {
    padding-top: 30px;
    margin-top: 30px;
    margin-bottom: 100px;
}

.seminar-banerBlock .bannerWrapper.bannerHeader {
    max-height: 100%;
}

.seminar-heading {
    padding-left: 13%;
    padding-right: 13%;
}

.seminar-anchorMenu {
    margin-bottom: 30px;
}

#om_seminaret {
    margin-top: 40px;
}

@media (min-width: 769px) {
    .price-onlyMembers {
        margin-left: 25%;
    }
}

/*************************************************************************/

/************************   PRESENTERS PART   ********************/

.presenters-readmore {
    max-width: 40px;
}

.presenters-boxCard {
    min-height: -webkit-max-content;
    min-height: -moz-max-content;
    min-height: max-content;
}

.presenters-image {
    margin-top: 8px;
    /*float: right;*/
    max-height: 160px;
}

/*************************************************************************/

/************************   PRESENTERS PART   ********************/

/*************************************************************************/

@media (max-width: 425px) {
    .sectionBlockMacro {
        margin: 50px 0;
    }
    .contactBox {
        width: 100%;
    }
    @media (max-width: 767px) {
        .contactBox {
            overflow: auto;
        }
    }
    .itemColumn:nth-child(2) {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        border-right: 0;
        border-left: 0;
    }
    .sectionBlock>.container>.contentBox.colSeparete>.itemBox {
        border-left: 0;
    }
    .select2-container {
        max-width: 100%;
    }
}

/***** Min Side *****/

a.item-content.my-page-main_button {
    display: block;
}

/*
.minside .f2-content .f2-items > div.active {
    border-bottom: 2px solid #D2E288 !important;
    content: "";
    position: absolute;
    left: 48%;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #D2E288;
    clear: both;
}
*/

.minside .f1-content .f1-items>div a.active {
    position: relative;
    border-bottom: 2px solid #d2e288;
}

.minside .f2-content .f2-items>div a.active {
    position: relative;
    border-bottom: 2px solid #d2e288;
}

.minside .minside__studentForm select {
	display: block;
}

#min-profil,
#min-fagbladet,
#mine-verv {
    display: block;
}

.nav-tem-center {
    text-align: center !important;
}

.bg-white .itemColumn .h5,
.bg-gray .itemColumn .h5 {
    color: $oldblue;
}

#moteoversikt {
    display: block;
}


.related-books .book-list_wrap .embedBook,
.related-books .book-list_wrap .embedBook .moreContent {
    -webkit-transition: none;
    transition: none;
}

.related-books .book-list_wrap .embedBook .moreContent {
    background: none;
    width: 66.6667%;
}

@media (max-width: 767px) {
    .related-books .book-list_wrap .embedBook .moreContent {
        width: 100%;
    }
}

.related-books .book-list_wrap .embedBook:hover,
.related-books .book-list_wrap .embedBook:active,
.related-books .book-list_wrap .embedBook:focus {
    background: #ffffff;
}

.related-books .book-list_wrap .embedBook .imageBox>img {
    max-width: 100%;
}

.related-books .book-list_wrap .embedBook .contentBox .titleContent {
    word-wrap: break-word;
}



.statistics .contactBox h4 {
    color: $green !important;
    font-weight: 300 !important;
    font-family: $sans !important;
    font-size: 3rem !important;
    margin-bottom: 20px !important;
}

.statistics.bg-blue .itemColumn h5 {
    color: #ffffff !important;
    font-family: $sans !important;
    font-weight: bold !important;
    font-size: 1rem !important;
}

.statistics .itemColumn p {
    font-size: 0.825rem;
    line-height: 1.5rem;
}



.bg-gray {
    background-color: #f0f3f0 !important;
}

.bg-white {
    background-color: #ffffff !important;
}

.statistics.bg-gray .itemColumn h5 {
    color: $oldgray !important;
    font-family: $sans !important;
    font-weight: bold !important;
    font-size: 1rem !important;
}

.statistics.bg-gray .itemColumn p {
    color: $oldgray !important;
}

.statistics.bg-white .itemColumn h5 {
    color: #313131 !important;
    font-family: $sans !important;
    font-weight: bold !important;
    font-size: 1rem !important;
}

.statistics.bg-white .itemColumn p {
    color: $oldblue !important;
}

/* Agencies */

/* ############################## by Michel */

.contentAgencies .sectionBlock .container .headerBox {
    margin-bottom: 0;
}

.contentAgencies .sectionBlock .container .headerBox:after {
    display: none;
}

.contentAgencies .sectionBlock:last-child {
    padding-top: 0;
}

.contentAgencies .sectionBlock .selectBox:first-child {
    text-align: right;
}

.contentAgencies .sectionBlock .selectBox:last-child {
    text-align: left;
}

.contentAgencies .sectionBlock .selectBox select {
    width: 40%;
}

@media (max-width: 767px) {
    .contentAgencies .sectionBlock .selectBox select {
        width: 50%;
    }
}

@media (max-width: 567px) {
    .contentAgencies .sectionBlock .selectBox select {
        width: 100%;
    }
    .contentAgencies .sectionBlock .selectBox:first-child,
    .contentAgencies .sectionBlock .selectBox:last-child {
        text-align: center;
    }
    .contentAgencies .sectionBlock .selectBox:first-child {
        margin-bottom: 20px;
    }
    .contentAgencies .button {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

/* ################################# */

/* end changes in agencies items */

.personBox .personDescription h1,
.personBox .personDescription h2,
.personBox .personDescription h3,
.personBox .personDescription h4,
.personBox .personDescription h5,
.personBox .personDescription h6 {
    color: #ffffff;
}

.personBox .personDescription {
    min-height: 470px;
}

@media (max-width: 767px) {
    .personBox .personDescription {
        min-height: 515px;
    }
}

@media (max-width: 567px) {
    .personBox .personDescription {
        min-height: 550px;
    }
    .personBox:last-child {
        min-height: 455px !important;
    }
}

@media (max-width: 320px) {
    .personBox .personDescription {
        min-height: 455px;
    }
}



/* social icons media side  #### Added by Rodrigo */

.st-custom-button {
    cursor: pointer;
}

/**************************************************/

.contentIntro a.button {
    margin: 0 5px 5px 5px;
}

/**************************************************/
/* adjust ul>li font in articles  #### Added by Michel */


/* adjust same size of vacancies box  #### Added by Michel */

.vacantBox {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 576px) {
    .vacantBox .vacantSubBox .vacantPlace {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .vacantPlace p {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    }
    .vacantBox .vacantLogo {
        border-top: 0;
        padding: 0;
        float: right;
        text-align: right;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    }
    .vacantLogo img {
        display: inline;
    }
    .vacantBox .vacantSubBox .vacantDeadline {
        width: 50%;
        border-right: 1px solid #e7eae6;
        padding-right: 15px;
    }
    .vacantBox .vacantSubBox .vacantPosition {
        width: 50%;
        padding-left: 15px;
        border-top: 0;
        padding-top: 0;
    }
    .icon.iconAvailable {
        margin-left: 15px;
        margin-bottom: 0;
    }
    .icon.iconPrice {
        margin-bottom: 0;
    }
}

.icon.iconMap2 {
    margin-bottom: 0;
}

/* adjust sponsor boxes  #### Added by Michel */

.sponsor {
    padding-bottom: 21px;
    padding-top: 34px;
}

.container.sponsor>.row>div {
    padding: 45px;
    text-align: center;
    margin: 0 auto;
    min-height: 210px;
}

.container.sponsor>.row>div>img {
    max-width: 100%;
}

/* adjust hostseminaret page  #### Added by Michel */


#hostseminaret .listMenuLinks li>a {
    font-size: 14px;
}


#hostseminaret h1,
#hostseminaret h2,
#hostseminaret h3,
#hostseminaret h4,
#hostseminaret h5,
#hostseminaret h6 {
    @include serifHeading;
    word-break: break-word;
}


.sideTextSignUp h3:before {
    content: " ";
    height: 2px;
    display: inline-block;
    width: 30px;
    margin-right: 10px;
    background-color: $green;
    position: relative;
    vertical-align: middle;
}

.sideTextSignUp h3 {
    color: $old7D7D7D;
    @include microfont("sm");
    // font-size: 0.75rem;



    margin-bottom: 20px;
}

.sideText h3 {
    color: $old7D7D7D;
    @include microfont("sm");
    // font-size: 0.75rem;

    margin-bottom: 20px;
}

.sideText h3:before {
    content: " ";
    height: 2px;
    display: inline-block;
    width: 30px;
    margin-right: 10px;
    background-color: $green;
    position: relative;
    vertical-align: middle;
}

/*by Rodrigo: agencies listing page to top button*/

.button-totop {
    cursor: pointer;
	display: inline-block;
}

@media (max-width: 767px) {
    .button-totop {
        display: none;
    }
}

/*+++++++++++++++++++++++++++++++++++++++++++++++*/

/*+++++++++++++++++++++++++++++++++++++++++++++++*/

.button-totop img {
    width: 32px;
}

a.button.button-wide {
    width: 100%;
	text-align: center;
}

/* limit full width to 1440px - by Michel */

/* main.xp-region.content {
    max-width: 1440px;
    margin: 0 auto;
} */

/* ######################### */

/* ######################### */

.poiter-none {
    pointer-events: none;
}

/* ######################### */

@media (max-width: 767px) {
    /* .embed-videos {
        padding: 0;
    } */
    .sectionBlock .textBox {
        overflow: auto;
    }
}

/*.personBox .personDescription .closeContent .icon:before {
    transform: rotate(-45deg);
}*/

.personBox .personDescription .closeContent .iconPlus {
    position: absolute;
    right: 10px;
    top: 10px;
}

.personBox .personDescription .closeContent .iconPlus:before {
    content: " ";
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url(../images/icon_plus.svg) center center no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin-right: 5px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}

@media (max-width: 767px) {
    .body .TextBlock {
        margin: 50px -25%;
    }
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

::-webkit-scrollbar-track {
    border-radius: 3px;
    background: #dadeda;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: #98c034;
}

@media (min-width: 768px) {
    .personBox .personDescription .closeContent {
        display: none;
    }
}


@media (max-width: 375px) {
    .icon.iconAvailable:before {
        top: 8px;
    }
    .icon.iconAvailable {
        margin-left: 0;
        margin-bottom: 0;
        margin-top: 20px;
        border-top: 1px solid #e7eae6;
        width: 100%;
        padding-top: 15px;
    }
}



/*Faktabox in intro*/

.sectionBlock .container .headerBox .contentIntro .factbox {
    margin-left: -53.5%;
    margin-right: -53.5%;
    position: relative;
}

@media (max-width: 767px) {
    .sectionBlock .container .headerBox .contentIntro .factbox {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 45px;
        margin-top: 45px;
        border-bottom: 1px solid #e7eae6;
    }
}

@media (max-width: 480px) {
    .sectionBlock .container .headerBox .contentIntro .factbox {
        margin-bottom: 35px;
        margin-top: 35px;
    }
}

.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper {
    display: block;
    width: 16.66667%;
    top: 0;
    right: 0;
    position: absolute;
    padding-left: 20px;
}

@media (max-width: 767px) {
    .sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper {
        position: relative;
        padding: 0;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }
}

.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .categoryBox {
    color: $old7D7D7D;
    @include serifRegular;
    font-size: 0.75rem;

    line-height: 1;

    margin-bottom: 20px;
}

.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .categoryBox:before {
    content: " ";
    height: 2px;
    display: inline-block;
    width: 30px;
    margin-right: 10px;
    background-color: $green;
    position: relative;
    vertical-align: middle;
}

@media (max-width: 767px) {
    .sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .categoryBox {
        width: 100%;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
}

.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .imageBox {
    display: block;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .imageBox {
        width: 33.33333%;
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }
}

@media (max-width: 767px) {
    .sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .contentBox {
        width: 66.66667%;
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        padding-right: 20px;
    }
}

.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .contentBox .titleContent {
    color: $oldblue;
    @include serifRegular;
    font-size: 16px;

    line-height: 22px;
    margin-bottom: 10px;
}

.sectionBlock .container .headerBox .contentIntro .factbox .factboxWrapper .contentBox .bodyContent {
    color: $oldgray;
    @include serifRegular;
    font-size: 14px;
    line-height: 22px;
    word-break: break-word;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap {
    border-bottom: 3px solid #e7eae6;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading a img {
    width: auto;
}

.article .container .contentArticle .body h1,
.prpratSingle .container .contentArticle .body h1,
.course .container .contentArticle .body h1,
.advertiseSingle .container .contentArticle .body h1,
.vacantPosition .container .contentArticle .body h1,
.article .container .contentArticle .body h2,
.prpratSingle .container .contentArticle .body h2,
.course .container .contentArticle .body h2,
.advertiseSingle .container .contentArticle .body h2,
.vacantPosition .container .contentArticle .body h2,
.article .container .contentArticle .body h3,
.prpratSingle .container .contentArticle .body h3,
.course .container .contentArticle .body h3,
.advertiseSingle .container .contentArticle .body h3,
.vacantPosition .container .contentArticle .body h3,
.article .container .contentArticle .body h4,
.prpratSingle .container .contentArticle .body h4,
.course .container .contentArticle .body h4,
.advertiseSingle .container .contentArticle .body h4,
.vacantPosition .container .contentArticle .body h4,
.article .container .contentArticle .body h5,
.prpratSingle .container .contentArticle .body h5,
.course .container .contentArticle .body h5,
.advertiseSingle .container .contentArticle .body h5,
.vacantPosition .container .contentArticle .body h5,
.article .container .contentArticle .body h6,
.prpratSingle .container .contentArticle .body h6,
.course .container .contentArticle .body h6,
.advertiseSingle .container .contentArticle .body h6,
.vacantPosition .container .contentArticle .body h6 {
    margin-bottom: 25px;
    margin-top: 25px;
}

@media (max-width: 767px) {
    .sectionBlock .listAnyThing .itemBox .blog-card_wrap .blog-card_footerBox {
        word-wrap: break-word;
    }
}

/* KOM-135 - addo social chare on mobile parts: video, stillinger and prprat */

@media (max-width: 767px) {
    .arrow {
        display: none;
    }
    .article .socialNetworkWrapper {
        padding: 40px 0 10px 0 !important;
        text-align: center;
    }
    .article .socialNetworkWrapper,
    .vacantPosition .socialNetworkWrapper,
    .videos .socialNetworkWrapper {
        list-style: none;
        padding: 0;
        margin: 40px 0;
    }
    .article .socialNetworkWrapper li,
    .vacantPosition .socialNetworkWrapper li,
    .videos .socialNetworkWrapper li {
        display: inline-block;
        margin-right: 5px;
    }
    .article .socialNetworkWrapper li div img,
    .vacantPosition .socialNetworkWrapper li div img,
    .videos .socialNetworkWrapper li div img {
        vertical-align: baseline;
    }
}

@media (min-width: 768px) {
    .article .socialNetworkWrapper,
    .vacantPosition .socialNetworkWrapper,
    .videos .socialNetworkWrapper {
        display: none;
    }
}

.videos .button.button-outline-green {
    margin-bottom: 20px;
}

.article .container .contentArticle .body,
.prpratSingle .container .contentArticle .body,
.course .container .contentArticle .body,
.advertiseSingle .container .contentArticle .body,
.vacantPosition .container .contentArticle .body {
    margin-top: 20px;
}

@media (min-width: 791px) {
    .byralistenFrontPageMobile {
        display: none !important;
    }
}

.prpratSingle .body-insertMedia ul.socialNetworkWrapper {
    list-style: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.prpratSingle .body-insertMedia ul.socialNetworkWrapper li {
    margin-right: 8px;
}

/* KOM-153 by michel */

#hostseminaret .list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__tag {
    min-height: 50px;
    max-height: 50px;
    -ms-flex-preferred-size: 70%;
    flex-basis: 70%;
    float: left;
    width: 65%;
}

.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading .agency-img {
    position: absolute;
    top: 35px;
    right: 15px;
}

.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap {
    position: relative;
}

.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading {
    max-width: 65%;
    min-height: 100px;
}

.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    word-break: break-all;
}

.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card {
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
}

@media (max-width: 991px) {
    #hostseminaret .list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading {
        max-width: 60%;
    }
    #hostseminaret .list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__tag {
        width: 60%;
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content;
    }
    #hostseminaret .agency-list.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading .agency-img {
        max-width: 120px;
        max-height: 140px;
    }
}

@media (max-width: 767px) {
    #hostseminaret .agency-list.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 {
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
    }
    .list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .moreContent .readmore {
        margin-top: 20px !important;
    }
}

@media (max-width: 320px) {
    .list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading {
        max-width: 55%;
    }
    #hostseminaret .list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading .agency-img {
        max-width: 100px;
    }
}

/* KOM-153 by Michel */

.list-presenters .agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading {
    min-height: 70px;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 {
    -ms-flex-preferred-size: 80%;
    flex-basis: 80%;
}

/* KOM 160 by Gilmarllen */

.img-bkg-search {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: auto;
    display: inline-block;
    /*min-width: 131px;*/
    min-height: 131px;
    max-width: 220px;
    max-height: 220px;
}

@media (max-width: 576px) {
    .img-bkg-search {
        min-height: 93.33px;
    }
}


/* KOM-162 by michel */

.row-login-buttons div:first-child {
    margin-bottom: 20px;
}

.row-login-buttons div:last-child {
    /* text-align: right; */
}

@media (max-width: 767px) {
    .row-login-buttons div button {
        width: 100%;
    }
}

/* KOM 153 by Michel */

.presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__tag,
.presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading {
    width: 70%;
}

.presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading {
    min-height: 120px;
}

.presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap {
    position: relative;
}

.presenters-image {
    position: absolute;
    top: 13px;
    right: 15px;
}

@media (max-width: 991px) {
    .presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__tag,
    .presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading {
        width: 60%;
    }
    .presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
    .presenters-image {
        max-width: 35%;
    }
    .presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 a {
        word-break: break-word;
    }
}

@media (max-width: 767px) {
    .presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap {
        min-height: 200px;
    }
    .presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 a {
        font-size: 16px;
        line-height: 18px;
    }
    .presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 {
        line-height: 18px;
    }
    .presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading {
        min-height: 60px;
        height: auto;
    }
}

@media (max-width: 425px) {
    .presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap {
        min-height: 100px;
    }
}

/* KOM-178 by michel */

.sectionBlock-1>.container>.headerBox {
    margin-bottom: 20px;
}

.sectionBlock-1 .contactBox p {
    color: $oldgray;
    font-size: 1rem;
    line-height: 26px;
}

/* green bar */

.sectionBlock-1 .textBox p {
    font-size: 1rem;
    line-height: 26px;
    margin-bottom: 20px;
    color: $oldblue;
}

@media (max-width: 767px) {
    .sectionBlock-1 .contactBox {
        width: 100%;
    }
    .sectionBlock-1 .itemColumn {
        padding: 1% 0;
    }
}

/* KOM-179 */

.factbox .factboxWrapper .contentBox .bodyContent p {
    font-family: $sans;
    font-size: 14px;
    line-height: 20px;
}

.factbox .factboxWrapper .contentBox .bodyContent h1,
.factbox .factboxWrapper .contentBox .bodyContent h2,
.factbox .factboxWrapper .contentBox .bodyContent h3,
.factbox .factboxWrapper .contentBox .bodyContent h4,
.factbox .factboxWrapper .contentBox .bodyContent h5,
.factbox .factboxWrapper .contentBox .bodyContent h6 {
    margin: 10px 0 5px 0;
    font-family: $sans;
    font-size: 16px;
    line-height: 20px;

}


.sectionBlock .listAnyThing .itemBox .blog-card_wrap .blog-card_link,
.sectionBlock .listAnyThing .itemBox .subject-material_card__wrap.small-card .subject-material_card__content h3,
.banner-bg_wrap .banner_heading h1,
.article .container .contentArticle .intro h1.title,
.prpratSingle .container .contentArticle .intro h1.title,
.course .container .contentArticle .intro h1.title,
.advertiseSingle .container .contentArticle .intro h1.title,
.vacantPosition .container .contentArticle .intro h1.title,
.membership .membership-wrap .membership-intro p {
    word-break: break-word;
    word-wrap: break-word;
}
.articlePr,
.interview,
.article-single,
.body{
	.modal-body {
		text-align: center;
	}
	.modal-content {
		-webkit-box-shadow: none;
		box-shadow: none;
		border: none;
		border-radius: 0;
		background: transparent;
	}

	.modal-body .modalAccessOpen p a.button {
		margin-bottom: 30px;
	}

	.modal-body .modalAccessOpen h3 {
		margin-bottom: 50px !important;
	}
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 100%;
        max-width: 600px;
    }
}


.listBloggersBox .itemBox .contentBox .contentTitle h2 {
    word-wrap: break-word;
}

.TextBlock .container .headerBox .contentTitle h2 {
    text-align: center;
    margin-bottom: -15px;
}

.calculator_noResondent-text {
    color: #FFF !important;
    @include microfont("sm");
    font-weight: normal;
    font-size: 20px;
}

/* popup on article-blocked - by Michel */

#ModalAccess .modal-dialog p a:not(.button),
#ModalAccessCalculator .modal-dialog p a:not(.button) {
    color: $oldblue;
    border-bottom: 2px solid #d2e288;
    padding-bottom: 5px;
}

#ModalAccess,
#ModalAccessCalculator {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(4%, rgba(255, 255, 255, 0)), color-stop(70%, rgba(255, 255, 255, 0.95)));
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 4%, rgba(255, 255, 255, 0.95) 70%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
    text-align: center;
    position: absolute;
    overflow: hidden;
}

#ModalAccess .modal-content,
#ModalAccessCalculator .modal-content {
    box-shadow: none;
    -webkit-box-shadow: none;
    border: 0;
    border-radius: 0;
    background: none;
}

@media screen and (min-width: 768px) {
    #ModalAccess.modal:before,
    #ModalAccessCalculator.modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
    }
}

#ModalAccess .modal-dialog,
#ModalAccessCalculator .modal-dialog {
    display: inline-block;
    text-align: center;
    vertical-align: bottom;
    /*top: 35%;*/
}

@media (max-width: 767px) {
    #ModalAccess .modal-dialog {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0 !important;
    }
}

.modal-backdrop.in {
    opacity: 0;
}

#ModalAccess .modal-dialog a.button,
#ModalAccessCalculator .modal-dialog a.button {
    margin: 0 10px;
}

#ModalAccess .modal-dialog .modal-body .modalAccessOpen h3,
#ModalAccessCalculator .modal-dialog .modal-body .modalAccessOpen h3 {
    @include serifRegular;
    margin-bottom: 45px;
}

@media (max-width: 475px) {
    #ModalAccess .modal-dialog .modal-body .modalAccessOpen h3,
    #ModalAccessCalculator .modal-dialog .modal-body .modalAccessOpen h3 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
    }
}

#ModalAccess .modal-dialog .modal-body,
#ModalAccessCalculator .modal-dialog .modal-body {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 30px 0;
}

/* KOM-148 - by michel */

.kom-nav.mega-nav {
    z-index: 999999;
}

.modal-backdrop,
#ModalAccess {
    top: 70px;
}

@media (max-width: 767px) {
    .modal-backdrop,
    #ModalAccess {
        top: 62px;
    }
}

@media (max-width: 320px) {
    .modal-backdrop,
    #ModalAccess {
        top: 49px;
    }
}

/*finish Faktabox in intro*/

.instantAccess {
    padding-top: 30px;
}

.instantAccess span:before {
    content: " ";
    display: inline-block;
    margin-right: 5px;
    border-top: 2px solid #ccc;
    width: 20px;
    height: 6px;
}

.instantAccess span:after {
    content: " ";
    display: inline-block;
    margin-left: 5px;
    border-top: 2px solid #ccc;
    width: 20px;
    height: 6px;
}

.instantAccess form {
    padding-bottom: 30px;
}

.instantAccess input[type="email"] {
    width: 79%;
    float: left;
    height: 50px;
}

.instantAccess input[type="submit"] {
    float: left;
    width: 20%;
    min-width: 5%;
    margin-left: 1%;
}

/*@media(max-width: 1199px){
    #ModalAccess .modal-dialog{
        top: 5% !important;
    }
}*/

@media (max-width: 767px) {
    .instantAccess form {
        width: 70%;
        margin: 0 auto;
    }
}

@media (max-width: 576px) {
    /*#ModalAccess .modal-dialog{
          top: 0 !important;
      }*/
    .instantAccess {
        padding-top: 0px;
    }
    .instantAccess span {
        display: none;
    }
    .instantAccess input[type="email"] {
        width: 70%;
        margin: 0 0 1% 0;
    }
    #ModalAccess .signinButton {
        margin-bottom: 5px !important;
    }
    .instantAccess input[type="submit"] {
        width: 29%;
    }
    .instantAccess form {
        height: 80px;
    }
    .instantAccess form {
        width: 100%;
    }
}

@media (max-width: 767px) {
    #ModalAccess {
        background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 1)));
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 1) 50%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 1) 50%);
    }
}

@media (max-width: 576px) {
    #ModalAccess {
        background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, rgba(255, 255, 255, 0)), color-stop(40%, rgba(255, 255, 255, 1)));
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 1) 40%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 1) 40%);
    }
}

/* modal changes same as invision */

/*.modal-open{
    overflow: auto;
}

#ModalAccess{
    background: linear-gradient( to bottom, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 1) 14%);
    background: -moz-linear-gradient( top, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 1) 14%);
    background: -webkit-linear-gradient( top, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 1) 14%);
}

#ModalAccess .modal-dialog{
  vertical-align: top;
  top: 600px;
}

@media(max-width: 1199px){
  #ModalAccess .modal-dialog{
    top: 500px;
  }
}

@media(max-width: 991px){
  #ModalAccess{
    background: linear-gradient( to bottom, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 1) 11%);
    background: -moz-linear-gradient( top, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 1) 11%);
    background: -webkit-linear-gradient( top, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 1) 11%);
  }
}

@media(max-width: 767px){
  #ModalAccess{
    background: linear-gradient( to bottom, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 1) 9%);
    background: -moz-linear-gradient( top, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 1) 9%);
    background: -webkit-linear-gradient( top, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 1) 9%);
  }
  #ModalAccess .modal-dialog{
    top: 400px;
  }
}

@media(max-width: 576px){
  #ModalAccess{
    background: linear-gradient( to bottom, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 1) 8%);
    background: -moz-linear-gradient( top, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 1) 8%);
    background: -webkit-linear-gradient( top, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 1) 8%);
  }
  #ModalAccess .modal-dialog{
    top: 350px;
  }
}

@media(max-width: 475px){
  #ModalAccess{
    background: linear-gradient( to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 4%);
    background: -moz-linear-gradient( top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 4%);
    background: -webkit-linear-gradient( top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 4%);
  }
  #ModalAccess .modal-dialog{
    top: 250px;
  }
}

@media(max-width: 320px){
  #ModalAccess{
    background: linear-gradient( to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 3%);
    background: -moz-linear-gradient( top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 3%);
    background: -webkit-linear-gradient( top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 3%);
  }
  #ModalAccess .modal-dialog{
    top: 200px;
  }
} */

/* hack to block scroll modal in iphones */

@supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    .modal-open {
        overflow: hidden;
        position: fixed;
        width: 100%;
        height: 600px;
    }
    #ModalAccess .modal-dialog {
        top: 150px;
    }
    #ModalAccess {
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(40%, rgba(255, 255, 255, 1)));
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
    }
}

/* KOM-191 by michel */

.article .container .contentArticle .intro,
.prpratSingle .container .contentArticle .intro,
.course .container .contentArticle .intro,
.advertiseSingle .container .contentArticle .intro,
.vacantPosition .container .contentArticle .intro {
    padding-right: 5%;
    padding-left: 5%;
}

@media (max-width: 767px) {
    .banner-bg_wrap .banner_heading {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-flex-basis: 100%;
    }
}

.sectionBlock .listAnyThing .itemBox .subject-material_card__wrap div a img {
    width: 100%;
}

.courses-events .courses-events_wrap .courses-events_cards .courses-events_card .courses-events_card__wrap .courses-events_card__content .diagonalBand {
    -webkit-hyphens: manual;
    -ms-hyphens: manual;
    hyphens: manual;
}

/* IE HACK */

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .mCustomScrollBox {
        width: 100%;
    }
    .mCS_img_loaded {
        max-height: 210px;
    }
    .subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .subject-material_card__content {
        min-height: 180px;
    }
    .subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .card-image,
    .subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .card-video {
        max-height: 210px;
    }
    .subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap {
        max-height: 350px;
    }
    .sectionBlock .listAnyThing .itemBox .subject-material_card__wrap.small-card {
        min-height: 500px;
    }
}

/* KOM-211 by michel */

@media (max-width: 991px) {
    .course .container .contentArticle .priceWrapper .priceItem {
        width: 100%;
        margin-bottom: 20px;
    }
}

.course .container .contentArticle .priceWrapper .priceItem .priceBox .priceValue {
    word-break: break-all;
}

.article .container .contentArticle .header,
.prpratSingle .container .contentArticle .header,
.course .container .contentArticle .header,
.advertiseSingle .container .contentArticle .header,
.vacantPosition .container .header {
    word-break: break-word;
    word-wrap: break-word;
}

@media (max-width: 480px) {
    .courses-events .courses-events_wrap .courses-events_cards .courses-events_card .courses-events_card__wrap .courses-events_card__content .titleContent {
        width: 100%;
        word-break: break-word;
        word-wrap: break-word;
    }
}


.prprat+.sectionBlockContact {
    padding: 0;
}

.prprat+.sectionBlockContact>.container>.textBox {
    margin-top: 0;
}

.icon-table-front {
    background-image: url(../images/Slide_symbol.svg);
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 43%;
    margin-top: 20%;
    cursor: pointer;
}

.show-mobile {
    display: none;
}

.show-desktop {
    display: block;
}

@media (max-width: 767px) {
    .show-mobile {
        display: block;
    }
    .show-desktop {
        display: none;
    }
}

@media (max-width: 576px) {
    .article .imageArticle+.container .contentArticle,
    .prpratSingle .imageArticle+.container .contentArticle,
    .course .imageArticle+.container .contentArticle,
    .advertiseSingle .imageArticle+.container .contentArticle,
    .vacantPosition .imageArticle+.container .contentArticle {
        margin-top: -50px;
    }
}

.byLine a {
    color: $old7D7D7D !important;
}




.icon.iconCalendar:before {
    background: url(../../images/icon_calendar.svg) no-repeat center center;
}

/* ISSUE KOM-250*/

.video-item .item-wrap .img-video .logged-in {
    top: inherit;
    left: inherit;
    bottom: 0;
    right: 0;
    z-index: 2;
}

.video-item .item-wrap .img-video .logged-in {
    top: inherit;
    left: inherit;
    bottom: 0;
    right: 0;
    z-index: 2;
}

.video-item .item-wrap .img-video .logged-in.logged-in-medlem-video span {
    margin: 0px;
}

.video-item .logged-in span:nth-child(2) {
    color: white !important;
}

/****************/

/* ISSUE KOM-247 */

.owl-carousel-fagstoff {
    display: block !important;
}

.owl-carousel-fagstoff .owl-stage-outer {
    overflow: hidden;
}

.owl-carousel-custom-news .owl-dots,
.owl-carousel-membership .owl-dots,
.owl-carousel-fagstoff .owl-dots {
    margin-top: 20px;
    margin-bottom: 50px;
}

.owl-carousel-custom-news .owl-dots .owl-dot,
.owl-carousel-fagstoff .owl-dots .owl-dot {
    background-color: #9cb34b;
}

.owl-carousel-custom-news .owl-dots .owl-dot.active span,
.owl-carousel-fagstoff .owl-dots .owl-dot.active span {
    background: $green;
}

.owl-carousel-fagstoff .owl-dots .owl-dot span {
    margin: 0;
    border-radius: 100px;
    width: 36px;
    height: 4px;
    background-color: #d8ddd8;
}

.owl-carousel-fagstoff .subject-material_card.small-card .subject-material_card__wrap {
    min-height: 423px;
}

.owl-carousel-fagstoff .owl-dots:before {
    clear: all;
    -webkit-column-break-after: always;
    break-after: always;
}

.subject-material .subject-material_wrap .carousel-subject-material_cards .carousel-subject-material_card.small-card {
    max-width: 300px;
}

.owl-carousel-fagstoff .owl-stage-outer .owl-stage {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.owl-carousel-fagstoff .owl-stage-outer .owl-item:first-child {
    width: 300px;
}

.owl-carousel-fagstoff .owl-stage-outer .owl-item:not(:first-child) {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.owl-carousel-fagstoff .owl-nav {
    display: none;
}

@media (min-width: 992px) {
    .hidden-md-up {
        display: none !important;
    }
}

.hidden-all {
    display: none !important;
}

@media (max-width: 991px) {
    .hidden-sm-down {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .owl-carousel-fagstoff .subject-material_card.small-card {
        max-width: 395px !important;
    }
}

/*****************/

/* KS-128 Felipe Branquinho */

.avatar {
    vertical-align: middle;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

/*****************/

/* kom-258 by michel */

/* kom-268 by michel */

@media (max-width: 576px) {
    .sectionBlock>.container>.headerBox {
        margin-bottom: 30px;
    }
}

/* KOM-252  */

.article .container .book-review.contentArticle .body p {
    font-family: $sans;
    font-size: 16px;
}

/* KOM-268 by michel */

.response-frame h5,
.payment-frame h5 {
    font-weight: 400;
}

/*KOM-275*/
article .container .contentArticle .body .embedCourse .course-macro:before {
    background: rgba(35, 62, 77, 0.7) !important;
    mix-blend-mode: multiply;
    content: "" !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
}

/*********/

/*KOM-274*/

.TextBlock .factaboxFormat .w-100 h3 {
    font-family: $sans !important;
}

.fakta-center-hr {
    border-top: 1px solid #f0f3f0;
    padding: 10px 0;
    margin: 0;
    clear: both;
}

/*********/

/* KOM-277 by Michel */

article .container .contentArticle .body .embedCourse a:not(.button) {
    text-decoration: none !important;
}

/* KS-15 by Michel */

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card-with-text .subject-material_card__wrap {
    padding: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #FFFFFF;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card-with-text .subject-material_card__wrap .card-image a img {
    width: 100%;
    max-height: 260px;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card-with-text .subject-material_card__wrap .subject-material_card__content {
    padding: 30px;
}

.subject-material .subject-material_wrap .subject-material_cards {
    margin-bottom: 0;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card-with-text .subject-material_card__wrap .subject-material_card__content h3 a {
    color: $offblack;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card-with-text .subject-material_card__wrap .subject-material_card__content .read-more {
    color: $old7D7D7D;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card-with-text .subject-material_card__wrap {
    /* min-height: 545px; */
    height: 100%;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card-with-text {
    height: auto;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card-with-text .subject-material_card__wrap .subject-material_card__content .logged-in {
    top: -25px;
    left: inherit;
    right: 0;
}

@media (max-width: 767px) {
    .subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card-with-text .subject-material_card__wrap {
        background-color: #FFFFFF !important;
    }
}

@-moz-document url-prefix() {
    .is-sticky .listMenuLinksBlock {
        /* z-index: 99999999 !important; */
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2);
    }
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card-with-text .subject-material_card__wrap .subject-material_card__content .theme-tag {
    color: $old7D7D7D;
}

@media all and (-ms-high-contrast:none) {
    *::-ms-backdrop,
    .subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card-with-text .subject-material_card__wrap {
        display: block;
    }
    /* IE11 */
}

/* KS-16 by Gilmarllen */

.rounded-circle-check {
    display: inline-block;
    padding: 15px 15px;
    border-radius: 50%;
    border: 3px solid #edf8e7;
}

.check-icon {
    width: 48px;
}

#confirmation-box .modal-body {
    padding-top: 40px;
    padding-bottom: 20px;
}

#confirmation-box h4 {
    margin: 40px 0px 40px;
}

#confirmation-box .btn.btn-primary {
    width: 70px;
}

#confirmation-box .modal-dialog {
    max-width: 400px;
}

#confirmation-box .btn-primary {
    background-color: #8cd4f5;
    border-color: #8cd4f5;
}

/* KS-54 */
.rounded-circle-check-failure {
    display: inline-block;
    padding: 15px 15px;
    border-radius: 50%;
    border: 3px solid #f7dfdd;
}

#failure-box .modal-body {
    padding-top: 40px;
    padding-bottom: 20px;
}

#failure-box h4 {
    margin: 40px 0px 40px;
}

#failure-box .btn.btn-primary {
    width: 70px;
}

#failure-box .modal-dialog {
    max-width: 400px;
}

#failure-box .btn-primary {
    background-color: #8cd4f5;
    border-color: #8cd4f5;
}

@media (max-width: 375px) {
    #failure-box {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media (min-width: 576px) {
    #failure-box .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2));
    }
}

#failure-box .modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (.5rem * 2));
}

#failure-box .modal-content {
    width: 100%;
}

#failure-box .modal-dialog {
    margin: 0 auto;
}

#failure-box h4 {
    font-family: $sans !important;
    word-wrap: break-word;
}

/*----MS Edge Browser CSS Start----*/

.contentLoadMore {
    clear: both;
}

/* KS-16 */

@media (max-width: 375px) {
    #confirmation-box {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media (min-width: 576px) {
    #confirmation-box .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2));
    }
}

#confirmation-box .modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (.5rem * 2));
}

#confirmation-box .modal-content {
    width: 100%;
}

#confirmation-box .modal-dialog {
    margin: 0 auto;
}

#confirmation-box h4 {
    font-family: $sans !important;
    word-wrap: break-word;
}

/* KS-15 by Michel */

@media(max-width: 576px) {
    .subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .card-image img {
        width: 100%;
    }
}

/* KS-19 by Michel */

section.TextBlock ul li {
    font-family: $sans !important;
    font-size: 1rem !important;
}

@media all and (-ms-high-contrast:none) {
    *::-ms-backdrop,
    #confirmation-box .modal-dialog {
        margin: 10% auto;
    }
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card:first-child {
    padding-left: 10px;
}

/* KS-47 Open all dropdown */
/*.select2-container--default .select2-results>.select2-results__options {
    max-height: none;
    overflow-y: visible;
}*/

th{
    color: $offblack;
}

/* KS-92 by Michel */
.bannerImage {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.bannerImage a {
    top: 70%;
    position: relative;
}

@-moz-document url-prefix() {
  .agency-list .agency-list_wrap .agency-list_cards .agency-list_card {
    height: auto !important;
  }
}

/* KS-89 by Gilmarllen */
.video-box h1 {
    transition: 0.2s;
}

.video-box:hover h1 {
    color: $green !important;
}

.video-box h1 {
    cursor: pointer;
}

.video-blocked-preview {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
}
.video-blocked-preview img {
    vertical-align: top;
    object-fit: cover;
    object-position: center;
    width: 100%;
}
.video-blocked-preview:after {
    position: absolute;
    content: " ";
    left: 50%;
    top: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
	background: url("../../images/icon/video-play.svg") center center no-repeat;
    vertical-align: middle;
}

.embed-container.video-blocked-box {
    height: auto;
    padding-bottom: 0;
}


.owl-carousel-membership .owl-stage-outer,
.owl-carousel-custom-news .owl-stage-outer{
    overflow: hidden;
}
.owl-carousel-membership .owl-stage-outer .owl-stage,
.owl-carousel-custom-news .owl-stage-outer .owl-stage{
    display: inline-flex;
}
.owl-carousel-custom-news .owl-stage-outer .owl-stage .owl-item .item img{
    width: 100%;
}
.custom-news .subject-material .subject-material_wrap .owl-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: -62px;
    right: -62px;
    top: 45%;
    position: absolute;
    /* width: calc(100% - 10px); */
}

.custom-news .subject-material .subject-material_wrap .owl-nav>div{
    margin: 0 2px;
    background: none;
}
.custom-news .subject-material .subject-material_wrap .owl-nav>.owl-next{
    right: 0;
}
.custom-news .subject-material .subject-material_wrap .owl-nav>.owl-prev{
    left: 0;
}
.custom-news .subject-material .subject-material_wrap .subject-material_cards{
    display: block;
    position: relative;
}
.custom-news .subject-material .subject-material_wrap .second-cards{
    overflow-x: initial;
}

.owl-carousel-custom-news .owl-stage-outer .owl-stage .owl-item .subject-material_card{
    padding-left: 0;
    max-width: 100%;
    flex: 0 0 100%;
    height: 100%;
}
.subject-material .subject-material_wrap .subject-material_cards .subject-material_card .subject-material_card__wrap .subject-material_card__content h3 a{
    word-break: break-word;
}

.contentArea .button.button-secondary{
    margin-top: 20px;
}
.agency-list .agency-list_wrap .agency-list_cards .agency-list_card{
    height: auto;
    align-self: stretch;
    -ms-flex-item-align: stretch;
}

.owl-carousel-custom-news .owl-dots .owl-dot {
    background-color: #d8ddd8;
}

.owl-carousel-custom-news .owl-dots .owl-dot span {
    margin: 0;
    border-radius: 100px;
    width: 36px;
    height: 4px;
    background-color: #d8ddd8;
}

@media(max-width: 576px){
    .custom-news .subject-material .subject-material_wrap .owl-nav{
        display: none;
    }
    .custom-news .subject-material .subject-material_wrap .second-cards{
        /* overflow-x: auto; */
    }
    .custom-news .owl-carousel-fagstoff .owl-dots {
        margin-top: 20px;
        margin-bottom: 50px;
    }
    .custom-news .owl-carousel-fagstoff .owl-dots .owl-dot {
        background-color: #d8ddd8;
    }
    .custom-news .owl-carousel-fagstoff .owl-dots .owl-dot.active span {
        background: $green;
    }


    .table__presenters .table__container table tbody tr.line td:nth-child(5){
        right: 30px;
    }

}

@media(max-width: 767px){
    .custom-news .subject-material .subject-material_wrap .owl-nav{
        display: none;
    }
}


/* KS-80 by Michel */
.seminar-heading h2 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 28px;
    @include serifHeading;
}
.seminar-heading p {
    font-size: 1rem;
    line-height: 26px;
    margin-bottom: 40px;
}

.table__presenters .seminar-heading p,
.presenters .agency-list_wrap .seminar-heading p {
    color: $oldblue;
}

/* [KS-104] Fix agency cards height */
.item.agency-list-carousel_card.agency-list_card{
    min-height: 256px;
    height: 256px;
}

/* [KS-105] XP7 Changes - Figure alignment on HtmlArea */
article .container .contentArticle .body figure.justify,
article .container .contentArticle .body figure.editor-align-justify {
    margin-left: -40%;
    margin-right: -40%;
    overflow: hidden;
}

article .container .contentArticle .body figure.justify img, article .container .contentArticle .body figure.editor-align-justify img {
    width: 100%;
    text-align: justify;
}

article .container .contentArticle .body figure.editor-align-center,
article .container .contentArticle .body figure.center {
    margin-left: -18%;
    margin-right: -18%;
    width: 100% !important;
    overflow: hidden;
}

article .container .contentArticle .body figure.justify figcaption,
article .container .contentArticle .body figure.editor-align-justify figcaption {
    margin-left: 22%;
    margin-right: 22%;
}

@media (max-width: 767px) {
    article .container .contentArticle .body figure.justify figcaption, article .container .contentArticle .body figure.editor-align-justify figcaption {
        margin-left: 0;
        margin-right: 0;
    }
    .contentArticle figure.justify, .contentArticle figure.editor-align-justify {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

article .container .contentArticle .body figure.editor-align-left{
    float: left;
    width: 40%;
    margin: 15px;
}
article .container .contentArticle .body figure.editor-align-left img{
    width: 100%;
    text-align: left;
}

article .container .contentArticle .body figure.editor-align-right{
    float: right;
    width: 40%;
    margin: 15px;
}
article .container .contentArticle .body figure.editor-align-right img{
    width: 100%;
    text-align: right;
}

article .container .contentArticle .body figure.editor-align-center{
    overflow: hidden;
    width: 100%;
    float: none;
    margin: auto;
}
article .container .contentArticle .body figure.editor-align-center img{
    width: 100%;
    text-align: center;
}

/* KS-128 by Michel */
.membership .membership-quote-wrap {
    padding-top: 87px;
    padding-bottom: 37px;
    border-top: 1px solid #E7EAE6;
}

.membership .membership__wrapper{
    display: flex;
    padding: 0 50px;
}
.membership .membership__wrapper .membership__photo{
    margin-right: 35px;
    padding-right: 35px;
    border-right: 2px solid #9cb34b;
}
.membership .membership__wrapper .membership__photo img{
    width: 120px;
    height: 120px;
    object-position: center;
    object-fit: cover;
}
.membership .membership__wrapper .membership__text h3{
    font-style: italic;
    color: $old17293e;
    font-size: 24px;
    margin-top: 0;
    line-height: 30px;
}

.membership .membership__wrapper .membership__text span{
    color: $old7D7D7D;
    @include microfont("sm");
    // font-size: 14px;
}

.membership .owl-carousel-membership .owl-nav{
    display: none;
}

.membership .owl-carousel-membership.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
    border: 2px solid #dbdbdb;
    background: transparent;
}
.membership .owl-carousel-membership.owl-theme .owl-dots .owl-dot span{
    border: 2px solid #9cb34b;
    background: transparent;
}
.membership .membership__wrapper {
    margin: 0 auto;
}

@media(min-width: 1140px){
    .membership .membership__wrapper {
        width: 980px;
    }
}

@media(max-width: 767px){
    .membership .membership__wrapper{
        flex-direction: column;
        width: auto;
    }
    .membership .membership__wrapper .membership__photo{
        padding-right: 0;
        border-right: none;
        padding-bottom: 35px;
        border-bottom: 2px solid #9cb34b;
        margin: 0 auto 35px auto;
    }
    .membership .membership__wrapper .membership__text h3{
        text-align: center;
    }
    .membership .membership__wrapper .membership__text span{
        text-align: center;
        display: block;
    }
}

@media(max-width: 475px){
    .membership .membership__wrapper{
        /* margin: 50px 0; */
        padding: 0;
    }
    .membership .membership__wrapper .membership__text h3{
        font-size: 18px;
        line-height: 24px;
    }
}

/* KS-140 by Michel */
.course .imageCourse{
    display: none;
}
@media(max-width: 767px){
    .course .imageCourse{
        display: block;
    }
    .course .imageCourse+.container{
        margin-top: -50px;
    }
}

/* KS-135 */
.custom-checkbox-margin{
    margin-top: 5px;
    display: flex;
}
.custom-checkbox-margin input{
    margin-top: 0;
    margin-right: 2px;
    height: 18px;
}
.custom-checkbox-margin label p{
    line-height: 160%;
    word-wrap: break-word;
}

.custom-checkbox-margin a {
    text-decoration: underline;
}

/* KS-197 */
#registerEvent label[for="agreedTerms"] {
	margin-top: -1px;
}
#registerEvent label[for="optionalConsent"] {
	margin-top: 3px;
}

/* KS-128 Felipe */
.owl-carousel-custom-news .owl-dots, .owl-carousel-membership .owl-dots, .owl-carousel-fagstoff .owl-dots {
    margin-bottom: 20px;
}

/* KS-196 -- START */
.form-group .row-login-buttons button[name="forgotten"] {
	background: transparent;
	outline: 2px solid white;
}

.form-group .row-login-buttons button[name="forgotten"]:hover {
	background: linear-gradient(180deg, #A2CD38 0%, #88AC2E 100%);
	outline: none;
}
/* KS-196 -- END */



.loginOpen .nav-login {
	flex: 1;
	display: flex !important;
    flex-direction: column;
}

.form-message,
.fail-message {
	font-size: 0.875rem;
	padding: 8px 8px;
    border: 1px solid #A2CD38;
	border-radius: 3px;
	position: relative;
	display: block;
	margin-bottom: 30px;

}
.fail-message {
	font-size: 1.25rem;
	padding-left: 72px;
	min-height: 60px;
}
.fail-message:before {
	content: "!";
	background-color: #fff;
	color: #ff002b;
	font-size: 35px;
	height: 100%;
	display: flex;
	width: 60px;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 0;
	top: 0;
}

.form-message a,
.fail-message a {
	color: inherit;
    text-decoration: underline;
	text-decoration-color: $green;
	text-underline-position: under;
}
.form-message p,
.fail-message p {
    margin: 0;
}
.new-password-row {
	text-align: right;
	display: flex;
	flex-wrap: wrap-reverse;
	gap: 15px;
	margin-bottom: 35px;
	justify-content: space-between;
}
.new-password-row button {
	color: #fff;
    font-size: 0.875rem;
	background-color: transparent;
	border: none;
	text-decoration: underline;
	text-decoration-color: $green;
	text-underline-position: under;
	padding: 0;
}
.form-group + .new-password-row{
	margin-top: -15px;
}

.row-login-buttons .btn-block {
	/* width: 300px;
	min-width: fit-content;
	max-width: 100%; */
	padding-left: 30px;
	padding-right: 30px;
}

.nav-login__load {
	margin: auto;
	display: none;

}

.loader {
	border-top: 1.6em solid #a3cd3780;
    border-right: 1.6em solid #a3cd3780;
    border-bottom: 1.6em solid #a3cd3780;
    border-left: 1.6em solid #A2CD38;
	margin: 0px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load 1.1s infinite linear;
    animation: load 1.1s infinite linear;
    border-radius: 50%;
    width: 149px;
    height: 149px;
}
@keyframes load {
	0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }

  .menu-links {
	display: flex;
	gap: 40px;
	align-items: center;
}
@media (max-width: 991px){
	.menu-links {
		gap: 20px;
	}
}
.menu-links form {
	display: contents;
}

.btn-img {
	vertical-align: middle;
	display: inline-block;
	padding: 0;
	border: none;
	background: transparent;
	margin: 0;
	height: fit-content;
	outline-offset: 3px;
}

.btn-img img {
	display: block;
}
.slick-slider{
	max-width: calc(100vw - 100px);
	margin: auto;
}
@media (max-width: 1280px){
.carouselObjects__carousel .carousel .slick-prev,
.carouselObjects__carousel .carousel .slick-next {
    right: -45px;
}
}

.courses-events_card:focus-visible {
	outline-offset: 2px;
}

@media (max-width: 374px) {
	.menu-links{
		gap: 20px;
	}
}

.button.button-outline-blue {
	outline-offset: 3px;
}

.owl-theme .owl-nav .owl-next,
.owl-theme .owl-nav .owl-prev {
	background-size: contain;
	aspect-ratio: 1/1;
	background-color: transparent!important;
	background-repeat: no-repeat!important;
	background-size: 16px!important;
	background-position: 46% center!important;
	min-width: 52px;
	min-height: 52px;
	padding: 0;
	border: 2px solid #0B1C08;
	border-radius: 30px;
	@media (max-width: 767px) {
		min-width: 36px;
		min-height: 36px;
		background-size: 11px!important;
	}
}
.owl-theme .owl-nav .owl-next {
	background-image: url("../../images/carousel_arrow_right.svg")!important;
	background-position: right 43% center!important;
}
.owl-theme .owl-nav .owl-prev {
	background-image: url("../../images/carousel_arrow_left.svg")!important;
}

.app-cookie-line.mod-light { // ks-229
	background-color: #fff!important;
	z-index: 1000;
}
.img-responsive {
	width: 100%;
	object-fit: scale-down;
}
.bg-blue,
.bg-dark {
	.owl-theme .owl-nav .owl-prev ,
	.owl-theme .owl-nav .owl-next {
		filter: brightness(0) saturate(100%) invert(94%) sepia(22%) saturate(165%) hue-rotate(29deg) brightness(97%) contrast(96%);
	}
}
