.course-instructor {
	margin-block: 60px;

	@media (max-width: 991px) {
		margin-top: 36px;
	}

	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;
		border: 2px solid $offblack;
		border-radius: 12px;
		padding: 32px;

		@media (max-width: 991px) {
			padding: 24px;
			gap: 16px;
		}

		.course-instructor>& {
			margin-inline: 0;
		}
	}

	&__title {
		.h4 {
			margin: 0;
		}
	}

	&__content {
		display: flex;
		align-items: center;
		gap: 48px;

		@media (max-width: 767px) {
			flex-direction: column;
			gap: 16px;
		}

		figure {
			aspect-ratio: 240/250;
			width: 100%;
			flex-shrink: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}

			@media (min-width: 768px) {
				max-width: 240px;
			}

			@media (max-width: 767px) {
				aspect-ratio: 280/250;
			}

			.course-instructor>.course-instructor__wrapper>& {
				margin-block: 0;
			}
		}

		.course-instructor>.course-instructor__wrapper>& {
			.text-block-sm {
				padding-left: 0;
			}
		}
	}
}
