.event {
	margin-bottom: 120px;

	@media (max-width: 991px) {
		margin-bottom: 60px;
	}

	&__content {
		padding-block: 60px;

		@media (max-width: 991px) {
			padding-block: 36px;
		}

		.text-block-sm {
			// padding-inline: 16px;

			@media (min-width: 992px) {
				padding-inline: 52px 0;
			}
		}

		.col-md-8.col-md-offset-1.col-lg-6.col-lg-offset-2 {

			.col-negative,
			table,
			.course-instructor,
			.embedArticle,
			.embedBook {
				@media (min-width: 992px) {
					margin-right: calc((-25% - 19px) + 52px); // (negative 1 offset MD) + internal margin
				}

				@media (min-width: 1200px) {
					margin-right: calc((-33.3% - 26px) + 52px); // (negative 2 offset LG) + internal margin
				}

			}
		}
	}

	&__intro {
		@include fontBody(intro2);
		margin-bottom: 60px;
		padding-inline: 16px;

		@media (max-width: 991px) {
			margin-bottom: 36px;
		}

		@media (min-width: 992px) {
			padding-inline: 52px 0;
		}
	}

	&__parts-region {
		.col-md-10.col-lg-8.col-md-offset-1.col-lg-offset-2 {
			@media (min-width: 992px) {
				padding-inline: 64px;
			}
		}

		[data-portal-region="main"] {
			margin-top: calc(120px - 60px);
			margin-bottom: 0;

			@media (max-width: 991px) {
				margin-top: calc(60px - 36px);
			}
		}
	}
}

.event-header {
	&__image {
		min-width: 100%;
		height: 526px;

		@media (max-width: 1199px) {
			margin-inline: calc(50% - 50vw);
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__content-wrapper {
		display: flex;
		flex-direction: column;
		gap: 60px;
		padding: 48px 64px;
		background-color: white;
		margin-top: -152px;

		@media (max-width: 1199px) {
			margin-inline: -12px;
		}

		@media (max-width: 991px) {
			margin: 0;
			margin-top: -48px;
			padding: 36px 16px;
			gap: 36px;
		}

		@media (max-width: 767px) {
			// margin-inline: 16px;
		}
	}

	&__title {
		display: flex;
		flex-direction: column;
		gap: 24px;

		.tags {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 8px;

			.tag {
				display: block;
				padding: 8px 16px;
				@include microfont(sm);
				border-radius: 50px;
				background-color: $lightgreen;
				text-transform: uppercase;
			}
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			display: flex;
			align-items: flex-start;
			gap: 12px;
			@include fontBody(sm);

			&::before {
				content: "";
				display: block;
				flex-shrink: 0;
				width: 24px;
				height: 24px;
				background-position: center;
				background-size: 100%;
				background-repeat: no-repeat;
			}

			&.date {
				&::before {
					background-image: url("../../images/icon/new-calendar.svg");
				}
			}

			&.location {
				&::before {
					background-image: url("../../images/icon/new-map-pin.svg");
				}
			}

			&.price {
				&::before {
					background-image: url("../../images/icon/price-tag.svg");
				}
			}
		}
	}
}

[data-portal-region="main"] {
	&:has([data-portal-component-type="layout"].event) {
		[data-portal-component-type="part"] {
			&:has(.program) {
				margin-block: 120px;

				@media (max-width: 991px) {
					margin-block: 60px;
				}
			}

			.program {
				margin-block: 0;
			}

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		&:has(+footer.footer) {
			padding-bottom: 120px;

			@media (max-width: 991px) {
				padding-bottom: 60px;
			}
		}
	}
}
