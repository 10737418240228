body {
	#CookiebotWidget .CookiebotWidget-consent-details button,
    #CookiebotWidget {
        font-family: inherit;
		color: $offblack;
    }
    #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-change {
		@include button-primary();
		&:disabled:hover {
			background-color: transparent;
		}
    }
    #CookiebotWidget #CookiebotWidget-buttons #CookiebotWidget-btn-withdraw {
		@include button-secondary();
    }
	#CookiebotWidget #CookiebotWidget-buttons,
	#CookiebotWidget .CookiebotWidget-header {
		border-color: $green;
	}
	#CookiebotWidget #CookiebotWidget-widgetContent {
		background-color: $lightgreen;

	}
	#CookiebotWidget .CookiebotWidgetSpinner {
		border-color: $offblack;
	}
	#CookiebotWidget .CookiebotWidget-logo svg circle {
		fill: $offblack;
	}
	#CookiebotWidget .CookiebotWidget-logo svg path {
		fill: $lightgreen;
	}
    #CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg,
	#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-consents-list li.CookiebotWidget-approved svg {
        fill: $green;
    }
}
