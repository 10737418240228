.login-section {
	padding-block: 100px 150px;
	text-align: center;

	@media (max-width: 768px) {
		padding-block: 72px 80px;
	}

	* {
		text-align: inherit;
	}

	h1 {
		@include heading(d1);
		margin-top: 0;
		margin-bottom: 64px;

		@media (max-width: 768px) {
			margin-bottom: 32px;
		}
	}

	&.login-loading {
		h1 {
			margin-bottom: 0;
		}
	}

	&__card {
		display: flex;
		flex-direction: column;
		gap: 48px;
		background-color: $theme-blue;
		border-radius: 12px;
		margin-bottom: 80px;
		padding: 80px 40px;

		@media (max-width: 768px) {
			padding: 40px 16px;
			margin-bottom: 48px;
		}

		.intro {
			@include fontBody(lg);
			margin: 0;
		}

		.input-group {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			max-width: 273px;

			label {
				font-weight: 400;
				margin-bottom: 4px;
			}

			input {
				padding: 12px 24px;
				border-radius: 999px;
				min-width: 0;
				display: block;
				width: 100%;
				border: 2px solid $offblack;

				@media (max-width: 768px) {
					padding: 8px 14px;
				}
			}
		}

		fieldset {
			display: flex;
			justify-content: center;
			gap: 24px;
			align-items: flex-end;

			@media (max-width: 992px) {
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			.otp-wrapper & {
				@media (max-width: 1440px) {
					flex-direction: column;
					align-items: center;
					justify-content: center;
				}
			}

			.sms-inputs {
				display: flex;
				gap: 8px;

				input {
					min-width: 0px;
					width: 56px;
					height: 60px;
					border-radius: 16px;
					border: 2px solid $offblack;

					@media (max-width: 768px) {
						width: 48px;
						height: 56px;
					}

					@media (max-width: 575px) {
						width: 40px;
						height: 48px;
					}
				}
			}

			button {
				padding: 12px 24px;
				background-color: $offblack;
				border: 2px solid $offblack;
				border-radius: 999px;
				color: $green;

				@media (max-width: 992px) {
					padding: 8px 14px;
					width: 100%;
					max-width: 273px;
				}
			}
		}

		.error-wrapper & {
			padding: 80px 80px;

			@media (max-width: 768px) {
				padding: 40px 16px;
				margin-bottom: 48px;
			}
		}

		a {
			color: inherit;
			text-decoration: underline;
		}
	}

	&__info {
		button {
			border: none;
			padding: 0;
			text-decoration: underline;
			background-color: transparent;
		}

		a,
		button {
			color: inherit;
			text-decoration: underline;
		}
	}

	&__loading {
		padding-block: 226px 242px;

		@media (max-width: 992px) {
			padding-block: 150px;
		}
	}
}
