// $base-rem: 16px;

@function -to-rem($value) {
  // Check if the value is a number
  @if type-of($value) != 'number' {
    @warn inspect($value) + ' was passed to rem-calc(), which is not a number.';
    @return $value;
  }
  // Calculate rem if units for $value is not rem or em
  @if unit($value) != 'rem' {
    $value: ($value) / 16 * 1rem;
  }

  // Turn 0rem into 0
  @if $value == 0rem {
    $value: 0;
  }

  @return $value;
}
