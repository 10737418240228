$offblack: #0B1C08;
$green: #99DB57;
$lightgreen: #E8F2D9;
$offwhite: #F3F3F3;
$lightyellow: #F5F0D9;

$danger: #AF1B1B;

$theme-green:#C7F3BE;
$theme-yellow:#F5ECBC;
$theme-purple:#EBD4F7;
$theme-blue:#BCECF5;

$theme-green--light:$lightgreen;
$theme-yellow--light:#FDFAEE;
$theme-purple--light:#F7EEFC;
$theme-blue--light: #DDF5FA;
