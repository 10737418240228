// old colors
$oldblue: $offblack;
$oldgray: $offblack;
$old003C4C: $offblack;
$old7D7D7D: $offblack;
$old42414b: $offblack;
$old777876: $offblack;
$old999: $offblack;
$old333: $offblack;
$old161616: $offblack;
$old323335: $offblack;
$old17293e: $offblack;
$oldblack:  $offblack;


* {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}

html {
	font-size: 100%;
}

body {
	font-family: $sans;
	font-weight: normal;
	color: $offblack;
}

a {
	text-decoration: none;
}

a:hover,
a:focus {
	text-decoration: none;
}
h1{
	margin: 0;
}
/* Input, Text-Area */

/* Link Wrap */
.linkWrap {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

/* Icons */
.fb:before {
	background-image: url("../../images/facebook_social.svg");
}

.twitter:before {
	background-image: url("../../images/twitter_social.svg");
}

.insta:before {
	background-image: url("../../images/Instagram_social.svg");
}

.snap:before {
	background-image: url("../../images/Snap_social.svg");
}

.ytube:before {
	background-image: url("../../images/YT_social.svg");
}

.ytube:before {
	background-image: url("../../images/YT_social.svg");
}

.flicr:before {
	background-image: url("../../images/Flicr_social.svg");
}

.linkedin:before {
	background-image: url("../../images/LinkedIn_social.svg");
}

.bg-blue .fb:before {
	background-image: url("../../images/facebook_social_grey.svg");
}

.bg-blue .twitter:before {
	background-image: url("../../images/twitter_social_grey.svg");
}

.bg-blue .insta:before {
	background-image: url("../../images/Instagram_social_grey.svg");
}

.bg-blue .snap:before {
	background-image: url("../../images/Snap_social_grey.svg");
}

.bg-blue .ytube:before {
	background-image: url("../../images/YT_social_grey.svg");
}

.bg-blue .ytube:before {
	background-image: url("../../images/YT_social_grey.svg");
}

.bg-blue .flicr:before {
	background-image: url("../../images/Flicr_social_grey.svg");
}

.bg-blue .linkedin:before {
	background-image: url("../../images/LinkedIn_social_grey.svg");
}

/* Background Colors */
.bg-gray {
	background-color: #F0F3F0;
	color: $oldgray;
}

.bg-gray h1,
.bg-gray h2,
.bg-gray h3,
.bg-gray h4,
.bg-gray h5,
.bg-gray h6 {
	color: $oldblue;
}

.bg-gray p {
	color: $oldgray;
}

.bg-blue {
	background-color: $old003C4C;
	color: white;
}



.bg-white {
	background-color: white;
	color: $oldgray;
}

.bg-white h1,
.bg-white h2,
.bg-white h3,
.bg-white h4,
.bg-white h5,
.bg-white h6 {
	color: $oldblue;
}

.bg-white p {
	color: $oldgray;
}

/* Container sizes */


/* Sections */
/* Embed video */
.embed-responsive-3by2 {
	padding-bottom: 66.5%;
}

/* Custom link */
a.customLink {
	border-bottom: 3px solid transparent;
	display: inline-block;
	color: $oldblue;
	font-family: $sans;
	font-size: 1rem;
	font-weight: 500;
	line-height: 22px;
}

a.customLink:hover,
a.customLink:focus,
a.customLink:active {
	border-bottom: 3px solid $green;
}

a.customLink.active {
	border-bottom: 3px solid $green;
}

.icon {
	display: inline-block;
	position: relative;
	margin-bottom: 15px;
}

.icon.iconCalendar {
	padding-left: 40px;
	margin-bottom: 0;
}

.icon.iconCalendar:before {
	content: " ";
	position: absolute;
	left: 0;
	top: -5px;
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../../images/icon_calendar.svg") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

.icon.iconAvailable {
	padding-left: 40px;
	padding-right: 15px;
}

.icon.iconAvailable:before {
	content: " ";
	position: absolute;
	left: 0;
	top: -5px;
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../../images/icon_available.svg") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

.icon.iconMap2 {
	padding-left: 40px;
	padding-right: 15px;
}

.icon.iconMap2:before {
	content: " ";
	position: absolute;
	left: 0;
	top: -5px;
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../../images/icon_place_map.svg") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

.icon.iconPrice {
	padding-left: 40px;
	padding-right: 15px;
}

.icon.iconPrice:before {
	content: " ";
	position: absolute;
	left: 0;
	top: -5px;
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../../images/icon_price.svg") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

.icon.iconPhoneBlue {
	padding-left: 0px;
	padding-right: 0px;
}

.icon.iconPhoneBlue:before {
	content: " ";
	position: relative;
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../../images/icon_phone_blue.svg") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

.icon.iconPhoneGrey {
	padding-left: 0px;
	padding-right: 0px;
}

.icon.iconPhoneGrey:before {
	content: " ";
	position: relative;
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../../images/icon_phone_grey.svg") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

.icon.iconMailBlue {
	padding-left: 0px;
	padding-right: 0px;
}

.icon.iconMailBlue:before {
	content: " ";
	position: relative;
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../../images/icon_mail_blue.svg") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

.icon.iconMailGrey {
	padding-left: 0px;
	padding-right: 0px;
}

.icon.iconMailGrey:before {
	content: " ";
	position: relative;
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../../images/icon_mail_grey.svg") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

.icon.iconPlaceMapBlue {
	padding-left: 0px;
	padding-right: 0px;
}

.icon.iconPlaceMapBlue:before {
	content: " ";
	position: relative;
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../../images/icon_place_map_blue.svg") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

.icon.iconPlaceMapGrey {
	padding-left: 0px;
	padding-right: 0px;
}

.icon.iconPlaceMapGrey:before {
	content: " ";
	position: relative;
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../../images/icon_place_map_grey.svg") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

.icon.iconTwitterGreen {
	padding-left: 0px;
	padding-right: 0px;
	margin-bottom: 0px;
	margin-right: 10px;
}

.icon.iconTwitterGreen:before {
	content: " ";
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
	background: url("../../images/icon_twitter_person.svg") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

.icon.iconMailGreen {
	padding-left: 0px;
	padding-right: 0px;
	margin-bottom: 0px;
	margin-right: 10px;
}

.icon.iconMailGreen:before {
	content: " ";
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
	background: url("../../images/icon_mail_person.svg") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

.icon.iconLinkedinGreen {
	padding-left: 0px;
	padding-right: 0px;
	margin-bottom: 0px;
	margin-right: 10px;
}

.icon.iconLinkedinGreen:before {
	content: " ";
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
	background: url("../../images/icon_linkedin_person.svg") no-repeat center center;
	background-size: contain;
	vertical-align: middle;
}

/* SOCIAL NETWORK */
.socialNetworkFixed {
	display: block;
	padding: 0px;
	margin: 0px;
	position: fixed;
	z-index: 99999;
	padding: 20px 10px 0 0;
	border-radius: 3px;
	top: 30%;
}

.socialNetworkFixed .arrow {
	display: none;
}

@media (max-width: 980px) {
	.socialNetworkFixed {
		top: 30%;
	}
}

@media (max-width: 767px) {
	.socialNetworkFixed {
		display: none;
	}
}

.socialNetworkFixed .socialNetworkWrapper {
	padding-left: 10px;
	list-style: none;
}

.socialNetworkFixed .socialNetworkWrapper li {
	margin-bottom: 10px;
}

@media (max-width: 480px) {
	.socialNetworkFixed .socialNetworkWrapper li {
		display: inline-block;
		margin: 5px;
	}
}

.socialNetworkFixed .socialNetworkWrapper li a {
	text-decoration: none;
}

.socialNetworkFixed .socialNetworkWrapper li a span.icon {
	display: inline-block;
}

@media (max-width: 480px) {
	.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconFacebook {
		margin-bottom: 0;
	}
}

.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconFacebook:before {
	content: " ";
	display: inline-block;
	width: 36px;
	height: 36px;
	background: url("../../images/icon_facebook.svg") center center no-repeat;
	background-size: contain;
	vertical-align: middle;
}

.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconFacebook:hover:before,
.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconFacebook:active:before {
	background: url("../../images/icon_facebook_active.svg") center center no-repeat;
}

.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconFacebook:focus:before {
	background: url("../../images/icon_facebook_pressed.svg") center center no-repeat;
}

.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconTwitter:before {
	content: " ";
	display: inline-block;
	width: 36px;
	height: 36px;
	background: url("../../images/icon_twitter.svg") center center no-repeat;
	background-size: contain;
	vertical-align: middle;
}

.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconTwitter:hover:before,
.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconTwitter:active:before {
	background: url("../../images/icon_twitter_active.svg") center center no-repeat;
}

.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconTwitter:focus:before {
	background: url("../../images/icon_twitter_pressed.svg") center center no-repeat;
}

.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconLinkedin:before {
	content: " ";
	display: inline-block;
	width: 36px;
	height: 36px;
	background: url("../../images/icon_linkedin.svg") center center no-repeat;
	background-size: contain;
	vertical-align: middle;
}

.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconLinkedin:hover:before,
.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconLinkedin:active:before {
	background: url("../../images/icon_linkedin_active.svg") center center no-repeat;
}

.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconLinkedin:focus:before {
	background: url("../../images/icon_linkedin_pressed.svg") center center no-repeat;
}

.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconMail:before {
	content: " ";
	display: inline-block;
	width: 36px;
	height: 36px;
	background: url("../../images/icon_mail.svg") center center no-repeat;
	background-size: contain;
	vertical-align: middle;
}

.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconMail:hover:before,
.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconMail:active:before {
	background: url("../../images/icon_mail_active.svg") center center no-repeat;
}

.socialNetworkFixed .socialNetworkWrapper li a span.icon.iconMail:focus:before {
	background: url("../../images/icon_mail_pressed.svg") center center no-repeat;
}


/* Nav */



nav.kom-nav>.container .menu-links {
	margin-left: auto;
}

nav.kom-nav>.container .menu-links>a,
nav.kom-nav>.container .menu-links>span {
	margin-left: 40px;
}

@media (max-width: 991px) {

	nav.kom-nav>.container .menu-links>a,
	nav.kom-nav>.container .menu-links>span {
		margin-left: 20px;
	}
}

@media (max-width: 374px) {

	nav.kom-nav>.container .menu-links>a,
	nav.kom-nav>.container .menu-links>span {
		margin-left: 10px;
	}
}

nav.kom-nav>.container .menu-links .search-link {
	cursor: pointer;
}

@media (max-width: 374px) {
	nav.kom-nav>.container .menu-links .search-link img {
		width: 18px;
	}
}

nav.kom-nav>.container .menu-links .login-icon {
	cursor: pointer;
}

nav.kom-nav>.container .menu-links .login-icon span {
	font-size: 0.875rem;
	font-weight: 500;
	line-height: 22px;
	color: $oldblue;
	vertical-align: bottom;
	margin-right: 10.5px;
}

@media (max-width: 767px) {
	nav.kom-nav>.container .menu-links .login-icon span {
		display: none;
	}
}

@media (min-width: 768px) {
	nav.kom-nav>.container .menu-links .login-icon {
		display: none;
	}
}

@media (min-width: 768px) {
	nav.kom-nav>.container .menu-links .login-icon img {
		display: none;
	}
}

@media (max-width: 374px) {
	nav.kom-nav>.container .menu-links .login-icon img {
		width: 15px;
	}
}

nav.kom-nav>.container .menu-links .logged a {
	color: #293f4e;
	font-size: 14px;
}

nav.kom-nav>.container .menu-links .logged a img {
	margin-left: 10px;
}

@media (max-width: 767px) {
	nav.kom-nav>.container .menu-links .logged {
		display: none;
	}
}

nav.kom-nav>.container .menu-links .menu-button-toggle {
	cursor: pointer;
}

nav.kom-nav>.container .menu-links .menu-button-toggle span {
	content: "";
	background-image: url("../../images/interface-more-menu-order.svg");
	display: block;
	width: 30px;
	height: 30px;
	position: relative;
	top: 0px;
}

@media (max-width: 374px) {
	nav.kom-nav>.container .menu-links .menu-button-toggle span {
		width: 20px;
		height: 20px;
		background-size: 20px;
	}
}

nav.kom-nav>.container .menu-links .menu-button-toggle span:hover,
nav.kom-nav>.container .menu-links .menu-button-toggle span:focus {
	background-image: url("../../images/menu-toggle-hover.svg");
}

nav.kom-nav>.container .menu-links .menu-button-toggle img:nth-child(2) {
	display: none;
}

// .nav-items,
// .nav-search,
// .nav-login {
// 	display: none;
// }

// .kom-nav.mega-nav {
// 	background-color: $old003C4C;
// 	color: white;
// 	position: fixed;
// 	overflow: scroll;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	left: 0;
// 	z-index: 999;
// 	.button-header img,
// 	.main-header__logo img {
// 		filter: invert(1) brightness(100);
// 	}
// }

// .kom-nav.mega-nav::-webkit-scrollbar {
// 	display: none;
// }

// @media (max-width: 767px) {
// 	.kom-nav.mega-nav .menu-brand {
// 		display: none;
// 	}
// }


// .kom-nav.mega-nav .menu-links .menu-button-toggle {
// 	cursor: pointer;
// }

// .kom-nav.mega-nav .menu-links .menu-button-toggle img:first-child {
// 	display: none;
// }

// .kom-nav.mega-nav .menu-links .menu-button-toggle img:nth-child(2) {
// 	display: inline-block;
// 	position: relative;
// 	top: 9px;
// }

// @media (max-width: 374px) {
// 	.kom-nav.mega-nav .menu-links .menu-button-toggle img:nth-child(2) {
// 		width: 20px;
// 		height: 20px;
// 	}
// }

// .kom-nav.mega-nav .menu-links .menu-button-toggle span {
// 	display: none;
// }

// .kom-nav.mega-nav .menu-links .menu-button-toggle span:hover,
// .kom-nav.mega-nav .menu-links .menu-button-toggle span:focus {
// 	display: none;
// }

// .kom-nav.mega-nav .nav-items {
// 	display: block;
// }


// .kom-nav.mega-nav .nav-items .nav-items-wrap>ul li.item-middle a {
// 	font-weight: 700;
// }

// .header-invert {
// 	background-color: $old003C4C;
// }

// .header-invert .button.button-outline-blue {
// 	color: #FFF;
// 	border: 2px solid #FFFFFF;
// }

// .header-invert .search-link img {
// 	width: 30px;
// 	height: auto;
// }

// @media (max-width: 767px) {
// 	.header-invert .login-icon span {
// 		color: #FFFFFF;
// 	}
// }

// .header-invert .menu-button-toggle img {
// 	width: 30px;
// 	height: auto;
// }

// .header-invert .menu-button-toggle span {
// 	background-image: url("../../images/interface-more-menu-order-white.svg") !important;
// }

// .header-invert .logged a {
// 	color: #FFFFFF !important;
// }

.socialIcon img:last-child {
	display: none;
}

.socialIcon:hover>img:first-child {
	display: none;
}

.socialIcon:hover>img:last-child {
	display: block !important;
}

/* Banner Area */
.banner-area .banner-image {
	position: relative;
}

.banner-area .banner-image img {
	width: 100%;
}

.banner-area .banner-image:before {
	opacity: 0.5;
	content: "";
	background: $offblack;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
}

.banner-area .banner-bg {
	min-height: 560px;
	background: url("../../images/banner-frontpage.jpg") no-repeat center;
	background-size: 100% 560px;
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: flex-end;
	align-items: flex-end;
	max-width: 1376px;
	margin: 0 auto;
	background-size: cover;
}

@media (max-width: 767px) {
	.banner-area .banner-bg {
		background: none !important;
	}

	.banner-area .banner-bg:before {
		background: none !important;
	}
}

.banner-area .banner-bg:before {
	opacity: 0.5;
	content: "";
	background: $offblack;
	background-size: 1440px 560px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
}

.banner-bg_wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: flex-end;
	align-items: flex-end;
}

@media (max-width: 767px) {
	.banner-bg_wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}

.banner-bg_wrap .banner_heading {
	position: relative;
	-webkit-flex-basis: 41.667%;
	flex-basis: 41.667%;
	margin-bottom: 40px;
}

.banner-bg_wrap .banner_heading .theme-tag {
	@include microfont("sm");
	// font-size: 0.75rem;


	color: #D2E288;
	margin-bottom: 10px;
	display: inline-block;
}

@media (max-width: 767px) {
	.banner-bg_wrap .banner_heading .theme-tag {
		color: $oldgray;
	}
}

.banner-bg_wrap .banner_heading h1 {
	margin-top: 0;
}

.banner-bg_wrap .banner_heading h1 a {
	font-size: 36px;
	font-weight: 700;
	line-height: 40px;
	color: white;
}

@media (max-width: 767px) {
	.banner-bg_wrap .banner_heading h1 a {
		font-size: 1.625rem;
		line-height: 30px;
		color: $oldblue;
	}
}

.banner-bg_wrap .banner_heading h1 a:hover {
	color: #bad255;
}

.banner-bg_wrap .banner_heading .read-more {
	@include microfont("sm");
	// font-size: 0.75rem;
	font-weight: 400;


	color: white;
}

@media (max-width: 767px) {
	.banner-bg_wrap .banner_heading .read-more {
		color: $oldgray;
	}
}

.banner-bg_wrap .banner_heading .read-more:before {
	content: " ";
	height: 2px;
	display: inline-block;
	width: 30px;
	margin-right: 10px;
	background-color: $green;
	position: relative;
	vertical-align: middle;
}

.banner-bg_wrap .banner-list {
	margin-left: auto;
	-webkit-flex-basis: 25%;
	flex-basis: 25%;
	background-color: #F6F9F6;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	border: 1px solid #E7EAE6;
	margin-bottom: 40px;
	position: relative;
	z-index: 2;
}

@media (max-width: 991px) {
	.banner-bg_wrap .banner-list {
		-webkit-flex-basis: 50%;
		flex-basis: 50%;
	}
}

@media (max-width: 767px) {
	.banner-bg_wrap .banner-list {
		border: 0;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		margin-left: -20px;
		margin-right: -20px;
		-webkit-flex-basis: 115%;
		flex-basis: 115%;
	}
}

.banner-bg_wrap .banner-list .banner-list_wrap .banner-list_title {
	position: relative;
	font-family: $sans;
	font-size: 1.125rem;
	font-weight: 500;
	color: $oldblue;
	margin: 20px 20px 9px 20px;
	display: inline-block;
}

.banner-bg_wrap .banner-list .banner-list_wrap .banner-list_title::before {
	content: "";
	width: 3px;
	height: 24px;
	display: inline-block;
	background-color: #88AC2E;
	top: 6px;
	left: 0;
	position: relative;
	margin-right: 7px;
}

.banner-bg_wrap .banner-list .banner-list_wrap ul {
	padding-left: 0;
	margin-left: 0;
	list-style-type: none;
	margin-bottom: 0;
}

.banner-bg_wrap .banner-list .banner-list_wrap ul li {
	position: relative;
	padding: 20px;
	border-top: 1px solid #E7EAE6;
}

.banner-bg_wrap .banner-list .banner-list_wrap ul li:hover {
	background-color: #f1f3f0;
}

.banner-bg_wrap .banner-list .banner-list_wrap ul li:hover .banner-list_link {
	color: $old42414b;
	border-bottom: 1px solid #A2CD38;
}

.banner-bg_wrap .banner-list .banner-list_wrap ul li a .theme-tag {
	@include microfont("sm");
	// font-size: 0.75rem;



	color: $old7D7D7D;
	margin-bottom: 10px;
	display: block;
}

.banner-bg_wrap .banner-list .banner-list_wrap ul li .banner-list_link {
	@include serifRegular;
	font-size: 1rem;
	font-weight: 700;
	line-height: 20px;
	color: $oldblack;
}

.bannerHeader .bannerImage img {
	width: 100%;
	height: auto;
}

.bannerHeader .bannerBox:before {
	background: $offblack !important;
}

.bannerHeader .bannerBox.bannerHome .banner-bg_wrap {
	width: 100%;
}

.bannerHeader .bannerBox.bannerHome .banner-bg_wrap .banner-list {
	flex-basis: 32%;
	-webkit-flex-basis: 32%;
}

@media(max-width: 991px) {
	.bannerHeader .bannerBox.bannerHome .banner-bg_wrap .banner-list {
		flex-basis: 50%;
		-webkit-flex-basis: 50%;
	}
}

@media(max-width: 767px) {
	.bannerHeader .bannerBox.bannerHome .banner-bg_wrap .banner-list {
		flex-basis: 115%;
		-webkit-flex-basis: 115%;
	}
}

.bannerHeader .bannerBox.bannerHome .container {
	display: flex;
	align-items: flex-end !important;
}

@media(max-width: 767px) {
	.bannerHeader .bannerBox.bannerHome {
		position: inherit !important;
		padding-top: 20px;
	}

	.bannerHeader .bannerBox.bannerHome:before {
		background: none !important;
	}
}

.searchOpen {
	background-color: $old003C4C;
	color: white;
	position: fixed;
	overflow: scroll;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10000;
}

.searchOpen::-webkit-scrollbar {
	display: none;
}

.searchOpen.searchOpenPage {
	position: relative;
}

@media (max-width: 767px) {
	.searchOpen .menu-brand {
		display: none;
	}
}


.searchOpen .menu-button-toggle img:nth-child(2) {
	display: inline-block !important;
	position: relative;
}

@media (max-width: 374px) {
	.searchOpen .menu-button-toggle img:nth-child(2) {
		width: 20px;
		height: 20px;
		top: 3px;
	}
}

.searchOpen .menu-button-toggle span {
	visibility: hidden;
	display: none !important;
}

.searchOpen .menu-button-toggle span:hover,
.searchOpen .menu-button-toggle span:focus {
	visibility: hidden;
	display: none !important;
}

.searchOpen .nav-search {
	display: block !important;
}

.searchOpen .nav-search .nav-search_wrap .form-group {
	margin-bottom: 80px;
}

.loginOpen .nav-login {
	display: block !important;
}

.loginOpen .nav-login .nav-login_wrap .form-group {
	margin-bottom: 30px;
}

.loginOpen .nav-login .nav-login_wrap .form-group button.button-green,
.loginOpen .nav-login .nav-login_wrap .form-group button.button-outline-green {
	min-width: inherit;
}

@media (max-width: 767px) {

	.loginOpen .nav-login .nav-login_wrap .form-group button.button-green,
	.loginOpen .nav-login .nav-login_wrap .form-group button.button-outline-green {
		font-size: 0.688rem;
		line-height: 11px;
	}
}

.loginOpen .nav-login .nav-login_wrap .form-group>label {
	color: $green;
	@include microfont("sm");
	// font-size: 0.75rem;
	font-weight: 400;



	margin-bottom: 18px;
}

.loginOpen .nav-login .nav-login_wrap .membership {
	font-family: $sans;
	font-size: 0.875rem;
	line-height: 22px;
	color: white;
}

@media (max-width: 767px) {
	.loginOpen .nav-login .nav-login_wrap .membership {
		font-size: 0.813rem;
		line-height: 21px;
	}
}

.loginOpen .nav-login .nav-login_wrap .membership a {
	color: white;
	border-bottom: 3px solid $green;
}

.loginOpen .nav-login .nav-login_wrap .membership a:hover {
	border: 0;
}



/* Courses and Events */
.courses-events .courses-events_wrap {
	padding-top: 80px;
	padding-bottom: 80px;
	text-align: center;
}

.courses-events .courses-events_wrap h2 {
	font-size: 1.5rem;
	line-height: 28px;
	@include serifHeading;
}

.courses-events .courses-events_wrap p {
	line-height: 26px;
	margin-bottom: 50px;
}

.courses-events .courses-events_wrap .courses-events_header {
	display: block;
	margin-bottom: 50px;
}

.courses-events .courses-events_wrap .courses-events_header>p {
	margin-bottom: 50px;
}

.courses-events .courses-events_wrap .courses-events_cards {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
	margin-bottom: 30px;
}

.courses-events .courses-events_wrap .courses-events_cards .courses-events_card {
	position: relative;
	padding: 10px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 50%;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

@media (max-width: 991px) {
	.courses-events .courses-events_wrap .courses-events_cards .courses-events_card {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

.courses-events .courses-events_wrap .courses-events_cards .courses-events_card .linkWrap {
	z-index: 2;
}

.courses-events .courses-events_wrap .courses-events_cards .courses-events_card .courses-events_card__wrap {
	position: relative;
	overflow: hidden;
	border-radius: 3px;
	text-align: left;
}

.courses-events .courses-events_wrap .courses-events_cards .courses-events_card .courses-events_card__wrap:before {
	background: rgba(35, 62, 77, 0.7) !important;
	mix-blend-mode: multiply;
	content: "" !important;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 0;
}

.courses-events .courses-events_wrap .courses-events_cards .courses-events_card .courses-events_card__wrap .courses-events_card__content {
	position: relative;
	z-index: 2;
	padding: 20px;
	min-height: 220px;
}

.courses-events .courses-events_wrap .courses-events_cards .courses-events_card .courses-events_card__wrap .courses-events_card__content .diagonalBand {
	position: absolute;
	top: 20px;
	right: -27px;
	height: 20px;
	width: 140px;
	transform: rotate(393deg);
	color: #FFFFFF;
	@include microfont("sm");
	// font-size: 0.75rem;


	text-align: center;
	background-color: $green;

	padding: 3px 25px;
}

.courses-events .courses-events_wrap .courses-events_cards .courses-events_card .courses-events_card__wrap .courses-events_card__content .dateContent {
	color: #D2E288;
	@include microfont("sm");
	// font-size: 0.75rem;


	padding-bottom: 20px;
	width: 54%;
}

@media (max-width: 480px) {
	.courses-events .courses-events_wrap .courses-events_cards .courses-events_card .courses-events_card__wrap .courses-events_card__content .dateContent {
		width: 80%;
	}
}

.courses-events .courses-events_wrap .courses-events_cards .courses-events_card .courses-events_card__wrap .courses-events_card__content .titleContent {
	margin-bottom: 20px;
	width: 54%;
	min-height: 104px;
}

.courses-events .courses-events_wrap .courses-events_cards .courses-events_card a .courses-events_card__wrap .courses-events_card__content .titleContent {
	color: white;
	font-family: $sans;
	font-size: 18px;

	line-height: 24px;
}

.courses-events .courses-events_wrap .courses-events_cards .courses-events_card a .courses-events_card__wrap .courses-events_card__content .titleContent:hover {
	color: white;
}

@media (max-width: 480px) {
	.courses-events .courses-events_wrap .courses-events_cards .courses-events_card .courses-events_card__wrap .courses-events_card__content .titleContent {
		width: 100%;
	}

	.courses-events .courses-events_wrap .courses-events_cards .courses-events_card a .courses-events_card__wrap .courses-events_card__content .titleContent {
		font-size: 16px;
		line-height: 20px;
	}
}

.courses-events .courses-events_wrap .courses-events_cards .courses-events_card .courses-events_card__wrap .courses-events_card__content .locationContent {
	color: #FFFFFF;
	font-family: $sans;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	width: 100%;
}

@media (max-width: 480px) {
	.courses-events .courses-events_wrap .courses-events_cards .courses-events_card .courses-events_card__wrap .courses-events_card__content .locationContent {
		width: 100%;
	}
}

.courses-events .courses-events_wrap .courses-events_cards .courses-events_card .courses-events_card__wrap .courses-events_card__content .locationContent:before {
	content: " ";
	display: inline-block;
	width: 20px;
	height: 20px;
	background: url("../../images/icon_map.svg") center center no-repeat;
	background-size: contain;
	vertical-align: middle;
	margin-right: 5px;
}

/* Membership */
.membership .membership-wrap {
	padding-top: 87px;
	padding-bottom: 119px;
	text-align: center;
	border-top: 1px solid #E7EAE6;
}

.membership .membership-wrap h2 {
	@include serifHeading;
	font-size: 1.5rem;
	line-height: 28px;
}

.membership .membership-wrap .membership-intro {
	margin-bottom: 36px;
}

.membership .membership-wrap .membership-intro p {
	@include serifHeading;
	font-size: 1.5rem;
	line-height: 28px;
}

@media (max-width: 767px) {
	.membership .membership-wrap .membership-intro p {
		font-size: 1.25rem;
		line-height: 24px;
	}
}

.membership .membership-wrap .membership-intro p {
	font-family: $sans;
}

/* Subject Material */
.subject-material .subject-material_wrap {
	padding-top: 50px;
	padding-bottom: 50px;
	text-align: center;
}

.subject-material .subject-material_wrap h2 {
	@include serifHeading;
	font-size: 1.5rem;
	line-height: 28px;
}

.subject-material .subject-material_wrap p {
	line-height: 26px;
	margin-bottom: 40px;
}

.subject-material .subject-material_wrap .first-cards {
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.subject-material .subject-material_wrap .second-cards {
	overflow-x: auto;
}

@media (max-width: 991px) {
	.subject-material .subject-material_wrap .second-cards {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
}

.subject-material .subject-material_wrap .mCSB_container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.subject-material .subject-material_wrap .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	background-color: $green;
	height: 3px;
}

.subject-material .subject-material_wrap .mCSB_scrollTools .mCSB_draggerRail {
	height: 3px;
	margin: 6px 0;
	background-color: #d8ddd8;
}

.subject-material .subject-material_wrap .subject-material_cards {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -10px;
	margin-bottom: 30px;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card {
	position: relative;
	padding: 10px;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card:first-child {
	padding-left: 0;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 50%;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card img.img-play {
	position: absolute;
	top: 45%;
	left: 45%;
	z-index: 1;
}

@media (max-width: 767px) {
	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card:nth-child(2) {
		padding-left: 0;
	}
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card .subject-material_card__wrap {
	min-height: 413px;
}

@media (min-width: 992px) and (max-width: 1199px) {
	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card .subject-material_card__wrap {
		min-height: 306px;
	}
}

@media (max-width: 991px) {
	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card .subject-material_card__wrap {
		min-height: 233px;
	}
}

@media (max-width: 767px) {
	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card .subject-material_card__wrap {
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 0;
		background: none !important;
		margin-bottom: 30px;
	}

	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card .subject-material_card__wrap:hover {
		background: transparent !important;
	}
}

@media (max-width: 767px) {
	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card .subject-material_card__wrap .subject-material_card__content .logged-in {
		top: 20px;
		left: 20px;
	}

	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card .subject-material_card__wrap .subject-material_card__content .theme-tag {
		color: $oldgray;
	}

	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card .subject-material_card__wrap .subject-material_card__content h3 a {
		color: $oldblue;
	}

	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card .subject-material_card__wrap .subject-material_card__content .read-more {
		color: $oldgray;
	}
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card .subject-material_card__wrap .subject-material_card__content .subject-material_card__image {
	margin-bottom: 20px;
	position: relative;
}

@media (min-width: 800px) {
	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card .subject-material_card__wrap .subject-material_card__content .subject-material_card__image:hover:before {
		background: rgba(66, 65, 75, 0.35) !important;
		mix-blend-mode: multiply;
		content: "" !important;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
	}
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.big-card .subject-material_card__wrap .subject-material_card__content .subject-material_card__image img {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	width: 100%;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 25%;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

@media (max-width: 991px) {
	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 300px;
		-ms-flex: 0 0 300px;
		flex: 0 0 300px;
		max-width: 300px;
	}
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap {
	background-color: white;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	padding: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	border-bottom: 3px solid transparent;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap:hover {
	border-bottom: 3px solid $green;
	background: white !important;
	-webkit-border-radius: 3px 3px 0 0;
	-moz-border-radius: 3px 3px 0 0;
	border-radius: 3px 3px 0 0;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .card-image,
.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .card-video {
	position: relative;
}

@media (min-width: 800px) {

	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .card-image a:hover:before,
	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .card-video a:hover:before {
		background: rgba(66, 65, 75, 0.2) !important;
		mix-blend-mode: multiply;
		content: "" !important;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
	}
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .card-image img,
.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .card-video img {
	max-width: 100%;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .card-image .logged-in,
.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .card-video .logged-in {
	top: inherit;
	left: inherit;
	bottom: 0;
	right: 0;
	z-index: 2;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .card-video:after {
	content: " ";
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 3;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	width: 64px;
	height: 64px;
	background: url("../../images/icon/video-play.svg") center center no-repeat;
	vertical-align: middle;
	cursor: pointer;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .subject-material_card__content {
	padding: 20px;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .subject-material_card__content .theme-tag {
	color: $old7D7D7D;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .subject-material_card__content:hover h3 a {
	color: $old42414b;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .subject-material_card__content h3 {
	margin-bottom: 78px;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .subject-material_card__content h3 a {
	color: $oldblack;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card.small-card .subject-material_card__wrap .subject-material_card__content .read-more {
	color: $old7D7D7D;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card .subject-material_card__wrap {
	position: relative;
	overflow: hidden;
	border-radius: 3px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 20px;
}

@media (min-width: 800px) {
	.subject-material .subject-material_wrap .subject-material_cards .subject-material_card .subject-material_card__wrap:hover:before {
		background: rgba(66, 65, 75, 0.35) !important;
		mix-blend-mode: multiply;
		content: "" !important;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 0;
	}
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card .subject-material_card__wrap .subject-material_card__content {
	text-align: left;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	position: relative;
	justify-content: flex-end;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card .subject-material_card__wrap .subject-material_card__content:hover h3 a {
	color: #dce59c;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card .subject-material_card__wrap .subject-material_card__content .theme-tag {
	@include microfont("sm");
	// font-size: 0.75rem;



	color: #D2E288;
	margin-bottom: 11px;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card .subject-material_card__wrap .subject-material_card__content h3 {
	margin-top: 0;
	margin-bottom: 20px;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card .subject-material_card__wrap .subject-material_card__content h3 a {
	font-size: 1.25rem;
	line-height: 24px;
	color: white;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card .subject-material_card__wrap .subject-material_card__content .read-more {
	@include microfont("sm");
	// font-size: 0.75rem;
	font-weight: 400;



	color: white;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card .subject-material_card__wrap .subject-material_card__content .read-more:before {
	content: " ";
	background: url("../../images/icon_line.svg") center center no-repeat;
	background-size: contain;
	display: inline-block;
	width: 30px;
	height: 20px;
	margin-right: 10px;
	position: relative;
	vertical-align: middle;
}

.subject-material .subject-material_wrap .subject-material_cards .subject-material_card .subject-material_card__wrap .subject-material_card__content .read-more:hover:before {
	background: url("../../images/icon_arrow_right_active.svg") center center no-repeat;
	width: 35px;
}

.logged-in {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
}

.logged-in span:first-child {
}

.logged-in span:first-child img {
	background-color: $green;
	width: -to-rem(27);
	height: -to-rem(27);
	display: block;
	object-fit: contain;
	padding: 3px;
}

.logged-in span:nth-child(2) {
	background-color: $old003C4C;
	@include microfont("sm");
	// font-size: 0.75rem;

	line-height: -to-rem(27);

	display: inline-block;
	color: white;
	width: 70px;
	height:  -to-rem(27);
}


/* Agency List */
.agency-list .agency-list_wrap {
	text-align: center;
	/* padding-top: 80px; */
	padding-bottom: 80px;
}

.agency-list .agency-list_wrap.agency-list_carousel {
	padding-top: 80px;
}

.agency-list_wrap h2 {
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 28px;
	@include serifHeading;
}

.agency-list_wrap p {
	font-size: 1rem;
	line-height: 26px;
	margin-bottom: 40px;
}

.agency-list .agency-list_wrap .agency-list_cards {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	/* margin-left: -10px;
  	margin-right: -10px; */
	margin-bottom: 30px;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card {
	height: 217px;
	padding: 10px;
	-webkit-box-flex: 0;
	/* -webkit-flex: 0 0 50%; */
	/* -ms-flex: 0 0 50%; */
	/* flex: 0 0 50%; */
	width: calc(50% - 20px);
	/* Half the container minus the padding of the element */
	max-width: 50%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.agency-list .agency-list_wrap .agency-list_cards .owl-item .agency-list-carousel_card.agency-list_card {
	width: 100%;
}

@media (max-width: 767px) {
	.agency-list .agency-list_wrap .agency-list_cards .agency-list_card {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		height: auto;
	}
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap {
	border: 1px solid #E7EAE6;
	border-radius: 3px;
	background-color: white;
	text-align: left;
	display: flex;
	flex-direction: column;
	padding: 20px 20px 10px 20px;
	width: 100%;
	border-bottom: 3px solid transparent;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .readmore {
	bottom: 0;
	order: 5;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .readmore .iconPlus {
	margin-bottom: 0;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .readmore .iconPlus:before {
	content: " ";
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../../images/icon_plus.svg") center center no-repeat;
	background-size: contain;
	vertical-align: middle;
	margin-right: 5px;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap a {
	cursor: pointer;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .moreContent {
	color: $oldblue;
	background: #fff;
	width: 100%;
	border-top: none;
	transition: 0.4s ease;
	display: none;
	order: 4;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .moreContent p {
	margin-bottom: 10px;
	color: $oldblue;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .moreContent span {
	font-size: 13px;
	color: $oldgray;
	display: block;
	margin: 10px 0;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .moreContent a {
	color: $oldblue;
	border-bottom: 3px $green solid;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .moreContent .readmore {
	position: relative;
	display: block;
	margin-top: 50px !important;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .moreContent .readmore .icon:before {
	transform: rotate(-45deg);
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap:hover {
	border-bottom: 3px solid $green;
	-webkit-border-radius: 3px 3px 0 0;
	-moz-border-radius: 3px 3px 0 0;
	border-radius: 3px 3px 0 0;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__tag {
	@include microfont("sm");
	// font-size: 0.75rem;


	color: $old7D7D7D;

	margin-bottom: 11px;
	order: 1;
	flex-grow: 2;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 16px;
	order: 2;
	flex-grow: 2;
}

@media (max-width: 767px) {
	.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		height: 100%;
		display: block;
	}
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 {
	margin-top: 0;
	margin-right: auto;
	margin-bottom: 0;
	flex-basis: 55%;
}

@media (max-width: 767px) {
	.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 {
		-webkit-flex-basis: 100%;
		flex-basis: 100%;
	}
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 a {
	font-family: $sans;
	font-size: 1.5rem;
	line-height: 30px;
	color: $oldblue;
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading a {
	flex-basis: 50%;
}

@media (max-width: 767px) {
	.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading a:nth-child(2) {
		display: block;
		width: 100%;
		text-align: center;
	}
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading a img {
	margin-top: 8px;
	max-width: 70%;
	float: right;
	max-height: 100px;
}

@media (max-width: 767px) {
	.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading a img {
		float: none;
	}
}

.agency-list .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__link {
	padding-bottom: 5px;
	display: block;
	width: 100%;
	border-bottom: 1px solid #DADEDA;
	margin-bottom: 15px;
	font-family: $sans;
	font-size: 0.813rem;
	line-height: 20px;
	color: $oldgray;
	order: 3;
}

/* PRprat */
.prprat {
	display: block;
}


/* Section Block */
.sectionBlock {
	padding-top: 50px;
	padding-bottom: 50px;
}

.sectionBlock .socialNetworkBox .snbTitle {
	color: $old7D7D7D;
	@include microfont("sm");
	// font-size: 0.75rem;

	line-height: 0.75rem;
	text-align: center;
	margin-bottom: 20px;
}

.sectionBlock .socialNetworkBox+.footerBox {
	margin-top: 50px;
}

.sectionBlock .textBox {
	padding: 0 16.5%;
	margin-top: 50px;
}

@media (max-width: 767px) {
	.sectionBlock .textBox {
		padding: 0;
	}
}

.sectionBlock .headerBox .contentTitle {
	@include serifHeading;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 28px;
	text-align: center;
	margin-bottom: 20px;
}

.sectionBlock .headerBox .contentTitle>* {
	text-align: center;
	margin: 0;
}

.sectionBlock .headerBox .contentTitle h1,
.sectionBlock .headerBox .contentTitle h2,
.sectionBlock .headerBox .contentTitle h3,
.sectionBlock .headerBox .contentTitle h4,
.sectionBlock .headerBox .contentTitle h5,
.sectionBlock .headerBox .contentTitle h6 {
	@include serifHeading;
}

.sectionBlock .headerBox .contentIntro {
	color: $oldgray;
	font-family: $sans;
	font-size: 1rem;
	line-height: 26px;
	text-align: center;
	padding: 0px 25%;
}

.sectionBlock .headerBox .contentIntro h3 {
	@include serifHeading;
}

@media (max-width: 767px) {
	.sectionBlock .headerBox .contentIntro {
		padding: 0px;
	}
}

.sectionBlock .headerBox .contentIntro>* {
	color: $oldgray;
	font-family: $sans;
	text-align: center;
}

.sectionBlock .selectBox {
	display: block;
	text-align: center;
}

.sectionBlock .selectBox select {
	font-weight: 500;
	background: transparent;
	color: $oldgray;
	border: 1px solid #DADEDA;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	padding: 18.5px 10px;
	min-width: 194px;
}

.sectionBlock .listBlogBox .headerBox {
	margin-bottom: 15px;
}

.sectionBlock .listBlogBox .headerBox:after {
	display: none;
}

.sectionBlock .listBlogBox .contentBox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
	margin-bottom: 30px;
}


.sectionBlock .listBlogBox .footerBox {
	text-align: center;
}

.sectionBlock.rmBottomSpace {
	padding-bottom: 0px;
}

.sectionBlock.rmTopSpace {
	padding-top: 0px;
}

.sectionBlock.rmAllSpace {
	padding-bottom: 0px;
	padding-top: 0px;
}

/* list Bloggers Box */
.listBloggersBox .itemBox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 60px 0px;
}

.listBloggersBox .itemBox:first-child {
	padding: 0px;
}

@media (max-width: 767px) {
	.listBloggersBox .itemBox {
		padding: 30px 0px;
	}
}

.listBloggersBox .itemBox .imageBox {
	width: 50%;
}

@media (max-width: 767px) {
	.listBloggersBox .itemBox .imageBox {
		width: 100%;
		margin-bottom: 20px;
	}
}

.listBloggersBox .itemBox .contentBox {
	align-self: center;
	width: 50%;
	padding: 2% 8%;
}

@media (max-width: 767px) {
	.listBloggersBox .itemBox .contentBox {
		width: 100%;
		padding: 0;
	}
}

.listBloggersBox .itemBox .contentBox .contentTitle {
	margin-bottom: 20px;
}

.listBloggersBox .itemBox .contentBox .contentTitle h2 {
	@include serifHeading;
	font-size: 1.75rem;

	line-height: 34px;
	color: $oldblue;
	margin: 0;
	padding: 0;
}

.listBloggersBox .itemBox .contentBox .contentIntro P {
	color: $oldblue;
	font-family: $sans;
	font-size: 1rem;
	line-height: 26px;
}

.listBloggersBox .itemBox .contentBox .readmoreContent {
	color: $old7D7D7D;
	@include microfont("sm");
	// font-size: 0.75rem;


	font-weight: normal;
}

.listBloggersBox .itemBox .contentBox .readmoreContent:before {
	content: " ";
	height: 20px;
	display: inline-block;
	width: 30px;
	margin-right: 10px;
	background-color: $green;
	position: relative;
	vertical-align: middle;
	background: url("../../images/icon_line.svg") center center no-repeat;
	background-size: contain;
}

.listBloggersBox .itemBox .contentBox .readmoreContent:hover:before,
.listBloggersBox .itemBox .contentBox .readmoreContent:active:before {
	background: url("../../images/icon_arrow_right_active.svg") center center no-repeat;
	background-size: contain;
}

.listBloggersBox .itemBox .contentBox .readmoreContent:hover:focus {
	background-size: contain;
}

@media (max-width: 480px) {
	.listBloggersBox .itemBox .contentBox .readmoreContent {
		font-size: 10px;
		line-height: 10px;
	}
}

.listBloggersBox .itemBox.LTR .imageBox {
	order: 1;
}

.listBloggersBox .itemBox.LTR .contentBox {
	order: 2;
}

.listBloggersBox .itemBox.RTL .imageBox {
	order: 2;
}

@media (max-width: 767px) {
	.listBloggersBox .itemBox.RTL .imageBox {
		order: 1;
	}
}

.listBloggersBox .itemBox.RTL .contentBox {
	order: 1;
}

@media (max-width: 767px) {
	.listBloggersBox .itemBox.RTL .contentBox {
		order: 2;
	}
}

/* CHECKBOX E RADIO BUTTON */
form .checkbox label:after,
form .radio label:after {
	content: "";
	display: table;
	clear: both;
}

form .checkbox .cr,
form .radio .cr {
	position: relative;
	display: inline-block;
	border: 1px solid #a9a9a9;
	border-radius: 0.25em;
	width: 20px;
	height: 20px;
	float: left;
	margin-right: 0.5em;
	background-color: #FFFFFF;
}

form .radio .cr {
	border-radius: 50%;
}

form .checkbox .cr .cr-icon,
form .radio .cr .cr-icon {
	position: absolute;
	font-size: 0.75rem;
	line-height: 0;
	top: 50%;
	left: 20%;
}

form .radio .cr .cr-icon {
	margin-left: 0.04em;
}

form .checkbox label input[type="checkbox"],
form .radio label input[type="radio"] {
	opacity: 0;
	position: absolute;
}

form .checkbox label input[type="checkbox"]+.cr>.cr-icon,
form .radio label input[type="radio"]+.cr>.cr-icon {
	transform: scale(3) rotateZ(-20deg);
	opacity: 0;
	transition: all 0.3s ease-in;
}

form .checkbox label input[type="checkbox"]:checked+.cr>.cr-icon,
form .radio label input[type="radio"]:checked+.cr>.cr-icon {
	transform: scale(1) rotateZ(0deg);
	opacity: 1;
}

form .checkbox label input[type="checkbox"]:disabled+.cr,
form .radio label input[type="radio"]:disabled+.cr {
	opacity: 0.5;
}

form .checkbox label,
form .radio label {
	padding-left: 10px;
}

/* book List */
.mobileImageCover {
	display: none;
}

.related-books .book-list_wrap {
	text-align: center;
	padding-top: 20px;
	padding-bottom: 40px;
}

.related-books .book-list_wrap h2 {
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 28px;
	@include serifHeading;
}

.related-books .book-list_wrap p {
	font-size: 1rem;
	line-height: 26px;
	margin-bottom: 40px;
}

.related-books .book-list_wrap .book-list_cards {
	display: flex;
	flex-wrap: wrap;
	margin: 40px 0;
}

.related-books .book-list_wrap .embedClosed {
	height: 210px;
}

.related-books .book-list_wrap .embedBook {
	display: flex;
	text-align: left;
	flex-direction: row;
	flex-wrap: wrap;
	border: 1px solid #E7EAE6;
	border-bottom: 3px solid #E7EAE6;
	border-radius: 3px;
	margin: 10px;
	height: 210px;
	padding: 20px;
	background: #FFFFFF;
	width: calc(50% - 20px);
	position: relative;
	transition: 0.3s linear;
	overflow: hidden;
}

.related-books .book-list_wrap .embedBook:hover,
.related-books .book-list_wrap .embedBook:active,
.related-books .book-list_wrap .embedBook:focus {
	background: #f6f9f6;
	border-bottom: 3px solid $green;
	padding: 20px;
}

.related-books .book-list_wrap .embedBook .contentBox {
	width: 66.66667%;
	min-height: 160px;
	position: relative;
}

@media (max-width: 480px) {
	.related-books .book-list_wrap .embedBook .contentBox {
		padding-right: 10px;
	}
}

.related-books .book-list_wrap .embedBook .contentBox .categoryContent {
	color: $old7D7D7D;
	@include microfont("sm");
	// font-size: 0.75rem;



	margin-bottom: 15px;
}

@media (max-width: 480px) {
	.related-books .book-list_wrap .embedBook .contentBox .categoryContent {
		font-size: 10px;
		line-height: 10px;
		margin-bottom: 10px;
	}
}

.related-books .book-list_wrap .embedBook .contentBox .titleContent {
	color: $oldblue;
	font-family: $sans;
	font-size: 24px;

	line-height: 30px;
	margin-bottom: 50px;
}

@media (max-width: 480px) {
	.related-books .book-list_wrap .embedBook .contentBox .titleContent {
		font-size: 16px;
		line-height: 20px;
	}
}

.related-books .book-list_wrap .embedBook .contentBox .readmore {
	position: absolute;
	bottom: 0px;
}

.related-books .book-list_wrap .embedBook .contentBox .readmore .iconPlus:before {
	content: " ";
	display: inline-block;
	width: 30px;
	height: 30px;
	background: url("../../images/icon_plus.svg") center center no-repeat;
	background-size: contain;
	vertical-align: middle;
	margin-right: 5px;
}

.related-books .book-list_wrap .embedBook .imageBox {
	width: 33.33333%;
	position: relative;
	z-index: 1;
}

.related-books .book-list_wrap .embedBook .imageBox>img {
	float: right;
}

.related-books .book-list_wrap .embedBook a {
	cursor: pointer;
}

.related-books .book-list_wrap .embedBook .moreContent {
	/* opacity: 0; */
	display: none;
	padding-bottom: 70px;
	color: $oldblue;
	background: #fff;
	padding: 0;
	position: relative;
	left: -1px;
	width: 100%;
	width: calc(100% + 2px);
	z-index: 0;
	border-top: none;
	transition: 0.4s ease;
	/* max-height: 0px; */
	overflow: hidden;
}

.related-books .book-list_wrap .embedBook .moreContent p {
	margin-bottom: 10px;
	color: $oldblue;
}

.related-books .book-list_wrap .embedBook .moreContent span {
	font-size: 13px;
	color: $oldgray;
	display: block;
	margin: 10px 0;
}

.related-books .book-list_wrap .embedBook .moreContent a {
	color: $oldblue;
	border-bottom: 3px $green solid;
}

.related-books .book-list_wrap .embedBook .moreContent .readmore {
	position: relative;
	display: block;
	margin-top: 20px;
}

.related-books .book-list_wrap .embedBook .moreContent .readmore .icon:before {
	transform: rotate(-45deg);
}

.related-books .book-list_wrap .embedBook.expanded {
	background: #fff;
	position: relative;
	height: auto;
	z-index: 10;
}

.related-books .book-list_wrap .embedBook.expanded .readmore {
	display: none;
}

.related-books .book-list_wrap .embedBook.expanded .moreContent {
	display: block;
	opacity: 1;
	max-height: 3100px;
	width: 100%;
	padding: 40px 20px 0px 20px;
}

.related-books .book-list_wrap .embedBook.expanded .titleContent {
	margin-bottom: 0px;
}

.related-books .book-list_wrap .embedBook.expanded .moreContent .readmore {
	display: block;
}

@media screen and (max-width: 500px) {
	.book-review .row {
		margin: 0;
	}

	.book-review .description-info {
		text-align: justify;
	}

	.book-review .description-info div,
	.book-review .description-info picture {
		padding: 0;
		max-width: 100%;
		width: 100%;
	}

	.book-review .description-info picture {
		display: none;
		margin-bottom: 20px;
		text-align: center;
	}

	.book-review .mobileImageCover {
		text-align: center;
		display: block;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 767px) {
	.related-books .book-list_wrap .embedBook {
		width: calc(100%);
	}
}

@media screen and (min-width: 380px) and (max-width: 500px) {
	.related-books .book-list_wrap .embedBook .moreContent {
		padding: 45px 20px 20px;
	}
}

.videos{
	padding-top: 100px;
	padding-bottom: 40px;
}



.videos .video-box span,
.embed-videos .video-box span {
	font-size: 0.75rem;
	color: $green;
}

.videos .video-box h1,
.embed-videos .video-box h1 {
	font-size: 28px;
	color: #fff;
	line-height: 1.1em;
	margin: 10px 0;
}

.videos .video-description,
.embed-videos .video-description {
	margin-bottom: 70px;
	font-size: 14px;
}

.videos .container .socialNetwork,
.embed-videos .container .socialNetwork {
	margin: 0;
	left: 0;
}

.videos .container .socialNetwork .socialNetworkWrapper,
.embed-videos .container .socialNetwork .socialNetworkWrapper {
	position: relative;
}

.videos .container .socialNetwork .socialNetworkWrapper li,
.embed-videos .container .socialNetwork .socialNetworkWrapper li {
	display: inline-block;
	margin-right: 10px;
}

@media (max-width: 767px) {

	.videos .container .socialNetwork,
	.embed-videos .container .socialNetwork {
		padding: 0;
	}

	.videos .container .socialNetwork .socialNetworkWrapper,
	.embed-videos .container .socialNetwork .socialNetworkWrapper {
		text-align: left;
	}
}


.linha-separacao {
	width: 100%;
	height: 1px;
	background: #E7EAE6;
	opacity: 0.2;
	display: block;
	margin: 0;
}


.embed-videos .button {
	text-align: center;
	float: none;
	margin-bottom: 40px;
}

.embed-videos .sectionBlock {
	padding: 0;
}

.embed-videos .sectionBlock .selectBox {
	margin-bottom: -40px;
	margin-top: 30px;
}

.embed-videos .sectionBlock .selectBox select {
	background: #fff;
}

@media (max-width: 767px) {
	.embed-videos .sectionBlock .selectBox {
		margin-bottom: 30px;
	}
}

.embed-videos .videos-wrap {
	width: 100%;
	margin: 0;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.embed-videos .video-item {
	margin: 30px 0;
	padding: 10px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 33.333%;
	-ms-flex: 0 0 33.333%;
	flex: 0 0 33.333%;
	max-width: 33.333%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

@media (max-width: 767px) {
	.embed-videos .video-item {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

.embed-videos .video-item .item-wrap {
	width: 100%;
	position: relative;
}

.embed-videos .video-item .img-video {
	width: 100%;
	position: relative;
}

.embed-videos .video-item .img-video img {
	width: 100%;
	max-width: 100%;
}

.embed-videos .video-item .img-video a:after {
	content: " ";
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 3;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	width: 64px;
	height: 64px;
	background: url("../../images/icon/video-play.svg") center center no-repeat;
	background-size: -to-rem(40);
	vertical-align: middle;
	cursor: pointer;
}

.embed-videos .video-item span {
	color: $green;
	font-size: 0.75rem;
	margin: 20px 0 15px;
	display: block;
}

.embed-videos .video-item h3 {
	font-size: 20px;
	color: #fff;
}

.embed-videos .video-item a {
	color: #fff;
	transition: 0.2s;
}

.embed-videos .video-item p {
	font-size: 14px;
}

.embed-videos .video-item:hover a {
	color: $green;
}

.embed-videos .button-container {
	margin-top: 40px;
}

.bottom-box {
	margin-top: 30px;
	margin-bottom: 70px;
	width: 100%;
	border-top: 1px solid rgba(231, 234, 230, 0.2);
	padding: 10px 0;
}

.bottom-box a {
	color: #fff;
	text-decoration: underline;
	opacity: 0.5;
	display: inline-block;
	margin-right: 10px;
	font-size: 13px;
}

@media (max-width: 980px) {
	.bottom-box {
		margin-bottom: 20px;
	}
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	margin-bottom: 50px;
	height: 0;
	overflow: hidden;
	max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.bg-white.embed-videos {
	margin-top: 0;
}

.bg-white.embed-videos .video-item a {
	color: $green;
}

.bg-white.embed-videos .video-item a:hover {
	color: $old003C4C;
}

/* Course */
.course .courses-events {
	padding-top: 40px;
}

.course .courses-events .courses-events_wrap {
	padding-top: 0;
}

.course .courses-events .courses-events_wrap h2 {
	margin-bottom: 40px;
}

/* Om oss */
.sectionBlock.sectionBlockShort .headerBox .contentTitle>* {
	margin: 0;
}

.sectionBlock.sectionBlockShort .headerBox:after {
	display: none;
}

@media (max-width: 767px) {
	.sectionBlock.sectionBlockShort .headerBox .contentIntro {
		word-break: break-word;
	}
}

.sectionBlock.sectionBlockShort .headerBox .contentTitle+.contentIntro {
	margin-top: 20px;
}

.sectionBlock.sectionBlockShort .headerBox .contentTitle+.contentIntro:first-child {
	margin-top: 0;
}

.sectionBlock>.container>.headerBox {
	margin-bottom: 60px;
}

.sectionBlock>.container>.contentBox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
}

.sectionBlock>.container>.contentBox>.itemBox.w-50 {
	width: 50%;
}

@media (max-width: 767px) {
	.sectionBlock>.container>.contentBox>.itemBox.w-50 {
		width: 100%;
	}
}

.sectionBlock>.container>.contentBox>.itemBox {
	width: 33.33%;
	padding-left: 10px;
	padding-right: 10px;
}

@media (max-width: 767px) {
	.sectionBlock>.container>.contentBox>.itemBox {
		width: 100%;
	}
}

.sectionBlock>.container>.contentBox.colSeparete>.itemBox {
	border-left: 1px solid #DADEDA;
}

.sectionBlock>.container>.contentBox.colSeparete>.itemBox:first-child {
	border: none;
}

@media (max-width: 767px) {
	.sectionBlock>.container>.contentBox.colSeparete>.itemBox {
		border-left: 0px;
		border-top: 1px solid #DADEDA;
	}
}

.sectionBlock>.container>.contentBoxCol4>.itemBox {
	width: 25%;
}

@media (max-width: 991px) {
	.sectionBlock>.container>.contentBoxCol4>.itemBox {
		width: 50%;
	}
}

@media (max-width: 767px) {
	.sectionBlock>.container>.contentBoxCol4>.itemBox {
		width: 50%;
	}
}

@media (max-width: 480px) {
	.sectionBlock>.container>.contentBoxCol4>.itemBox {
		width: 100%;
	}
}

.contactBox {
	width: 100%;
	text-align: center;
	padding: 15px 0px;
}

@media(max-width: 767px) {
	.contactBox {
		width: 40%;
		margin: 0 auto;
	}
}

.contactBox p {
	color: $old003C4C;
	font-family: $sans;
	font-size: 0.875rem;
	line-height: 22px;
	text-align: center;
}

.main_social>ul {
	padding-left: 0;
	margin-left: 0;
	list-style-type: none;
	text-align: center;
	margin: 0;
}

.main_social>ul>li {
	display: inline-block;
	margin-right: 8px;
}

.sectionBlockContact>.container>.contentBox {
	margin-bottom: 60px;
}

.alignCenter {
	text-align: center;
}

.personBox {
	margin-bottom: 70px;
	position: relative;
}

.personBox .personDescription {
	position: absolute;
	display: none;
	width: 100%;
	height: auto;
	background: #D2E288;
	z-index: 5;
	padding: 20px;
	word-wrap: break-word;
}

.personBox .descriptionFixed {
	display: flex !important;
	flex-direction: column;
	min-height: 452.75px;
	background: $green;
}

.personBox .descriptionFixed h3 {
	color: #FFFFFF;
	text-align: center;
	padding-top: 150px;
}

.personBox .descriptionFixed p {
	flex-grow: 1;
	text-align: center;
	color: $old333;
}

.personBox .personImage {
	margin-bottom: 15px;
}

@media(max-width:992px) {
	.personBox .personImage img {
		width: 100%;
	}
}

.personBox .personContent .personTitle {
	margin-bottom: 15px;
}

.personBox .personContent .personTitle>h2 {
	color: $offblack;
	@include serifHeading;
	font-size: 1.25rem;

	line-height: 1.5rem;
	margin: 0;
}

.personBox .personContent .personPosition {
	color: $old7D7D7D;
	@include microfont("sm");
	// font-size: 0.75rem;

	margin-bottom: 20px;
}

.personBox .personContent .personInfo {
	margin-bottom: 20px;
}

.personBox .personContent .personInfo>* {
	color: $oldgray;
	font-family: $sans;
	font-size: 0.875rem;
	line-height: 1.375rem;
}

.personBox .personContent .personFooter {
	padding: 5px 0px;
}

.bg-blue .contactBox p {
	color: #FFFFFF;
}

.listMenuLinksBlock {
	background: #FFFFFF;
}

.is-sticky {
	border-bottom: 1px solid $old333;
}

.is-sticky .listMenuLinksBlock {
	z-index: 99 !important;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

/* Local team */
.localTeam {
	display: block;
}

/* Select block */

// .sectionBlock .listBlogBox .contentBox .blog-card {
// 	padding: 10px;
// 	-webkit-box-flex: 0;
// 	-webkit-flex: 0 0 33.333%;
// 	-ms-flex: 0 0 33.333%;
// 	flex: 0 0 33.333%;
// 	max-width: 33.333%;
// 	display: -webkit-box;
// 	display: -webkit-flex;
// 	display: -ms-flexbox;
// 	display: flex;
// }

// @media (max-width: 767px) {
// 	.sectionBlock .listBlogBox .contentBox .blog-card {
// 		-webkit-box-flex: 0;
// 		-webkit-flex: 0 0 100%;
// 		-ms-flex: 0 0 100%;
// 		flex: 0 0 100%;
// 		max-width: 100%;
// 	}
// }

// .sectionBlock .listBlogBox .contentBox .blog-card .blog-card_wrap {
// 	position: relative;
// 	border-radius: 3px;
// 	text-align: left;
// 	padding: 20px;
// 	width: 100%;
// }

// .sectionBlock .listBlogBox .contentBox .blog-card .blog-card_wrap.green:before {
// 	background: rgba(144, 173, 81, 0.75) !important;
// 	content: "" !important;
// 	position: absolute;
// 	top: 0;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// 	z-index: 0;
// 	-webkit-border-radius: 3px;
// 	-moz-border-radius: 3px;
// 	border-radius: 3px;
// }

// .sectionBlock .listBlogBox .contentBox .blog-card .blog-card_wrap.green:hover:before {
// 	background: rgba(144, 173, 81, 0.9) !important;
// }

// .sectionBlock .listBlogBox .contentBox .blog-card .blog-card_wrap.blue:before {
// 	background: rgba(25, 144, 194, 0.75) !important;
// 	content: "" !important;
// 	position: absolute;
// 	top: 0;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// 	z-index: 0;
// 	-webkit-border-radius: 3px;
// 	-moz-border-radius: 3px;
// 	border-radius: 3px;
// }

// .sectionBlock .listBlogBox .contentBox .blog-card .blog-card_wrap.blue:hover:before {
// 	background: rgba(25, 144, 194, 0.9) !important;
// }

// .sectionBlock .listBlogBox .contentBox .blog-card .blog-card_wrap .blog-card_date {
// 	position: relative;
// 	z-index: 1;
// 	color: white;
// 	@include microfont("sm");
// 	// font-size: 0.75rem;


// 	margin-bottom: 111px;
// }

// .sectionBlock .listBlogBox .contentBox .blog-card .blog-card_wrap .blog-card_link {
// 	position: relative;
// 	display: block;
// 	color: white;
// 	@include serifHeading;
// 	font-size: 1.5rem;
// 	font-weight: 700;
// 	line-height: 28px;
// 	margin-bottom: 14px;
// 	z-index: 1;
// }

// .sectionBlock .listBlogBox .contentBox .blog-card .blog-card_wrap .read-more {
// 	@include microfont("sm");
// 	// font-size: 0.75rem;
// 	font-weight: 400;



// 	color: white;
// 	position: relative;
// 	z-index: 1;
// }

// .sectionBlock .listBlogBox .contentBox .blog-card .blog-card_wrap .read-more:before {
// 	content: " ";
// 	background: url("../../images/icon_line_w.svg") center center no-repeat;
// 	background-size: contain;
// 	display: inline-block;
// 	width: 30px;
// 	height: 20px;
// 	margin-right: 10px;
// 	position: relative;
// 	vertical-align: bottom;
// }

// .sectionBlock .listBlogBox .contentBox .blog-card .blog-card_wrap .read-more:hover:before {
// 	background: url("../../images/icon_arrow_right_active_w.svg") center center no-repeat;
// 	width: 35px;
// }
// .sectionBlock .listAnyThing .itemBox .blog-card_wrap {
// 	position: relative;
// 	border-radius: 3px;
// 	text-align: left;
// 	padding: 20px;
// 	width: 100%;
// 	min-height: 260px;
// }

// .sectionBlock .listAnyThing .itemBox .blog-card_wrap.green:before {
// 	background: rgba(144, 173, 81, 0.75) !important;
// 	content: "" !important;
// 	position: absolute;
// 	top: 0;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// 	z-index: 0;
// 	-webkit-border-radius: 3px;
// 	-moz-border-radius: 3px;
// 	border-radius: 3px;
// }

// .sectionBlock .listAnyThing .itemBox .blog-card_wrap.green:hover:before {
// 	background: rgba(144, 173, 81, 0.9) !important;
// }

// .sectionBlock .listAnyThing .itemBox .blog-card_wrap.blue:before {
// 	background: rgba(25, 144, 194, 0.75) !important;
// 	content: "" !important;
// 	position: absolute;
// 	top: 0;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// 	z-index: 0;
// 	-webkit-border-radius: 3px;
// 	-moz-border-radius: 3px;
// 	border-radius: 3px;
// }

// .sectionBlock .listAnyThing .itemBox .blog-card_wrap.blue:hover:before {
// 	background: rgba(25, 144, 194, 0.9) !important;
// }

// .sectionBlock .listAnyThing .itemBox .blog-card_wrap .blog-card_footerBox {
// 	position: absolute;
// 	bottom: 0;
// 	left: 0;
// 	right: 0;
// 	padding-left: 20px;
// 	padding-bottom: 20px;
// 	padding-right: 20px;
// }

// @media (max-width: 768px) {
// 	.sectionBlock .listAnyThing .itemBox .blog-card_wrap .blog-card_footerBox {
// 		position: unset;
// 		padding: 0;
// 		left: unset;
// 		right: unset;
// 		bottom: unset;
// 	}
// }

// .sectionBlock .listAnyThing .itemBox .blog-card_wrap .blog-card_date {
// 	position: relative;
// 	z-index: 1;
// 	color: white;
// 	@include microfont("sm");
// 	// font-size: 0.75rem;


// 	margin-bottom: 111px;
// }

// .sectionBlock .listAnyThing .itemBox .blog-card_wrap .blog-card_link {
// 	position: relative;
// 	display: block;
// 	color: white;
// 	@include serifHeading;
// 	font-size: 1.5rem;
// 	font-weight: 700;
// 	line-height: 28px;
// 	margin-bottom: 14px;
// 	z-index: 1;
// }

// .sectionBlock .listAnyThing .itemBox .blog-card_wrap .read-more {
// 	@include microfont("sm");
// 	// font-size: 0.75rem;
// 	font-weight: 400;



// 	color: white;
// 	position: relative;
// 	z-index: 1;
// }

// .sectionBlock .listAnyThing .itemBox .blog-card_wrap .read-more:before {
// 	content: " ";
// 	background: url("../../images/icon_line_w.svg") center center no-repeat;
// 	background-size: contain;
// 	display: inline-block;
// 	width: 30px;
// 	height: 20px;
// 	margin-right: 10px;
// 	position: relative;
// 	vertical-align: bottom;
// }

// .sectionBlock .listAnyThing .itemBox .blog-card_wrap .read-more:hover:before {
// 	background: url("../../images/icon_arrow_right_active_w.svg") center center no-repeat;
// 	width: 35px;
// }



/* Advertise Single */

.borderRight {
	border-right: 1px solid #E7EAE6;
}

.borderBottom {
	border-bottom: 1px solid #E7EAE6;
}

.borderTop {
	border-top: 1px solid #E7EAE6;
}

.borderLeft {
	border-left: 1px solid #E7EAE6;
}

.rmPaddingleft {
	margin-left: 0;
}

.rmPaddingRight {
	padding-right: 0;
}

.rmPaddingTop {
	padding-top: 0;
}

.rmPaddingBottom {
	padding-bottom: 0;
}

.vacantBox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-top: 1px solid #E7EAE6;
	border-bottom: 1px solid #E7EAE6;
	font-size: 14px;
}

.vacantBox .vacantSubBox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 60%;
	margin-top: 15px;
	margin-bottom: 15px;
	border-right: 1px solid #E7EAE6;
	padding-right: 15px;
}

.vacantBox .vacantSubBox p {
	margin-bottom: 10px;
	line-height: 18px;
}

@media (max-width: 767px) {
	.vacantBox .vacantSubBox {
		width: 100%;
		border-right: none;
		padding-right: 0;
	}
}

@media (max-width: 480px) {
	.vacantBox .vacantSubBox {
		width: 100%;
		border-right: none;
		padding-right: 0;
	}
}

.vacantBox .vacantSubBox .vacantPlace {
	width: 100%;
	border-bottom: 1px solid #E7EAE6;
	margin-bottom: 15px;
}

.vacantBox .vacantSubBox .vacantDeadline {
	width: 50%;
	border-right: 1px solid #E7EAE6;
	padding-right: 15px;
}

@media (max-width: 480px) {
	.vacantBox .vacantSubBox .vacantDeadline {
		width: 100%;
		border-right: 0;
		padding-right: 0;
	}
}

.vacantBox .vacantSubBox .vacantPosition {
	width: 50%;
	padding-left: 15px;
}

@media (max-width: 480px) {
	.vacantBox .vacantSubBox .vacantPosition {
		width: 100%;
		padding-left: 0;
		border-top: 1px solid #E7EAE6;
		padding-top: 15px;
	}
}

.vacantBox .vacantLogo {
	width: 40%;
	max-width: 100%;
	margin-top: 15px;
	margin-bottom: 15px;
	padding-left: 15px;
}

@media (max-width: 767px) {
	.vacantBox .vacantLogo {
		width: 100%;
		border-top: 1px solid #E7EAE6;
		margin-top: 0;
		padding-top: 15px;
	}
}

@media (max-width: 480px) {
	.vacantBox .vacantLogo {
		padding-left: 0;
	}
}

.container-fluid {
	padding: 0;
}

p {
	margin-bottom: 20px;
	line-height: inherit;
}

p.separator:after {
	content: " | ";
	color: $green;
	display: block;
	margin: 20px 0 50px 0;
	font-size: 36px;
	font-weight: 100;
}

.links-sections a {
	color: $oldblue;
	border-bottom: 3px solid #FFFFFF;
	padding: 10px;
	font-size: 0.875rem;
	font-weigth: bold !important;
}

.links-sections a:hover {
	border-bottom: 2px solid #A2CD38;
}

.payment-frame {
	background-color: $old003C4C;
	padding: 30px;
}

.payment-frame h5 {
	color: #FFFFFF;

	font-size: 0.75rem;
	font-family: $sans;

	margin-bottom: 20px;
	font-weight: 100;
}

.payment-frame span {
	color: $green;
	font-size: 2.75rem;
	font-weight: 300;
}

.response-frame {
	background-color: $green;
	padding: 30px;
}

.response-frame h5 {
	color: #FFFFFF;

	font-size: 0.75rem;
	font-family: $sans;

	margin-bottom: 20px;
	font-weight: 100;
}

.response-frame span {
	color: $old003C4C;
	font-size: 2.75rem;
	font-weight: 300;
}


.bg-blue .circles-text {
	color: $green;
}

.itemColumn {
	padding: 1% 8%;
}

.itemColumn:nth-child(2) {
	border-left: 1px solid rgba(255, 255, 255, 0.2);
	border-right: 1px solid rgba(255, 255, 255, 0.2);
}

@media(max-width: 767px) {
	.itemColumn:nth-child(2) {
		border-right: 0;
		border-left: 0;
		border-top: 1px solid rgba(255, 255, 255, 0.2);
	}
}

@media(max-width: 767px) {
	.itemColumn:nth-child(3) {
		border-right: 0 !important;
		border-left: 0;
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
}

.itemColumn h4 {
	color: $green;
	font-weight: 300;
	font-family: $sans;
	font-size: 3rem;
	margin-bottom: 20px;
}

.itemColumn h5 {
	color: #FFFFFF;
	font-family: $sans;

	font-size: 1rem;
}

.itemColumn p {
	font-size: 0.825rem;
	line-height: 1.5rem;
}

.accordion-toggle:after {
	font-family: "FontAwesome";
	content: "Ã¯ï¿½Â¸";
	float: right;
	margin-top: 2px;
}

.accordion-opened .accordion-toggle:after {
	content: "Ã¯ï¿½â€�";
}

.accordion .accordion-group {
	padding: 10px;
	border-bottom: 1px solid #f9f9f9;
}

.accordion .accordion-group:hover {
	background-color: #f9f9f9;
}

.accordion .accordion-group .accordion-heading a {
	color: $old777876;
	font-size: 0.825rem;
}

.accordion .accordion-group:first-child {
	border-top: 1px solid #f9f9f9;
}

.accordion .accordion-group:hover {
	background-color: #f9f9f9;
}

.accordion .accordion-inner {
	margin-top: 30px;
}

.minside .tabs .row {
	margin: 0 !important;
}

.minside .header {
	background: $old003C4C;
	padding: 50px;
}

.minside .header .row {
	display: flex;
}

@media (max-width: 767px) {
	.minside .header .row {
		display: block;
	}
}

.minside .header .header-buttons {
	align-self: flex-end;
}

@media (max-width: 767px) {
	.minside .header .header-buttons {
		text-align: center;
	}
}

.minside .header .header-buttons a {
	margin-top: 15px;
}

@media (max-width: 767px) {
	.minside .header .header-buttons a {
		display: block;
		margin: 10px auto;
		width: 30%;
	}
}

.minside .header .user-data {
	color: #FFFFFF;
	padding-left: 30px;
	align-self: center;
	font-size: 13px;
	line-height: 20px;
	padding-top: 30px;
}

.minside .header .user-data h2 {
	color: #FFFFFF;
}

.minside .header .user-data strong {
	margin-right: 5px;
}

@media (max-width: 767px) {
	.minside .header .user-data {
		text-align: center;
		margin: 20px 0;
		padding: 0;
	}
}

.minside .user-photo img {
	max-width: 200px;
	border-radius: 50%;
	height: auto;
	margin: 0 auto;
	display: block;
	width: 100%;
}

@media (max-width: 767px) {
	.minside .user-photo img {
		max-width: 100px;
	}
}

.minside .nav-tabs li.nav-item {
	padding: 0;
}

.minside .nav-tabs li.nav-item:first-child {
	text-align: right;
}

.minside .nav-tabs li.nav-item a {
	border: 0;
	background: #f2f2f3;
	margin: 0;
}

.minside .nav-tabs li.nav-item a span {
	margin-right: 0;
	border: 0;
	border-bottom: 2px solid #f2f2f3;
	padding: 10px 8px 3px 8px;
	display: inline-block;
	color: $old999;
	font-size: 0.875rem;
}

.minside .nav-tabs li.nav-item a span:hover {
	box-shadow: inset 0 -2px 0 #D2E288;
}

.minside .nav-tabs li.nav-item a.active {
	background: #FFFFFF;
}

.minside .nav-tabs li.nav-item a.active span {
	border-color: #D2E288;
	color: $old161616;
}

.minside .f2-content {
	display: none;
}

.minside .f1-content,
.minside .f2-content {
	padding: 50px 0;
}

@media (max-width: 767px) {

	.minside .f1-content,
	.minside .f2-content {
		padding-top: 0;
	}
}

.minside .f1-content .f2-items>div,
.minside .f2-content .f2-items>div {
	width: calc(90% / 5);
	float: left;
	margin: 0 1%;
}

.minside .f1-content .f2-items>div:hover,
.minside .f2-content .f2-items>div:hover {
	position: relative;
	border-bottom: 2px solid #D2E288;
}

.minside .f1-content .f2-items>div:hover:after,
.minside .f2-content .f2-items>div:hover:after {
	content: "";
	position: absolute;
	left: 50%;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #D2E288;
	clear: both;
	margin-top: 2px;
}

.minside .f1-content .f1-items .item-content:hover,
.minside .f2-content .f1-items .item-content:hover {
	position: relative;
	border-bottom: 2px solid #D2E288;
}

.minside .f1-content .f1-items .item-content:hover:after,
.minside .f2-content .f1-items .item-content:hover:after {
	content: "";
	position: absolute;
	left: 50%;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #D2E288;
	clear: both;
	margin-top: 2px;
	top: 100%;
}

.minside .f1-content .f1-items,
.minside .f1-content .f2-items,
.minside .f2-content .f1-items,
.minside .f2-content .f2-items {
	margin-bottom: 50px;
}

.minside .f1-content .f1-items .item-content,
.minside .f1-content .f2-items .item-content,
.minside .f2-content .f1-items .item-content,
.minside .f2-content .f2-items .item-content {
	cursor: pointer;
	border: 1px solid #f2f2f3;
	text-align: center;
	padding: 30px;
}

.minside .f1-content .f1-items .item-content img,
.minside .f1-content .f2-items .item-content img,
.minside .f2-content .f1-items .item-content img,
.minside .f2-content .f2-items .item-content img {
	display: block;
	margin: 0 auto;
	margin-bottom: 15px;
	max-height: 52px;
}

.minside .f1-content .f1-items .item-content span,
.minside .f1-content .f2-items .item-content span,
.minside .f2-content .f1-items .item-content span,
.minside .f2-content .f2-items .item-content span {
	min-height: 39px;
	display: block;
	font-size: 0.8rem;

	color: $offblack;
}

@media (max-width: 767px) {

	.minside .f1-content .f1-items,
	.minside .f1-content .f2-items,
	.minside .f2-content .f1-items,
	.minside .f2-content .f2-items {
		display: none;
	}
}

.minside .f1-content select,
.minside .f2-content select {
	display: none;
}

.minside .f1-content select option,
.minside .f2-content select option {
	background-position: 15px center;
	padding: 30px 0 30px 100px;
	background-repeat: no-repeat;
}

.minside .f1-content .f1-items-mobile,
.minside .f1-content .f2-items-mobile,
.minside .f2-content .f1-items-mobile,
.minside .f2-content .f2-items-mobile {
	display: none;
	margin: 0;
}

@media (max-width: 767px) {

	.minside .f1-content .f1-items-mobile,
	.minside .f1-content .f2-items-mobile,
	.minside .f2-content .f1-items-mobile,
	.minside .f2-content .f2-items-mobile {
		display: block;
	}
}

.minside .f1-content .f1-items-mobile .drop-down,
.minside .f1-content .f2-items-mobile .drop-down,
.minside .f2-content .f1-items-mobile .drop-down,
.minside .f2-content .f2-items-mobile .drop-down {
	position: relative;
	display: inline-block;
	width: 90%;
	margin: 0 5%;
	border-radius: 3px;
}

.minside .f1-content .f1-items-mobile .drop-down>.button,
.minside .f1-content .f2-items-mobile .drop-down>.button,
.minside .f2-content .f1-items-mobile .drop-down>.button,
.minside .f2-content .f2-items-mobile .drop-down>.button {
	width: 100%;
	display: block;
	background: #FFFFFF;
	padding: 30px 15px;
	text-align: left;
	margin-bottom: 30px;
}

.minside .f1-content .f1-items-mobile .drop-down>.button span,
.minside .f1-content .f2-items-mobile .drop-down>.button span,
.minside .f2-content .f1-items-mobile .drop-down>.button span,
.minside .f2-content .f2-items-mobile .drop-down>.button span {
	background-repeat: no-repeat;
	padding: 50px 0px 50px 80px;
	background-position: left center;
	width: 100%;
	text-transform: initial;
	color: $old161616;
	font-family: $sans;
	font-size: 0.875rem;
}

.minside .f1-content .f1-items-mobile .drop-down>.button a.select-list-link,
.minside .f1-content .f2-items-mobile .drop-down>.button a.select-list-link,
.minside .f2-content .f1-items-mobile .drop-down>.button a.select-list-link,
.minside .f2-content .f2-items-mobile .drop-down>.button a.select-list-link {
	float: right;
}

.minside .f1-content .f1-items-mobile .drop-down>.button a.select-list-link i,
.minside .f1-content .f2-items-mobile .drop-down>.button a.select-list-link i,
.minside .f2-content .f1-items-mobile .drop-down>.button a.select-list-link i,
.minside .f2-content .f2-items-mobile .drop-down>.button a.select-list-link i {
	font-size: 1.2rem;
}

.minside .f1-content .f1-items-mobile .drop-down select,
.minside .f1-content .f2-items-mobile .drop-down select,
.minside .f2-content .f1-items-mobile .drop-down select,
.minside .f2-content .f2-items-mobile .drop-down select {
	display: none;
}

.minside .f1-content .f1-items-mobile .drop-down .select-list,
.minside .f1-content .f2-items-mobile .drop-down .select-list,
.minside .f2-content .f1-items-mobile .drop-down .select-list,
.minside .f2-content .f2-items-mobile .drop-down .select-list {
	position: absolute;
	top: 15px;
	left: 0;
	z-index: 1;
	margin-top: 63px;
	padding: 0;
	background-color: #FFFFFF;
	list-style: none;
	width: 100%;
	border: 0;
}

.minside .f1-content .f1-items-mobile .drop-down .select-list li,
.minside .f1-content .f2-items-mobile .drop-down .select-list li,
.minside .f2-content .f1-items-mobile .drop-down .select-list li,
.minside .f2-content .f2-items-mobile .drop-down .select-list li {
	display: none;
}

.minside .f1-content .f1-items-mobile .drop-down .select-list li span,
.minside .f1-content .f2-items-mobile .drop-down .select-list li span,
.minside .f2-content .f1-items-mobile .drop-down .select-list li span,
.minside .f2-content .f2-items-mobile .drop-down .select-list li span {
	display: inline-block;
	min-height: 40px;
	min-width: 280px;
	width: 100%;
	padding: 30px 15px 30px 80px;
	background-color: #FFFFFF;
	background-position: left 10px center;
	background-repeat: no-repeat;
	font-size: 0.8rem;
	text-align: left;
	color: $offblack;
	box-sizing: border-box;
}

.minside .f1-content .f1-items-mobile .drop-down .select-list li span:hover,
.minside .f1-content .f2-items-mobile .drop-down .select-list li span:hover,
.minside .f2-content .f1-items-mobile .drop-down .select-list li span:hover,
.minside .f2-content .f2-items-mobile .drop-down .select-list li span:hover {
	opacity: 1;
}

.minside .f1-content .f1-items-mobile .drop-down .select-list li span:focus,
.minside .f1-content .f2-items-mobile .drop-down .select-list li span:focus,
.minside .f2-content .f1-items-mobile .drop-down .select-list li span:focus,
.minside .f2-content .f2-items-mobile .drop-down .select-list li span:focus {
	opacity: 1;
}

.minside .icon-table {
	display: none;
	position: absolute;
	left: 43%;
	cursor: pointer;
}

@media (max-width: 767px) {
	.minside .icon-table {
		display: block;
		margin-top: 20%;
	}
}

@media (max-width: 480px) {
	.minside .icon-table {
		margin-top: 30%;
	}
}

.minside .table-container {
	width: 100%;
	overflow-y: auto;
	_overflow: auto;
	margin: 30px 0 50px 0;
}

.minside .table-container tr td {
	border-top: 3px solid #FFFFFF;
	color: $old161616;
	white-space: nowrap;
	font-size: 1rem;
}

.minside .table-container tr td:last-child {
	text-align: center;
	padding-right: 20px;
	vertical-align: middle;
}

.minside .table-container tr td:first-child {
	padding-left: 20px;
}

@media (max-width: 767px) {
	.minside .table-container tr td {
		font-size: 0.875rem;
	}
}

.minside .table-container tr td a {
	color: $old161616;
}

.minside .table-container tr.disabled td {
	color: $old999;
}

.minside .categoryBox {
	color: $old7D7D7D;
	@include microfont("sm");
	// font-size: 0.75rem;
	margin-bottom: 20px;
}

.minside .categoryBox:before {
	content: " ";
	height: 2px;
	display: inline-block;
	width: 30px;
	margin-right: 10px;
	background-color: $green;
	position: relative;
	vertical-align: middle;
}

@media (max-width: 767px) {
	.minside .categoryBox {
		width: 100%;
		order: 1;
	}
}

.min-kurshistorikk {
	display: block;
}

.min-profil {
	display: block;
	padding: 0;
}

@media (max-width: 767px) {
	.min-profil h2 {
		padding: 0 15px;
	}
}

.min-profil .sectionBlock {
	display: flex;
	flex-direction: row;
	padding-top: 30px;
}

@media (max-width: 767px) {
	.min-profil .sectionBlock {
		display: initial !important;
	}
}

.min-profil .sectionBlock .reservasjon {
	order: 2;
	padding: 0 0 0 50px;
}

@media (max-width: 767px) {
	.min-profil .sectionBlock .reservasjon {
		background-color: #f2f2f3;
		padding: 20px 15px 10px 15px;
		margin-bottom: 20px;
	}
}

.min-profil .sectionBlock .reservasjon p {
	font-size: 14px;
	line-height: 22px;
	word-wrap: breaking-word;
}

.min-profil .sectionBlock .form-profil {
	order: 1;
}


.min-fagbladet {
	display: block;
	text-align: center;
}

.min-fagbladet a img {
	width: 100%;
	height: auto;
}

.min-fagbladet a h3 {
	font-size: 0.875rem;
	font-weight: 500;
	color: $oldgray;
	font-family: $sans;
	font-size: 13px;
	margin: 10px 0;
}

.min-fagbladet h2 {
	margin-bottom: 40px;
	text-align: left;
}

.min-fagbladet .button {
	margin: 30px 0 100px 0;
}

.mine-verv {
	display: block;
}

.mine-verv table thead tr th {
	border: 0;
	color: $offblack;
}

.mine-verv h5 {
	font-size: 18px;
	font-family: $sans;
	font-weight: 500;
	margin: 20px 0 10px 0;
}

.mine-verv h6 {
	color: $oldblue;

	font-size: 1rem;
}

.medlemsliste h2 {
	margin-bottom: 30px;
}

.medlemsliste a {
	border-bottom: 2px solid #D2E288;
	color: $offblack;
	display: table;
	margin-bottom: 10px;
	font-size: 1rem;
}

.moteoversikt {
	display: block;
}

.moteoversikt table thead tr th {
	border: 0;
	color: $offblack;
}

.moteoversikt h5 {
	font-size: 18px;
	font-family: $sans;
	font-weight: 500;
	margin: 20px 0 10px 0;
}

.ressursbank {
	display: block;
}

.ressursbank h2 {
	margin: 50px 0;
}

@media (max-width: 767px) {
	.ressursbank h2 {
		margin: 30px 0;
	}
}

.ressursbank h2:first-child {
	margin-top: 0;
}

.ressursbank h6 {
	color: $oldblue;

	font-size: 1rem;
	margin: 50px 0;
}

@media (max-width: 767px) {
	.ressursbank h6 {
		margin: 20px 0;
	}
}

.ressursbank table thead tr th {
	border: 0;
	color: $offblack;
}

.ressursbank p,
.ressursbank ul {
	line-height: 26px;
	padding-left: 120px;
	color: $offblack;
	font-size: 0.875rem;
}

@media (max-width: 767px) {

	.ressursbank p,
	.ressursbank ul {
		padding-left: 0;
	}
}

.ressursbank ul li p {
	padding-left: 0;
}

.ressursbank .files-links a {
	margin-left: 120px;
	color: $offblack;
	border-bottom: 1px solid #D2E288;
	padding-bottom: 2px;
}

@media (max-width: 767px) {
	.ressursbank .files-links a {
		margin-left: 0;
	}
}

@media (max-width: 767px) {
	.ressursbank .files-links p {
		margin-top: 5px;
	}
}

.ressursbank ol {
	padding-left: 120px;
}

@media (max-width: 767px) {
	.ressursbank ol {
		padding-left: 20px;
	}
}

.ressursbank ol li {
	color: $offblack;
}

.ressursbank ol li ul {
	padding-left: 20px;
	padding-top: 30px;
	padding-bottom: 30px;
}

@media (max-width: 767px) {
	.ressursbank ol li ul {
		padding-top: 0;
	}
}

.ressursbank ol li ul li {
	list-style: disc;
	font-weight: normal;
}

@media (max-width: 767px) {
	.ressursbank .data-tabs ul {
		padding-left: 20px;
	}
}

.ressursbank nav#year-tabs {
	text-align: center;
}

.ressursbank nav#year-tabs a {
	color: $offblack;
	font-size: 0.875rem;
	padding: 2px 10px;
	border-bottom: 2px solid #FFF;
}

.ressursbank nav#year-tabs a.active {
	border-color: #D2E288;
}

.ressursbank nav#year-tabs a:hover {
	background: #f2f2f3;
}

.ressursbank .history-links {
	margin-left: 120px;
}

.ressursbank .history-links a {
	text-decoration: underline;
	text-decoration-color: #D2E288;
	float: left;
	width: 50%;
	margin-bottom: 15px;
	color: $old333;
	font-weight: 500;
}

@media(max-width: 767px) {
	.ressursbank .history-links a {
		float: none;
	}
}

.ressursbank .history-links span {
	width: 20%;
	margin-bottom: 15px;
	color: $old333;
	display: block;
	float: left;
	font-style: italic;
}

.designmanual {
	display: block;
}

.designmanual h2 {
	margin: 50px 0;
}

@media (max-width: 767px) {
	.designmanual h2 {
		margin: 30px 0;
	}
}

.designmanual h2:first-child {
	margin-top: 0;
}

.designmanual h6 {
	color: $oldblue;

	font-size: 1rem;
	margin: 50px 0;
}

@media (max-width: 767px) {
	.designmanual h6 {
		margin: 20px 0;
	}
}

.designmanual strong {
	padding-left: 120px;
	color: $offblack;
	font-size: 1rem;
	line-height: 26px;
}

@media (max-width: 767px) {
	.designmanual strong {
		padding-left: 0;
	}
}

.designmanual p,
.designmanual ul {
	line-height: 26px;
	padding-left: 120px;
	color: $offblack;
	font-size: 1rem;
}

@media (max-width: 767px) {

	.designmanual p,
	.designmanual ul {
		padding-left: 0;
	}
}

.designmanual ul {
	margin-bottom: 40px;
}

.designmanual ul li p {
	padding-left: 0;
}

.designmanual a.link {
	font-size: 14px;
	margin-bottom: 4px;
	color: $old777876;
	text-decoration: underline;
	display: block;
	margin-left: 120px;
}

@media (max-width: 767px) {
	.designmanual a.link {
		margin-bottom: 10px;
		margin-left: 0;
	}
}

.designmanual .brands {
	margin-left: 120px;
	display: -webkit-box;
	margin-bottom: 50px;
}

@media (max-width: 767px) {
	.designmanual .brands {
		margin-left: 0;
		display: inline-block;
		margin-bottom: 20px;
	}
}

.designmanual .brands h6 {
	margin: 20px 0 10px 0;
	font-size: 18px;
}

.designmanual .brands>div {
	padding: 0;
}

.designmanual .brands .brand-image img {
	width: 90%;
	border: 1px solid #E7EAE6;
}

@media (max-width: 767px) {
	.designmanual .brands .brand-image img {
		width: 100%;
	}
}

.designmanual .brands a {
	display: block;
	color: $old777876;
	text-decoration: underline;
	margin-bottom: 10px;
	font-size: 14px;
}

.designmanual ul li {
	margin: 20px 0 20px 25px;
	padding-left: 10px;
}

.retningslinjer {
	display: block;
}

.retningslinjer h2 {
	margin: 50px 0;
}

@media (max-width: 767px) {
	.retningslinjer h2 {
		margin: 30px 0;
	}
}

.retningslinjer h2:first-child {
	margin-top: 0;
}

.retningslinjer h6 {
	color: $oldblue;

	font-size: 1rem;
	margin: 50px 0;
}

@media (max-width: 767px) {
	.retningslinjer h6 {
		margin: 20px 0;
	}
}

.retningslinjer strong {
	padding-left: 120px;
	color: $offblack;
	font-size: 1rem;
	line-height: 26px;
}

@media (max-width: 767px) {
	.retningslinjer strong {
		padding-left: 0;
	}
}

.retningslinjer p,
.retningslinjer ul {
	line-height: 26px;
	padding-left: 120px;
	color: $offblack;
	font-size: 1rem;
}

@media (max-width: 767px) {

	.retningslinjer p,
	.retningslinjer ul {
		padding-left: 0;
	}
}

.retningslinjer p strong,
.retningslinjer ul strong {
	padding-left: 0;
}

.retningslinjer ul li {
	padding-top: 10px;
	padding-bottom: 10px;
}

.retningslinjer ul li p {
	padding-left: 0;
}

.retningslinjer ol {
	padding-left: 135px;
}

@media (max-width: 767px) {
	.retningslinjer ol {
		padding-left: 20px;
	}
}

.retningslinjer ol li {
	color: $offblack;
	padding-top: 10px;
	padding-bottom: 10px;
}

.retningslinjer ol>ul {
	padding-left: 20px;
	padding-top: 0;
	padding-bottom: 0;
}

@media (max-width: 767px) {
	.retningslinjer ol>ul {
		padding-top: 0;
	}
}

.retningslinjer ol>ul li {
	list-style: disc;
	font-weight: normal;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 10px;
}

.okonomi {
	display: block;
}

.okonomi h2 {
	margin: 50px 0;
}

@media (max-width: 767px) {
	.okonomi h2 {
		margin: 30px 0;
	}
}

.okonomi h2:first-child {
	margin-top: 0;
}

.okonomi h6 {
	color: $oldblue;

	font-size: 1rem;
	margin: 50px 0;
}

@media (max-width: 767px) {
	.okonomi h6 {
		margin: 20px 0;
	}
}

.okonomi strong {
	padding-left: 120px;
	color: $offblack;
	font-size: 1rem;
	line-height: 26px;
}

@media (max-width: 767px) {
	.okonomi strong {
		padding-left: 0;
	}
}

.okonomi p,
.okonomi ul {
	line-height: 26px;
	padding-left: 120px;
	color: $offblack;
	font-size: 1rem;
}

.okonomi p strong,
.okonomi ul strong {
	padding-left: 0;
}

.okonomi p a,
.okonomi ul a {
	display: block;
	padding: 5px 0 0 0;
	text-decoration: underline;
	color: $offblack;
}

@media (max-width: 767px) {

	.okonomi p,
	.okonomi ul {
		padding-left: 0px;
	}
}

.okonomi ul {
	padding-left: 160px;
}

@media (max-width: 767px) {
	.okonomi ul {
		padding-left: 20px;
	}
}

.okonomi ul li:last-child {
	margin-bottom: 20px;
}

.okonomi ul li p {
	padding-left: 0;
}

.okonomi ol {
	padding-left: 135px;
}

@media (max-width: 767px) {
	.okonomi ol {
		padding-left: 20px;
	}
}

.okonomi ol li {
	color: $offblack;
	padding-top: 10px;
	padding-bottom: 10px;
}

.okonomi ol>ul {
	padding-left: 20px;
	padding-top: 0;
	padding-bottom: 0;
}

@media (max-width: 767px) {
	.okonomi ol>ul {
		padding-top: 0;
	}
}

.okonomi ol>ul li {
	list-style: disc;
	font-weight: normal;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 10px;
}

.okonomi form {
	padding-left: 120px;
	margin: 40px 0;
}

.okonomi form .select2-container {
	margin-right: 20px;
}

@media(max-width: 767px) {
	.okonomi form .select2-container {
		width: 100% !important;
	}
}

.okonomi form .select2-selection__rendered {
	padding-right: 35px;
}

@media(max-width: 767px) {
	.okonomi form {
		padding-left: 0;
	}
}

.errorPage {
	text-align: center;
	margin-top: -100px;
	padding-top: 160px;
	padding-bottom: 40px;
}

.errorPage h2 {
	font-size: 1.75rem;
	@include serifHeading;
	line-height: 34px;
	color: white;
	margin-bottom: 150px;
}

.errorPage .row:nth-child(2) img {
	margin-bottom: 50px;
	width: 60px;
	height: auto;
}

.errorPage .row:nth-child(2) a {
	margin: 40px 0 250px 0;
}

.errorPage .row:nth-child(2) p {
	@include serifHeading;
	font-size: 28px;
}

.sponsor {
	padding-top: 50px;
	border-top: 1px solid #DADEDA;
}

.sponsor a img {
	margin-bottom: 20px;
}

@media(max-width: 767px) {
	#hostseminaret .agency-list.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .readmore {
		margin-top: -40px;
	}
}

@media(max-width: 767px) {
	#hostseminaret .agency-list.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading {
		display: inline-flex;
	}
}

@media(max-width: 767px) {
	#hostseminaret .agency-list.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 {
		max-width: 55%;
		line-height: 16px;
	}
}

@media(max-width: 425px) {
	#hostseminaret .agency-list.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 {
		max-width: 50%;
	}
}

@media(max-width: 320px) {
	#hostseminaret .agency-list.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 {
		line-height: 0.8rem;
	}
}

@media(max-width: 1199px) {
	#hostseminaret .agency-list.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 a {
		font-size: 1rem;
		line-height: 1rem;
		word-wrap: break-word;
	}
}

@media(max-width: 320px) {
	#hostseminaret .agency-list.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading h3 a {
		font-size: 0.8rem;
	}
}

#hostseminaret .agency-list.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading .agency-img {
	display: block;
	width: 100%;
	max-width: 173px;
	height: -webkit-fill-available;
	background-position: center center;
	background-size: cover;
	margin-top: -20px;
	max-height: 173px;
}

@media(max-width: 767px) {
	#hostseminaret .agency-list.list-presenters .agency-list_wrap .agency-list_cards .agency-list_card .agency-list_card__wrap .agency-list_card__heading .agency-img {
		width: 45%;
		max-height: 147px;
	}
}

/*# sourceMappingURL=app.css.map */
.section {
	.agency-list_wrap,
	.agency-list_wrap.agency-list_carousel {
		padding-block: 0;
	}
}
