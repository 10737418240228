$page-color: var(--page-color);
$page-color-light: var(--page-color-light);

:root {
	--page-color: #{$lightgreen};
	--page-color-light: #{$lightgreen};
}

.page-white {
	--page-color: #FFF !important;
	--page-color-light: #FFF !important;
}

.page-green {
	--page-color: #{$theme-green} !important;
	--page-color-light: #{$theme-green--light} !important;
}

.page-yellow {
	--page-color: #{$theme-yellow} !important;
	--page-color-light: #{$theme-yellow--light} !important;
}

.page-purple {
	--page-color: #{$theme-purple} !important;
	--page-color-light: #{$theme-purple--light} !important;

}

.page-blue {
	--page-color: #{$theme-blue} !important;
	--page-color-light: #{$theme-blue}--light !important;
}

.bg-page {
	background-color: var(--page-color);
}

.bg-page-light {
	background-color: var(--page-color-light);
}

$theme-colors: (
	green: $theme-green,
	yellow: $theme-yellow,
	purple: $theme-purple,
	blue: $theme-blue,
);

@mixin add-theme-variations($property) {
	@each $theme-name, $color in $theme-colors {
		&.theme-#{'' + $theme-name} {
			#{$property}: $color;
		}
	}
}
