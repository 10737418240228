.visually-hidden {
	opacity: 0 !important;
	position: fixed !important;
	height: 0 !important;
	width: 0 !important;
	left: -100px;
}

.text-green {
	color: $green;
}

.mob-only {
	@media (min-width: 992px) {
		display: none !important;
	}
}

.mob-none {
	@media (max-width: 991px) {
		display: none !important;
	}
}

.col-lg-float-1 {
	@media (min-width: 1200px) {
		margin-left: -8.33333%;
	}
}
.row-flex {
	&:before, &:after {
		display: none;
	}
	min-width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.col-flex {
	display: flex;
	flex-direction: column;
}
.d-md-contents {
	padding: 0;
	@media(min-width: 992px){
		display: contents!important;
	}
}
.span-arrow {
	display: inline-block;
	width: fit-content;
}
