.hero-corporate {
	position: relative;
	padding-top: 0px;
	padding-bottom: 120px;
	// background: var(--page-color);

	@media (max-width: 767px) {
		padding-bottom: 56px;
	}

	&.section {
		padding-top: 0;
	}

	.row {
		display: flex;
		flex-wrap: wrap;
		justify-content: stretch;

		@media (max-width: 991px) {
			&:not(.hero-corporate__wrapper) {
				flex-direction: column;
			}
		}

		// align-items: center;
		.col-12 {
			display: flex;
			flex-direction: column;
		}
	}

	&__wrapper {

		&::before,
		&::after {
			display: none;
		}

		@media (max-width: 991px) {
			flex-direction: column-reverse;
			row-gap: 36px;
		}
	}

	&__img {
		width: 100%;
		aspect-ratio: 644/475;

		@media (max-width:767px) {
			aspect-ratio: 328/300;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	.breadcrumbs {
		margin-top: 16px;
		padding-inline: 12px;

		@media (max-width: 767px) {
			margin-top: 8px;
			padding: 22px 6px;
		}
	}

	&__text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 36px;
		height: 100%;


		@media (max-width: 991px) {
			gap: 16px;
		}

		.hero-corporate:not(.hero-corporate--img) & {
			padding-top: 80px;

			@media (max-width: 991px) {
				padding-top: 36px;
			}
		}

		.hero-corporate--centered & {
			text-align: center;
			padding-right: 0;
		}

		>* {
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		h1,
		.h1 {
			@include heading(d1);
			margin: 0;
		}

		p {
			@include fontBody(intro);
			margin: 0;
		}

	}

	&__link-list {
		display: flex;
		flex-direction: column;
		gap: 12px;
		list-style: none;
		padding: 0;

		li {
			display: inline-flex;
			gap: 8px;
			align-items: center;
			@include fontBody(sm);
			color: $offblack;

			&::before {
				content: "";
				width: 24px;
				height: 24px;
				background-image: url("../../images/icon/arrow-right-24.svg");
				background-position: center;
				background-size: 100%;
				background-repeat: no-repeat;
				rotate: 90deg;
			}

			* {
				color: inherit;
			}

			a {
				@include link-styled;
			}
		}
	}

	&--img {
		padding-top: 0;
		padding-bottom: 80px;

		@media (max-width: 767px) {
			padding-bottom: 40px;
		}
		.hero-corporate__text{
			@media (min-width: 992px) {
				padding-right: calc(80px - 24px);
			}

		}

		.breadcrumbs {
			@media (min-width: 768px) {
				margin-bottom: 32px;
			}

			+.hero-corporate__text {
				padding-bottom: 52px;

				>*:last-child {
					margin-bottom: -52px;
				}
			}

		}
	}
}

.hero-corporate-select {
	text-align: center;
	.selectBox {
		max-width: -to-rem(430);
		margin: auto;
	}
}
