.listMenuLinksBlock {
	.listMenuLinks {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: block;
		text-align: center;

		li {
			display: inline-block;
			padding: 10px 15px;

			a {
				border-bottom: 3px solid transparent;
				display: inline-block;
				color: $oldblue;
				font-family: $sans;
				font-size: 1rem;
				font-weight: 500;
				line-height: 22px;

				&:hover,
				&:focus,
				&:active {
					border-bottom: 3px solid $green;
				}

				&.active {
					border-bottom: 3px solid $green;
				}
			}
		}
	}
}
