.person-cards {
	.row:not(:last-child) {
		margin-bottom: 80px;
		@media (max-width: 991px) {
			margin-bottom: 40px;
		}
	}
	&_footer {
		text-align: center;
	}
}
