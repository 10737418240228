.searchPage {
   background-color: $lightgreen;

   .searchWrapper {
      display: block !important;
      padding-bottom: 72px;

      @media (max-width: 992px) {
         padding-bottom: 48px;
      }
   }

   .nav-search_wrap {
      .searches {
         ul {
            li {
               a {
                  @media (max-width: 767px) {
                     font-size: 0.813rem;
                     line-height: 21px;
                  }

                  &:hover,
                  &:focus {
                     color: $green;
                  }
               }
            }
         }
      }
   }

   .box-no-results {
      color: #FFFFFF;
      font-family: $sans;
      font-size: 0.875rem;
      margin: 20px 0 80px 0;
      padding: 0;

      img {
         margin-right: 15px;
      }
   }

   .search_results {
      &_header {
         margin-bottom: 40px;

         h2 {
            color: $offblack;
            @include heading(h4);
            @include serifHeading;
            margin: 0;

            @media (max-width: 992px) {
               margin-bottom: 30px;
            }
         }

         .searchSorting {
            @media (max-width: 992px) {
               margin-block: 32px;
            }
         }

         .search_category {
            display: flex;
            flex-wrap: wrap;
            gap: 16px 24px;

            a {
               display: block;
               margin: 0;
               padding: 0;
			   @include fontBody(sm);
				@include link-hover-box;

               &.disabled {
                  text-decoration-line: none;
               }
            }
         }
      }

      &_items {
         .button-container {
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            padding-top: 40px;
            margin-bottom: 40px;
            clear: both;
         }

         .item_result {
            margin-bottom: 10px;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            padding-top: 10px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;

            @media (max-width: 767px) {
               margin-bottom: 20px;
               padding-top: 20px;
            }

            div {
               padding: 0;
            }

            img {
               max-width: 100%;
            }

            .result-title {
               margin-left: 30px;

               @media (max-width: 767px) {
                  margin-left: 0;
                  padding-left: 3%;
               }

               h3 {
                  font-size: 1.25rem;
                  color: $offblack;
                  line-height: 1.5rem;
                  margin-bottom: 10px;

                  @media (max-width: 767px) {
                     word-wrap: break-word;
                     margin: 0;
                  }
               }

               .date {
                  color: $offblack;
                  font-size: 0.75rem;
                  display: block;
                  margin-bottom: 8px;
               }

               a {
                  display: inline-block;
                  color: $offblack;
				  @include fontBody(sm);
				  @include link-hover-box;

                  &::before {
                     display: inline-block;
                     content: "";
                     border-top: 1px solid currentColor;
                     width: 2rem;
                     margin-right: 5px;
                     transform: translateY(-4px);
                  }
               }
            }

            &>.no-margin {
               margin: 0;
            }
         }

         h4 {
            color: $offblack;
            font-size: 1.25rem;
         }
      }
   }
}

// @media (max-width: 767px) {
//    .searchPage .searchWrapper .search_results .search_results_items .item_result .result-title h3 {
//       word-wrap: break-word;
//    }
// }

// .search_results_items .item_result .result-title span.date {
//    color: #FFFFFF;
//    font-size: 0.75rem;
//    display: block;
//    margin-bottom: 8px;
// }

// .search_results_items .item_result {
//    display: -webkit-box;
//    display: -ms-flexbox;
//    display: flex;
//    -webkit-box-orient: horizontal;
//    -webkit-box-direction: normal;
//    -ms-flex-direction: row;
//    flex-direction: row;
//    -webkit-box-align: stretch;
//    -ms-flex-align: stretch;
//    align-items: stretch;
// }

// .searchPage .searchWrapper .search_results .search_results_items .item_result .result-title a {
//    margin-bottom: 30px;
//    display: block;
// }

// .searchPage .searchWrapper .search_results .search_results_items .item_result .result-title h3 {
//    margin-bottom: 10px;
// }

// .searchPage {
// 	background-color: $old003C4C;
// }

// .searchPage .searchWrapper {
// 	display: block !important;
// }

// .searchPage .searchWrapper .nav-search_wrap .searches ul li a:hover,
// .searchPage .searchWrapper .nav-search_wrap .searches ul li a:focus {
// 	color: #A2CD38;
// }

// @media (max-width: 767px) {
// 	.searchPage .searchWrapper .nav-search_wrap .searches ul li a {
// 		font-size: 0.813rem;
// 		line-height: 21px;
// 	}
// }

// .searchPage .searchWrapper .box-no-results {
// 	color: #FFFFFF;
// 	font-family: $sans;
// 	font-size: 0.875rem;
// 	margin: 20px 0 80px 0;
// 	padding: 0;
// }

// .searchPage .searchWrapper .box-no-results img {
// 	margin-right: 15px;
// }

// .searchPage .searchWrapper .search_results .search_results_header {
// 	margin-bottom: 40px;
// }

// .searchPage .searchWrapper .search_results .search_results_header h2 {
// 	color: #FFF;
// 	font-size: 1.25rem;
// 	margin: 0;
// }

// @media (max-width: 767px) {
// 	.searchPage .searchWrapper .search_results .search_results_header h2 {
// 		margin-bottom: 30px;
// 	}
// }

// .searchPage .searchWrapper .search_results .search_results_header a {
// 	color: #FFF;
// 	font-family: $sans;
// 	font-size: 0.875rem;
// 	margin: 0 20px 0 0;
// 	padding-bottom: 5px;
// }

// .searchPage .searchWrapper .search_results .search_results_header a:hover {
// 	border-bottom: 2px solid #A2CD38;
// }

// .searchPage .searchWrapper .search_results .search_results_header a span {
// 	color: #A2CD38;
// }

// .searchPage .searchWrapper .search_results .search_results_items .button-container {
// 	border-top: 1px solid rgba(255, 255, 255, 0.1);
// 	padding-top: 40px;
// 	margin-bottom: 40px;
// 	clear: both;
// }

// .searchPage .searchWrapper .search_results .search_results_items .item_result {
// 	margin-bottom: 10px;
// 	border-top: 1px solid rgba(255, 255, 255, 0.1);
// 	padding: 10px 0 0 0;
// }

// @media (max-width: 767px) {
// 	.searchPage .searchWrapper .search_results .search_results_items .item_result {
// 		margin-bottom: 20px;
// 		padding-top: 20px;
// 	}
// }

// .searchPage .searchWrapper .search_results .search_results_items .item_result div {
// 	padding: 0;
// }

// .searchPage .searchWrapper .search_results .search_results_items .item_result img {
// 	max-width: 100%;
// }

// .searchPage .searchWrapper .search_results .search_results_items .item_result .result-title {
// 	margin-left: 30px;
// }

// .searchPage .searchWrapper .search_results .search_results_items .item_result .result-title h3 {
// 	font-size: 1.25rem;
// 	color: #FFFFFF;
// 	line-height: 1.5rem;
// }

// @media (max-width: 767px) {
// 	.searchPage .searchWrapper .search_results .search_results_items .item_result .result-title h3 {
// 		margin: 0;
// 	}
// }

// .searchPage .searchWrapper .search_results .search_results_items .item_result .result-title a {
// 	color: #FFF;

// 	font-size: 0.75rem;
// 	font-family: $sans;
// }

// .searchPage .searchWrapper .search_results .search_results_items .item_result .result-title a::before {
// 	display: inline-block;
// 	content: "";
// 	border-top: 1px solid #A2CD38;
// 	width: 2rem;
// 	margin-right: 5px;
// 	transform: translateY(-4px);
// }

// @media (max-width: 767px) {
// 	.searchPage .searchWrapper .search_results .search_results_items .item_result .result-title {
// 		margin-left: 0;
// 		padding-left: 3%;
// 	}
// }

// .searchPage .searchWrapper .search_results .search_results_items .item_result>.no-margin {
// 	margin: 0;
// }

// .searchPage .searchWrapper .search_results .search_results_items h4 {
// 	color: #FFFFFF;
// 	font-size: 1.25rem;
// }
