.video-cards {
	.sallary-calc {
		margin-block: 0;

		h2 {
			font-family: $serif;
			font-weight: 400;
			text-align: left;
			margin-bottom: 2.25rem;
		}

		&__buttons {
			justify-content: flex-start;
		}

		.button {
			margin-block: 0;
		}
	}
}
