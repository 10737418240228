.modal-full {
	background-color: $old003C4C;
	color: $lightgreen;
	position: fixed;
	overflow-y: scroll;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999999;
	display: none;

	&.nav-search {
		color: $offblack;

		.modal-full__close {
			&::after {
				filter: brightness(0) saturate(100%);
			}
		}
	}

	&:not(.nav-search) {
		.main-header {
			background-color: transparent;
			&__logo {
				filter: brightness(100);
			}
		}
	}

	h2,h3 {
		color: $green;
		margin-top: 0;
		margin-bottom: 40px;
	}
	input.form-control {
		border: none;
		flex: 1;
		color: $offblack;
	}
	&--active {
		display: block;
	}
	&__close {
		&::after {
			content: '';
			display: block;
			width: 18px;
			height: 18px;
			background-image: url('../../images/icon/close-18.svg');
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;

			@media (max-width: 991px) {
				width: 14px;
				height: 14px;
				background-size: 12px;
			}
		}
	}
}
.nav-newsletter {
	text-align: center;
	&_content {
		margin-top: 120px;
		@media (max-width: 991px) {
			margin-top: 80px;
		}
	}
}
