.td {
	padding: 5px 24px;
	font-weight: 400;
	@media (max-width: 991px) {
		padding: 0 12px;
	}
	&:first-child {
		padding-left: 1px;
	}
	&:last-child {
		padding-right: 1px;
	}
}
.th {
	color: inherit;
	padding: 0 24px 16px;
	font-weight: 600;
	@media (max-width: 991px) {
		padding: 0 12px 8px;
	}
	&:first-child {
		padding-left: 1px;
	}
	&:last-child {
		padding-right: 1px;
	}
}
table {
	@include fontBody('xs');

	tbody tr:first-child th ~ td,
	thead + tbody tr:first-child td,
	th,
	td {
		@extend .td;
	}
	thead td,
	th,
	tbody tr:first-child td {
		@extend .th;
	}
	tr {
		border-bottom: 8px solid transparent;
		&:nth-child(even) {
			background-color: $page-color;
			color: $offblack;
		}
		// &:hover {
		// 	th,
		// 	td {
		// 		background: $green;
		// 	}
		// }
	}
	thead + tbody tr{
		&:nth-child(even) {
			background-color: transparent;
			color: inherit;
		}
		&:nth-child(odd) {
			background-color: $page-color;
			color: $offblack;
		}
	}
	tbody {
		th {
			padding: 5px 24px;
			@media (max-width: 991px) {
				padding: 0 12px;
			}
		}
	}
	caption {
		color: inherit;
		padding: 0;
		margin-bottom: 16px;
	}
}

.table-container {
	@media (max-width: 767px) {
		overflow: auto;
		width: 100vw;
		margin-left: calc(50% - 50vw);
		margin-right:  calc(50% - 50vw);
		padding:  0 calc(-50% + 50vw);
		display: block;
		table {
			min-width: -to-rem(550);
			width: auto;
		}
	}
}
