// @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');


$sans: "Inter", Verdana, Geneva, Tahoma, sans-serif;

$serif: "TiemposRegular", serif;
$serif-italic: "TiemposRegularItalic";
$serif-bold: "TiemposRegularSemibold";
$serif-bold-italic: "TiemposSemiboldItalic";

$serifTitle: "TiemposHeadline";
$serifTitle-italic: "TiemposHeadlineItalic";
$serifTitle-bold: "TiemposHeadlineBold";

@mixin serifRegular {
	font-family: $serif;
	font-weight: 400;

	em {
		font-family: $serifTitle;

		b,
		strong {
			font-family: $serif-bold-italic;
		}
	}

	b,
	strong {
		font-family: $serif-bold;

		em {
			font-family: $serif-bold-italic;
		}
	}
}

@mixin serifHeading {
	font-family: $serifTitle;
	font-weight: 400;

	em {
		font-family: $serifTitle-italic;
	}

	b,
	strong {
		font-family: $serifTitle-bold;
	}
}

@mixin heading($variation) {
	@if $variation == d1 {
		@include serifHeading;
		font-size: -to-rem(64);
		line-height: -to-rem(78);

		@media (max-width: 991px) {
			font-size: -to-rem(40);
			line-height: -to-rem(48);
		}
	}
	@if $variation == h1 {
		@include serifHeading;
		font-size: -to-rem(56);
		line-height: -to-rem(68);

		@media (max-width: 991px) {
			font-size: -to-rem(36);
			line-height: -to-rem(42);
		}
	}

	@if $variation == h2 {
		@include serifHeading;
		font-size: -to-rem(48);
		line-height: -to-rem(60);

		@media (max-width: 991px) {
			font-size: -to-rem(32);
			line-height: -to-rem(38);
		}
	}

	@if $variation == h3 {
		font-family: $sans;
		font-size: -to-rem(32);
		line-height: -to-rem(40);
		font-weight: 600;

		@media (max-width: 991px) {
			font-size: -to-rem(24);
			line-height: -to-rem(32);
		}
	}

	@else if $variation == h4 or $variation == h5 or $variation == h6 {
		font-family: $sans;
		font-size: -to-rem(24);
		line-height: -to-rem(32);
		font-weight: 600;

		@media (max-width: 991px) {
			font-size: -to-rem(20);
			line-height: -to-rem(28);
		}
	}

	a {
		font-size: inherit;
		font-family: inherit;
		font-weight: inherit;
		text-decoration: underline;
	}
}

@mixin fontBody($size) {
	@if $size =="intro" {
		font-size: -to-rem(28);
		line-height: 1.4;
		font-family: $serif-italic;

		b,
		strong {
			font-family: $serif-bold-italic;
		}

		@media (max-width: 991px) {
			font-size: -to-rem(24);
			line-height: 1.5;
		}
	}
	@if $size =="intro2" {
		font-size: -to-rem(24);
		line-height: 1.5;
		font-family: $serif-italic;

		b,
		strong {
			font-family: $serif-bold-italic;
		}

		@media (max-width: 991px) {
			font-size: -to-rem(20);
			line-height: 1.5;
		}
	}

	@if $size =="lg" {
		font-size: -to-rem(24);
		line-height: 1.5;

		@media (max-width: 991px) {
			font-size: -to-rem(22);
			line-height: 1.5;
		}
	}

	@if $size =="sm" {
		font-size: -to-rem(20);
		line-height: 1.4;

		@media (max-width: 991px) {
			font-size: -to-rem(16);
			line-height: 1.375;
		}
	}
	@if $size =="xs" {
		font-size: -to-rem(18);
		line-height: 1.4;

		@media (max-width: 991px) {
			font-size: -to-rem(16);
			line-height: 1.375;
		}
	}

	@if $size =="md" {
		font-size: -to-rem(22);
		line-height: 1.5;
		font-weight: normal;

		@media (max-width: 991px) {
			font-size: -to-rem(18);
		}
	}
}

@mixin microfont($size) {
	font-family: $sans;

	@if $size =="xs" {
		font-size: -to-rem(16);
		line-height: calc(18 / 16);

		@media (max-width: 991px) {
			font-size: -to-rem(14);
			line-height: calc(16 / 14);
		}
	}

	@if $size == "sm-bold" {
		font-size: -to-rem(18);
		font-weight: 600;
		line-height: 1;

		@media (max-width: 991px) {
			font-size: -to-rem(16);
			line-height: 1.25;
		}
	}

	@if $size == "sm" {
		font-size: -to-rem(18);
		line-height: 1;

		@media (max-width: 991px) {
			font-size: -to-rem(16);
		}
	}
}

body {
	font-size: -to-rem(24);
	font-family: "Inter", Verdana, Geneva, Tahoma, sans-serif;
	font-optical-sizing: auto;
	font-style: normal;
	text-decoration-thickness: 2px;
	text-underline-position: under;
	text-decoration-skip: initial;
	text-decoration-skip-ink: none;
}

h1, .h1 {
	@include heading(h1)
}

h2, .h2 {
	@include heading(h2)
}

h3, .h3 {
	@include heading(h3)
}

h4, .h4, h5, .h5, h6, .h6 {
	@include heading(h4)
}
a {
	color: inherit;
}
