.sallary-calc {
	&__block {
		text-align: center;
		.h3 {
			margin-bottom: 24px;
		}
		.button {
			margin-top: 48px;
			@media (max-width: 991px) {
				margin-top: 36px;
			}
		}
	}
	&__header {
		margin-bottom: 120px;
		@media (max-width: 991px) {
			margin-bottom: 56px;
		}
	}
	&__form {
		.form-group {
			.label,
			label {
				&:not(.form-check-label):not(.form-radio-label) {
					margin-bottom: 8px;
				}
			}
		}
	}
	&__buttons {
		column-gap: 16px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		row-gap: 8px;
	}
	&__result {
		display: flex;
		flex-wrap: wrap;
		margin-top: 80px;
		gap: 16px;
		@media (max-width: 991px) {
			margin-top: 60px;
		}
		&__box {
			@include fontBody(sm);
			color: $lightgreen;
			background-color: $offblack;
			border-radius: 12px;
			min-width: 40%;
			flex: 1;
			padding: 40px;
			@media (max-width: 991px) {
				padding: 24px;
			}
			.respondent_value {
				margin-top: 8px;
				@include heading(h3);
			}
			&--green {
				background-color: $green;
				color: $offblack;
			}
		}
	}
}
