/* Icons Front */
.icons-front {
	text-align: center;

	&.bg-page {
		padding-top: 110px;
		padding-bottom: 110px;

		@media (max-width: 767px) {
			padding-top: 54px;
			padding-bottom: 54px;
		}
	}

	h2,
	.h2 {
		font-family: $serifTitle-italic;
		margin-top: 0;
		margin-bottom: 96px;
		padding-top: 10px;

		@media (max-width: 767px) {
			margin-bottom: 48px;
		}
	}

	&_wrap {
		display: flex;
		flex-wrap: wrap;
		gap: 48px;
		justify-content: space-around;

		@media (max-width: 991px) {
			row-gap: 36px;
		}

		@media (max-width: 767px) {
			column-gap: 10px;
		}

		.icon-front {
			text-align: center;
			flex: 1 0 calc(16.667% - 48px - (0.16667 * 48px));

			@media (max-width: 991px) {
				flex: 1;
				min-width: 25%;
			}

			@media (max-width: 767px) {
				min-width: calc(50% - 10px);
			}
		}

	}

	&--large {
		.icons-front_wrap {
			@media (max-width: 767px) {
				flex-direction: column;
				align-items: center;
			}

		}

		.icon-front {
			max-width: 350px;

			&_image {
				margin-bottom: 40px;

				@media (max-width: 767px) {
					margin-bottom: 16px;
				}

				img {
					max-width: 194px;
					max-height: 160px;
					height: 160px;
					object-fit: contain;

					@media (max-width: 767px) {
						height: unset;
						max-height: 128px;
						height: 128px;
						max-width: 155px;
					}
				}
			}

			&_title {
				@include heading(h3);
			}

			&_text {
				@include fontBody(md);
				margin-top: 24px;

				@media (max-width: 767px) {
					margin-top: 16px;
				}
			}
		}

	}
}

.icon-front {

	&_box {
		display: flex;
		min-height: 100%;
		flex-direction: column;
		position: relative;
		padding: 10px;
		border: 0px solid transparent;

		@media (hover: hover) {
			&:hover,
			&:active {
				.linkWrap ~ .icon-front_title{
						span {
							background-color: $offblack;
							color: $lightgreen;
						}
				}
			}

		}

	}

	&_image {
		margin-bottom: 32px;

		@media (max-width: 767px) {
			margin-bottom: 16px;
		}

		img {
			width: 100%;
			max-width: 100px;
			max-height: 100px;
			object-fit: contain;
			// object-fit: scale-down;
			object-position: center;

			@media (max-width: 767px) {
				max-width: 60px;
				max-height: 60px;
			}
		}
	}

	&_title {
		@include microfont("sm-bold");
		line-height: 1.5;
	}

	&_text {
		@include fontBody("sm");
	}
	.linkWrap,
	&__link {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		@include microfont("sm");
		margin-top: 8px;
		& ~ .icon-front_title{
			text-decoration: underline;
			text-decoration-thickness: 2px;
			text-underline-position: under;
		}
	}
}



.bannerTop+.icons-front.bg-page {
	padding-top: 0;
	margin-top: 0;

	@media (max-width: 767px) {
		padding-top: 24px;
	}
}
